import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Pax8Company } from 'common/types';
import httpClient from 'services/http-client/http-client';

import { ActionType } from './types';

/* PAX8 */

export const fetchPax8Companies = createAsyncThunk<void>(
	ActionType.FETCH_PAX8_COMPANIES,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<Pax8Company[]>(
				'/pax8/companies',
			);

			dispatch(setPax8Companies(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const synchronizePax8 = createAsyncThunk<void>(
	ActionType.SYNC_PAX8,
	async () => {
		try {
			await httpClient.get('/pax8/synchronize');
		} catch (e) {
			console.log(e);
		}
	},
);

export const setPax8Companies = createAction<Pax8Company[]>(
	ActionType.SET_PAX8_COMPANIES,
);

import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface NavLinkProps {
	title: string;
	href: string;
	isActive?: boolean;
}

export const NavLink = ({ title, href, isActive = false }: NavLinkProps) => {
	return (
		<Root $isActive={isActive}>
			<Link to={href}>{title}</Link>
		</Root>
	);
};

const Root = styled.li<{ $isActive: boolean }>`
	position: relative;

	a {
		text-decoration: none;
		${TYPOGRAPHY.body1}
		color: ${COLORS.textPrimary};

		&:hover {
			color: ${COLORS.secondaryMain};
		}
	}

	${({ $isActive }) =>
		$isActive &&
		`
    a {
      color: ${COLORS.secondaryMain};
    }
    &:before {
      content: '';

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -10px;

      width: 3px;
      height: 22px;

      background-color: ${COLORS.secondaryMain};
    }
  `}

	&:not(:last-child) {
		margin-bottom: 12px;
	}
`;

export const buildRoute = (path: string, params?: { [key: string]: any }): string => {
	let url = path;
    
	if(params){
		for (const key in params) {
			url = url.replace(':' + key, params[key].toString());
		}
	}

	return url;
};
  
export const CLASS_NAMES = {
	ButtonRoot: '.MuiButton-root',
	ButtonBaseRoot: '.MuiButton-root',
	ButtonOutlined: '.MuiButton-outlined',
	ButtonContained: '.MuiButton-contained',
	ButtonGroupContained: '.MuiButtonGroup-contained',
	ButtonGroupContainedHorizontal:
		'.MuiButtonGroup-groupedContainedHorizontal',
	ButtonGroupGrouped: '.MuiButtonGroup-grouped:not(:last-of-type)',

	Selected: '.Mui-selected',
	Disabled: '.Mui-disabled',
	Focused: '.Mui-focused',
	Checked: '.Mui-checked',

	TypographyBody1: '.MuiTypography-body1',

	TabRoot: '.MuiTab-root',

	InputNotchedOutline: '.MuiOutlinedInput-notchedOutline',
	OutlinedInputRoot: '.MuiOutlinedInput-root',
	OutlinedInputInput: '.MuiOutlinedInput-input',

	InputBaseRoot: '.MuiInputBase-root',
	InputBaseInput: '.MuiInputBase-input',

	SelectRoot: '.MuiSelect-root',
	Select: '.MuiSelect-select',
	SelectIcon: '.MuiSelect-icon',
	SelectMenu: '.MuiSelect-selectMenu',

	AutoCompleteRoot: '.MuiAutocomplete-root',
	AutoCompleteInputRoot: '.MuiAutocomplete-inputRoot',

	InputLabelRoot: '.MuiInputLabel-root',
	InputLabelFormControl: '.MuiInputLabel-formControl',
	InputLabelShrink: '.MuiInputLabel-shrink',
	InputLabelOutlined: '.MuiInputLabel-outlined',

	FormLabelRoot: '.MuiFormLabel-root',

	DeleteIcon: '.MuiChip-deleteIcon',

	CheckBoxPrimary: '.MuiCheckbox-colorPrimary',
};

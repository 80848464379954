import { createAsyncThunk } from '@reduxjs/toolkit';

import httpClient from '../../../services/http-client/http-client';
import { ActionType } from './types';

export const fetchAzureUsers = createAsyncThunk(
	ActionType.FETCH_AZURE_USERS,
	async () => {
		const { data } = await httpClient.get('/azure/users');
	},
);

import { CloseIcon } from 'assets/icons';
import ErrorIcon from 'assets/icons/error-icon.svg';
import SuccessIcon from 'assets/icons/success-icon.svg';
import WarningIcon from 'assets/icons/warning-icon.svg';
import { COLORS } from 'assets/styles/colors';
import { COMPONENTS } from 'assets/styles/components';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';

type ToasterVariant = 'success' | 'error' | 'warning';

export interface ToasterButtonProps {
	buttonText: string;
	onClick: () => void;
}

export interface ToasterProps {
	className?: string;
	message: string;
	buttonProps?: ToasterButtonProps;
	variant?: ToasterVariant;
	closeToast?: () => void;
}

export const Toaster = ({
	className,
	message,
	buttonProps,
	variant = 'success',
	closeToast,
}: ToasterProps) => {
	const Icon = () => {
		switch (variant) {
			case 'success':
				return <ToasterIcon src={SuccessIcon} alt="success" />;
			case 'error':
				return <ToasterIcon src={ErrorIcon} alt="error" />;
			case 'warning':
				return <ToasterIcon src={WarningIcon} alt="warning" />;
		}
	};

	return (
		<Root className={className} $variant={variant}>
			<LeftSideWrap>
				<Icon />
				<MessageText $variant={variant}>{message}</MessageText>
			</LeftSideWrap>
			<RightSide>
				{buttonProps && (
					<RightSideButton
						$variant={variant}
						onClick={buttonProps?.onClick}>
						{buttonProps?.buttonText}
					</RightSideButton>
				)}
				<CloseButton $variant={variant} onClick={closeToast}>
					<CloseIcon />
				</CloseButton>
			</RightSide>
		</Root>
	);
};

const Root = styled.div<{ $variant: ToasterVariant }>`
	display: flex;
	align-items: center;
	justify-content: space-between;

	position: absolute;
	z-index: 999;

	max-width: 934px;
	width: 100%;
	height: 51px;

	box-sizing: border-box;
	padding: 14px 16px;

	background-color: #fff;
	border: 1px solid
		${({ $variant }) => {
			switch ($variant) {
				case 'success':
					return COLORS.successMain;
				case 'error':
					return COLORS.errorMain;
				case 'warning':
					return COLORS.warningMain;
			}
		}};
`;

const RightSide = styled.div`
	display: flex;
	height: 30px;
	align-items: center;
`;

const RightSideButton = styled.button<{ $variant: ToasterVariant }>`
	width: 45px;
	${COMPONENTS.buttonSmall}
	color: ${({ $variant }) => {
		switch ($variant) {
			case 'success':
				return COLORS.successMain;
			case 'error':
				return COLORS.errorMain;
			case 'warning':
				return COLORS.warningMain;
		}
	}};
	margin-right: 2px;
`;

const MessageText = styled.p<{ $variant: ToasterVariant }>`
	${TYPOGRAPHY.body2}
	color: ${({ $variant }) => {
		switch ($variant) {
			case 'success':
				return COLORS.successMain;
			case 'error':
				return COLORS.errorMain;
			case 'warning':
				return COLORS.warningMain;
		}
	}};
	margin: 0;
`;

const ToasterIcon = styled.img`
	margin-right: 12px;
`;

const LeftSideWrap = styled.div`
	display: flex;
	align-items: center;
`;

const CloseButton = styled.button<{ $variant: ToasterVariant }>`
	width: 24px;
	height: 24px;

	& svg path {
		fill-opacity: 1;
		fill: ${({ $variant }) => {
			switch ($variant) {
				case 'success':
					return COLORS.successMain;
				case 'error':
					return COLORS.errorMain;
				case 'warning':
					return COLORS.warningMain;
			}
		}};
	}
`;

import {DesktopDatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { CLASS_NAMES } from 'assets/styles/classNames';
import { COMPONENTS } from 'assets/styles/components';
import styled from 'styled-components';

interface DatePickerProps {
	className?: string;
	label?: string;
	format?: string;
	value: Date | string | null;
	onChange: (date: Date | string | null) => void;
	disabled?: boolean
}

export const DatePicker = ({
	className,
	label = '',
	format='MM/dd/yyyy',
	value,
	onChange,
	disabled = false
}: DatePickerProps) => (
	<LocalizationProvider dateAdapter={AdapterDateFns}>
		<DesktopDatePicker
			label={label}
			inputFormat={format}
			onChange={onChange}
			value={value}
			renderInput={(params: any) => (
				<StyledTextField className={className} {...params} />
			)}
			disabled= {disabled}
		/>
	</LocalizationProvider>
);

const StyledTextField = styled(TextField)`
	&& {
		&${CLASS_NAMES.InputLabelRoot} {
			${COMPONENTS.inputLabel}
		}

		.MuiInputLabel-outlined {
			transform: translate(14px, 10px) scale(1);

			&.MuiInputLabel-shrink {
				transform: translate(14px, -8px) scale(0.75);
			}
		}

		input {
			padding-top: 8.5px;
			padding-bottom: 8.5px;
		}
	}
`;

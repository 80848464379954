import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { itOSAuthToken } from './types';

interface State {
	token?: string;
	tokenData?: itOSAuthToken;
}

const initialState: State = {};

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(actions.login, (state, { payload: { token } }) => {
			state.tokenData = parseJwt(token);
			state.token = token;
		})
		.addCase(actions.logout, state => {
			state.token = undefined;
			state.tokenData = undefined;
		});
});

const parseJwt = (token: string): itOSAuthToken => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(''),
	);

	return JSON.parse(jsonPayload);
};

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select as UISelect,
	SelectProps as UISelectProps,
} from '@mui/material';
import { CLASS_NAMES } from 'assets/styles/classNames';
import { getOptionLabel } from 'lib/utils/getOptionLabel';
import styled from 'styled-components';
import { OptionType } from 'types';

import { themes } from './themes';
import { Chip } from '..';

type SelectVariant = 'primary' | 'secondary';

interface SelectProps extends Omit<UISelectProps, 'variant'> {
	items: OptionType[];
	variant: SelectVariant;
}

export const Select = ({
	items,
	onChange,
	value,
	label,
	variant,
	className,
	multiple,
	...props
}: SelectProps) => {
	return (
		<StyledFormControl className={className}>
			<StyledLabel id={props.name + '_label'}>{label}</StyledLabel>
			<StyledSelect
				{...props}
				multiple={multiple}
				labelId={props.name + '_label'}
				label={label}
				variant="outlined"
				onChange={onChange}
				value={value ?? ''}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
				}}
				renderValue={(selected: any) =>
					multiple ? (
						<ValueContainer>
							{selected.map((value: any) => (
								<StyledChip
									key={value}
									text={getOptionLabel(items, value)}
								/>
							))}
						</ValueContainer>
					) : (
						getOptionLabel(items, selected)
					)
				}
				$theme={themes[variant](multiple)}>
				{items.map(item => (
					<MenuItem key={item.value} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</StyledSelect>
		</StyledFormControl>
	);
};

const StyledFormControl = styled(FormControl)`
	max-width: 198px;
	width: 100%;
`;

const StyledLabel = styled(InputLabel)`
	&& {
		&${CLASS_NAMES.InputLabelFormControl} {
			transform: translate(14px, 12px) scale(1);
		}

		&${CLASS_NAMES.InputLabelShrink} {
			transform: translate(14px, -5px) scale(0.75);
		}
	}
`;

const StyledSelect = styled(UISelect)<{ $theme: string }>`
	width: 100%;
	height: 100%;
	text-align: left;

	${({ $theme }) => $theme}
`;

const StyledChip = styled(Chip)`
	margin-right: 6px;
	margin-bottom: 6px;
`;

const ValueContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

import { ItOsLogo } from 'assets/icons';
import { Button, TicketInput } from 'components/UIComponents';
import { SidebarLinksType } from 'lib/constants/routes';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { NavLink } from './NavLink';

interface SidebarProps {
	links: SidebarLinksType;
}

export const Sidebar = ({ links }: SidebarProps) => {
	const location = useLocation();

	const [isOpen, setIsOpen] = useState(false);

	return (
		<Root>
			<LogoIconWrap>
				<ItOsLogo />
			</LogoIconWrap>

			<NavContainer>
				<LinksGroup>
					{links.generalGroup.map(item => (
						<NavLink
							key={item.href}
							{...item}
							isActive={location.pathname.includes(item.href)}
						/>
					))}
				</LinksGroup>
				<LinksGroup>
					{links.secondaryGroup.map(item => (
						<NavLink
							key={item.href}
							{...item}
							isActive={location.pathname.includes(item.href)}
						/>
					))}
				</LinksGroup>
				<LinksGroup>
					{links.settingsGroup.map(item => (
						<NavLink
							key={item.href}
							{...item}
							isActive={location.pathname.includes(item.href)}
						/>
					))}
				</LinksGroup>
			</NavContainer>
			<TicketInput isOpen={isOpen} onClose={() => setIsOpen(false)} />
			<StyledButton
				onClick={() => setIsOpen(true)}
				text="Help"
				variant="secondary"
			/>
		</Root>
	);
};

const Root = styled.div`
	position: relative;

	width: 100%;
	height: 100%;

	border-right: 1px solid #e3dee1;
`;

const LogoIconWrap = styled.div`
	width: 100%;
	padding: 57px 60px;
`;

const NavContainer = styled.ul`
	width: 100%;

	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	padding-left: 60px;
	margin-bottom: 40px;

	list-style-type: none;
`;

const LinksGroup = styled.div`
	&:not(&:last-child) {
		margin-bottom: 32px;
	}
`;

const StyledButton = styled(Button)`
	&& {
		min-width: 106px;
		height: 31px;
		margin-left: 60px;
		text-transform: none;
		font-weight: 400;
	}
`;

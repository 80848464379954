import { COMPONENTS } from 'assets/styles/components';
import React, { FC, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

interface UploadAvatar {
	className?: string;
	id?: string;
	name?: string;
	value?: string;
	onChange: (value: any) => void;
}

export const UploadAvatar: FC<UploadAvatar> = ({
	className,
	id,
	name = '',
	value = '',
	onChange,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [avatarKey, setAvatarKey] = useState(value);

	const handleClickOnRoot = () => {
		inputRef.current && inputRef.current.click();
	};

	const handleUploadAvatar = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const {
				target: { files },
			} = event;

			if (files?.length) {
				const data = URL.createObjectURL(files[0]);
				setAvatarKey(data);
				onChange(data);
			}
		},
		[setAvatarKey, onChange],
	);

	return (
		<Root className={className} onClick={handleClickOnRoot}>
			{avatarKey ? (
				<Icon src={avatarKey} alt="avatar" />
			) : (
				<InfoText>(on hover prompt edit options)</InfoText>
			)}
			<StyledInput
				ref={inputRef}
				hidden
				id={id}
				name={name}
				type="file"
				onChange={handleUploadAvatar}
			/>
		</Root>
	);
};

const Root = styled.div`
	position: relative;

	max-width: 97px;
	min-width: 97px;
	height: 97px;
	overflow: hidden;
	cursor: pointer;

	border-radius: 64px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #4282b380;
`;

const StyledInput = styled.input`
	position: absolute;
`;

const Icon = styled.img`
	width: 100%;
	height: 100%;
`;

const InfoText = styled.span`
	${COMPONENTS.chip};
	color: #fff;
	text-align: center;
`;

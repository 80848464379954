import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import { COLORS } from 'assets/styles/colors';
import styled from 'styled-components';
import { StepItemType } from 'types';

interface StepsBarProps {
	steps: StepItemType[];
	currentStepIndex: number;
	className?: string;
}

export const StepsBar = ({
	steps,
	currentStepIndex,
	className,
}: StepsBarProps) => {
	return (
		<Root className={className}>
			<Stepper activeStep={currentStepIndex}>
				{steps.map(item => {
					const stepProps: { compiled?: boolean } = {};
					return (
						<Step key={item.name} {...stepProps}>
							<StepButton onClick={item.onClick}>
								<StepLabel>{item.name}</StepLabel>
							</StepButton>
						</Step>
					);
				})}
			</Stepper>
		</Root>
	);
};

const Root = styled.div`
	max-width: 100%;
	width: 100%;
	margin-bottom: 40px;

	&& {
		.MuiStepper-root {
			padding: 0;
		}
		.MuiStepIcon-root.MuiStepIcon-active {
			color: ${COLORS.primaryMain};
		}
		.MuiStepIcon-root.MuiStepIcon-completed {
			color: ${COLORS.primaryMain};
		}
		.MuiStep-horizontal {
			&:first-child {
				padding-left: 0;
			}
		}
	}
`;

export const routes = {
	authorized: {
		endCustomer: {
			assessmentIntro: '/assessment/intro',
			assessmentEdge: '/assessment/edge',
			assessmentCallback: '/assessment/callback',
			// assessmentComplete: '/assessment/complete',
		},
		msp: {
			onboarding: {
				start: '/msp/onboarding',
				finish: '/msp/onboarding/callback',
			},
			dashboard: {
				root: '/msp',
				customers: {
					dashboard: '/msp/customers',
					byId: '/msp/customer/:id',
					new: '/msp/customers/new',
				},
				reports: {
					dashboard: '/msp/reports',
					latest: '/msp/reports/latest',
					byId: '/msp/report/:id',
					byIdForCustomer: '/msp/report/for-customer/:id',
				},
				settings: {
					root: '/msp/settings',
					profile: '/msp/settings/profile',
					security: '/msp/settings/security',
					notifications: '/msp/settings/notifications',
				},
			},
		},

		newClient: '/new-client',
		editClient: '/edit-client/:id',
		unsubscribe: '/unsubscribe',

		onboarding: '/onboarding',
		onboardingTeamMembers: '/onboarding/steps#teamMembers',
		onboardingSecurity: '/onboarding/steps#security',
		onboardingAssets: '/onboarding/steps#assets',
		onboardingSaaS: '/onboarding/steps#saas',
		onboardingSteps: '/onboarding/steps',
		onboardingMap: '/onboarding/map',
		onboardingFinish: '/onboarding/finish',
		onboardingInitAzure: '/onboarding/initAzure',
		onboardingInitNinja: '/onboarding/initNinja',
		securityDashboard: '/security-dashboard',
		compliance: '/compliance',
		policies: '/policies',
		workflows: '/workflows',
		supportTickets: '/support-tickets',
		myAccount: '/my-account',

		team: '/teams/team/:id',
		teams: '/teams',
		newTeam: '/teams/new-team',
		editTeam: '/teams/edit-team/:id',

		teamMember: '/teams/team-member/:id',
		newTeamMember: '/teams/new-team-member',
		editTeamMember: '/teams/edit-team-member/:id',

		assets: '/assets',
		hardwareAsset: '/assets/hardware-asset/:id',
		softwareAsset: '/assets/software-asset/:id',
		newAsset: '/assets/new-asset',

		report: '/report',
		reportLoading: '/report/loading',

		gustoCallback: '/gusto/callback',

		quickbooksCallback: '/quickbooks/callback',

		hubspot: '/hubspot/callback',
		customerReportView: '/cybersecurity-report/customer-view',
	},
	unauthorized: {
		signIn: '/sign-in',
		demo: '/demo',
		mspSignIn: '/msp',
		customerAssessmentLogin: '/assessment/login',
		customerReportViewRedirect: '/cybersecurity-report/customer-view',
		unsubscribe: '/unsubscribe',
	},
};

export interface TeamPageParams {
	id: string;
}

export interface TeamMemberParams {
	id: string;
}

export interface EditTeamParams {
	id: string;
}

export interface EditClientParams {
	id: string;
}

export interface EditTeamMemberParams {
	id: string;
}

export interface MspCustomerPortalParams {
	id: string;
}

export interface ReportDetailPageParams {
	id: string;
}

import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AppTheme } from 'assets/themes/AppTheme';
import { ToasterController } from 'components';
import { useAppDispatch } from 'hooks/redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AppRouter from 'router';

import { AxiosInterceptors } from './services/http-client/http-client';
import { persistor, store } from './store';

AxiosInterceptors.setup(store);

function App() {
	// const dispatch = useAppDispatch();
	return (
		<Provider store={store}>
			<ThemeProvider theme={AppTheme}>
				<PersistGate
					loading={<Typography>Loading...</Typography>}
					persistor={persistor}>
					<AppRouter />
					<ToasterController />
				</PersistGate>
			</ThemeProvider>
		</Provider>
	);
}

export default App;

/* eslint no-useless-escape: 0 */

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import { CompanyAvatar } from 'components';
import { useAppDispatch } from 'hooks/redux';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import { Role } from 'store/stores/auth/types';

export const MspSettingsPage = () => {
	const dispatch = useAppDispatch();
	const msp = useSelector(selectors.msp.selectCurrentMSP);
	const [editMode, setEditMode] = useState<boolean>(false);

	const updateFields = async () => {
		const update = {
			companyName: document
				?.getElementById('companyName')
				?.textContent?.toString(),
			primaryContactFirstName: document
				?.getElementById('firstName')
				?.textContent?.toString(),
			primaryContactLastName: document
				?.getElementById('lastName')
				?.textContent?.toString(),
			primaryContactEmailAddress: document
				?.getElementById('email')
				?.textContent?.toString(),
			phoneNumber: document
				?.getElementById('phone')
				?.textContent?.toString(),
			address: document
				?.getElementById('address')
				?.textContent?.toString(),
		};
		await dispatch(actions.msp.setMspUpdate(update));
		dispatch(actions.msp.fetchCurrentMsp());
		setEditMode(false);
	};

	const cancelEdit = () => {
		dispatch(actions.msp.fetchCurrentMsp());
		setEditMode(false);
	};

	useEffect(() => {
		dispatch(actions.msp.fetchCurrentMsp());
	}, []);

	return (
		<>
			<Box suppressContentEditableWarning={true} sx={sxRoot}>
				<Box sx={sxLogoArea}>
					<CompanyAvatar
						role={Role.MSP}
						logoProps={msp?.logo}
						editable={editMode}
					/>
				</Box>
				<Box sx={sxInfo}>
					<Box suppressContentEditableWarning={true} sx={sxHeader}>
						<Typography
							variant="h2"
							contentEditable={editMode}
							id="companyName"
							key={'companyName'}
							className="companyName"
							sx={editMode ? sxContactValueEdit : null}>
							{msp?.companyName}
						</Typography>
						<IconButton
							onClick={
								editMode
									? () => updateFields()
									: () => {
											setEditMode(!editMode);
									  }
							}
							sx={{ ml: '1em' }}>
							{editMode ? (
								<SaveOutlinedIcon />
							) : (
								<EditOutlinedIcon />
							)}
						</IconButton>
						{editMode && (
							<IconButton
								onClick={() => cancelEdit()}
								sx={{ ml: '1em' }}>
								<ClearOutlinedIcon />
							</IconButton>
						)}
					</Box>
					<Box sx={{ width: '80%' }}>
						<Box
							suppressContentEditableWarning={true}
							sx={sxContactRow}>
							<Typography sx={sxContactLabel}>
								<strong>Primary Contact</strong>
							</Typography>
							<Typography
								id={'firstName'}
								contentEditable={editMode}
								sx={editMode ? sxContactValueEdit : null}
								variant="body1">
								{msp?.firstName}
							</Typography>

							<Typography
								id="lastName"
								contentEditable={editMode}
								sx={editMode ? sxContactValueEdit : null}
								variant="body1">
								{msp?.lastName}
							</Typography>
						</Box>
						<Box
							suppressContentEditableWarning={true}
							sx={sxContactRow}>
							<Typography sx={sxContactLabel}>
								<strong>Email</strong>
							</Typography>
							<Typography
								id="email"
								contentEditable={editMode}
								sx={editMode ? sxContactValueEdit : null}
								variant="body1">
								{msp?.emailAddress ?? 'Not set'}
							</Typography>
						</Box>
						<Box
							suppressContentEditableWarning={true}
							sx={sxContactRow}>
							<Typography sx={sxContactLabel}>
								<strong>Phone</strong>
							</Typography>
							<Typography
								id="phone"
								contentEditable={editMode}
								sx={editMode ? sxContactValueEdit : null}
								variant="body1">
								{msp?.phoneNumber != undefined
									? msp?.phoneNumber
									: 'Not set'}
							</Typography>
						</Box>
						<Box
							suppressContentEditableWarning={true}
							sx={sxContactRow}>
							<Typography sx={sxContactLabel}>
								<strong>Address</strong>
							</Typography>
							<Typography
								id="address"
								contentEditable={editMode}
								sx={editMode ? sxContactValueEdit : null}
								variant="body1">
								{msp?.address ? msp.address : 'Not set'}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

const sxRoot = {
	mt: '10vh',
	width: '100%',
	height: '40vh',
	minWidth: '820px',
	minHeight: '280px',
	padding: '1em',
	display: 'flex',
	flexDirection: 'row',
};

const sxLogoArea = {
	width: '20%',
	minWidth: '160px',
	display: 'flex',
	alignItems: 'start',
	pt: '2em',
	alignContent: 'space-around',
	justifyContent: 'centered',
};

const sxInfo = {
	width: '100%',
	p: '1.5em',
	display: 'flex',
	flexDirection: 'column',
};

const sxHeader = {
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
	alignItems: 'center',
	minHeight: '48px',
};

const sxContactRow = {
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
	alignItems: 'center',
	height: '2em',
	justifyContent: 'left',
	wrap: 'no-wrap',
};

const sxContactLabel = {
	width: '50%',
};

const sxContactValueEdit = {
	backgroundColor: 'whiteSmoke',
};

export const sxCircularProgress = {
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
	minWidth: '150px',
	minHeight: '150px',
	maxWidth: '150px',
	maxHeight: '150px',
};

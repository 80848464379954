import { createReducer } from '@reduxjs/toolkit';
import { NinjaOrganization } from 'common/types';

import * as actions from './actions';

interface NinjaState {
	ninjaOrganizations: NinjaOrganization[];
	ninjaOrganizationsForAssignment: NinjaOrganization[];
	loading: boolean;
	slectedOrganization?: string | null | undefined;
}

const initialState: NinjaState = {
	ninjaOrganizations: [],
	ninjaOrganizationsForAssignment: [],
	loading: false,
};

export const reducer = createReducer(initialState, builder =>
	builder
		.addCase(actions.setNinjaOrganizations, (state, { payload }) => {
			state.ninjaOrganizations = payload;
		})
		.addCase(
			actions.setNinjaOrganizationsForAssignemt,
			(state, { payload }) => {
				state.ninjaOrganizationsForAssignment = payload;
			},
		)
		.addCase(actions.startSyncNinjaLoader, state => {
			state.loading = true;
		})
		.addCase(actions.finishSyncNinjaLoader, state => {
			state.loading = false;
		})
		.addCase(actions.selectNinjaOrganization, (state, { payload }) => {
			state.slectedOrganization = payload;
		})
		.addCase(actions.unselectNinjaOrganization, state => {
			state.slectedOrganization = null;
		}),
);

import LinkIcon from '@mui/icons-material/Link';
import { Box, Button, Card, CardContent } from '@mui/material';
import React from 'react';

import { useActions } from '../../actions';
import * as ComplianceActions from '../../actions/compliance';

interface NISTGetStartedProps {
	setWizzardMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const NISTGetStarted: React.FunctionComponent<NISTGetStartedProps> = props => {
	const complianceActions: typeof ComplianceActions =
		useActions(ComplianceActions);
	return (
		<Card /*className={classes.NISTGetStartedCard}*/>
			<CardContent>
				<Box display="flex" alignItems="center" m={1}>
					<Box fontWeight="fontWeightBold" fontSize={24}>
						itOS Unified Compliance
					</Box>
				</Box>
				<Box display="flex" alignItems="center" m={1}>
					<Box fontWeight="fontWeightLight" fontSize={18}>
						The National Institute of Standards and Framework&apos;s
						Cybersecurity Framework (CSF) was published in February
						2014 in response to Presidential Executive Order 13636,
						&quot;Improving Critical Infrastructure
						Cybersecurity&quot;, which called for standardized
						security framework for critical infrastructure in the
						United States.
					</Box>
				</Box>
				<Box display="flex" width="100%" justifyContent="start">
					<Button
						/*className={classes.getStartedButton}*/
						variant="outlined"
						onClick={() => {
							complianceActions.getStarted();
							props.setWizzardMode(true);
						}}
						endIcon={<LinkIcon />}>
						Get Started
					</Button>
				</Box>
			</CardContent>
		</Card>
	);
};

export default NISTGetStarted;

import type {} from '@mui/lab/themeAugmentation';

import { createTheme } from '@mui/material/styles';
import { COLORS } from 'assets/styles/colors';

export const AppTheme = createTheme({
	palette: {
		primary: {
			main: '#6501E5',
		},
		secondary: {
			main: '#9573D0',
		},
		text: {
			primary: '#000000DE',
			secondary: '#00000099',
		},
		success: {
			main: '#4caf50',
		},
		error: {
			main: '#F97066',
		},
		warning: {
			main: 'rgba(255, 152, 0, 0.5)',
		},
		grey: {
			400: '#BDBDBD',
		},
	},
	typography: {
		htmlFontSize: 16,
		fontFamily: 'Inter',
		fontSize: 14,
		fontWeightBold: 900,
		fontWeightMedium: 500,
		fontWeightRegular: 400,
		fontWeightLight: 300,
		h1: {
			fontFamily: 'Inter',
			fontWeight: 700,
			fontSize: '48px',
			lineHeight: '48px',
		},
		h2: {
			fontFamily: 'Inter',
			fontWeight: 700,
			fontSize: '36px',
			lineHeight: '44px',
		},
		h3: {
			fontFamily: 'Inter',
			fontWeight: 700,
			fontSize: '48px',
			lineHeight: '56px',
		},
		h4: {
			fontFamily: 'Inter',
			fontWeight: 700,
			fontSize: '36px',
			lineHeight: '44px',
			letterSpacing: '0.25px',
		},
		h5: {
			fontFamily: 'Inter',
			fontWeight: 700,
			fontSize: '24px',
			lineHeight: '32px',
		},
		h6: {
			fontFamily: 'Inter',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '24px',
		},
		caption: {
			fontFamily: 'Lato',
			fontWeight: 400,
			fontSize: '12px',
			lineHeight: '20px',
			letterSpacing: '0.4px',
		},
		subtitle1: {
			fontFamily: 'Inter',
			fontWeight: 300,
			fontSize: '16px',
			lineHeight: '28px',
			letterSpacing: '0.15px',
		},
		subtitle2: {
			fontFamily: 'Inter',
			fontWeight: 300,
			fontSize: '14px',
			lineHeight: '22px',
			letterSpacing: '0.1px',
		},
		body1: {
			fontFamily: 'Inter',
			fontWeight: 400,
			fontSize: '18px',
			lineHeight: '27px',
			letterSpacing: '0.15px',
		},
		body2: {
			fontFamily: 'Inter',
			fontWeight: 200,
			fontSize: '16px',
			lineHeight: '24px',
		},
	},
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltipPlacementBottom: {
					fontFamily: 'Lato',
					fontWeight: 400,
					fontSize: '18px',
					lineHeight: '27px',
					letterSpacing: '0.15px',
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				tag: {
					backgroundColor: COLORS.secondaryMain,
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					'&.Mui-completed': {
						color: COLORS.secondaryMain,
					},
					'&.Mui-active': {
						color: COLORS.secondaryMain,
					},
				},
				active: {},
				completed: {},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				label: {
					fontSize: '16px',
					'&.Mui-active': {
						color: COLORS.secondaryMain,
					},
					'&.Mui-completed': {
						color: COLORS.secondaryMain,
					},
				},
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'outlined' },
					style: {
						minWidth: '120px',
						paddingTop: '20px',
						paddingBottom: '20px',
						height: '32px',
						textTransform: 'none',
						fontSize: '18px',
						fontWeight: 500,
						lineHeight: '22px',
						letterSpacing: '0.46px',
						color: COLORS.secondaryMain,
						fill: COLORS.secondaryMain,
						border: `1px solid ${COLORS.secondaryMain}`,
						'&:hover': {
							backgroundColor: '#f7f2fe',
							border: `1px solid ${COLORS.secondaryMain}`,
						},
					},
				},
				{
					props: { variant: 'toggle' },
					style: {
						display: 'flex',
						height: '36px',
						borderRadius: '4px',
						boxShadow: '20px 20px 40px 0px #0000000d',
						margin: 'auto',
						'&:nth-of-type(4n)': {
							margin: 0,
						},
					},
				},
			],
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					cursor: 'default',
					'&.Mui-checked': {
						color: '#DD3B8C',
						'&:hover': {
							backgroundColor: 'transparent',
						},
					},
				},
				colorPrimary: {
					'&:hover': {
						backgroundColor: 'transparent',
					},
				},
			},
			defaultProps: {
				disableRipple: true,
			},
		},
	},
});

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		toggle: true;
	}
}

// The following are referenced in the typography.ts file but do not conform to theme object model:

// body5: `
//     font-family: 'Lato';
//     font-weight: 300;
//     font-size: 18px;
//     line-height: 22px;
//     letter-spacing: 0.15px;
//   `,
// 	light1: `
//     font-family: 'Lato';
//     font-weight: 300;
//     font-size: 22px;
//     line-height: 22px;
//   `,
// 	light2: `
//     font-family: 'Lato';
//     font-weight: 300;
//     font-size: 26px;
//     line-height: 30px;
//     letter-spacing: 0.15px;
//   `,

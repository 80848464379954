export enum ActionType {
	/* NOTIFICATIONS */
	FETCH_NOTIFICATIONS = 'NOTIFICATIONS/FETCH_NOTIFICATIONS',
	SET_NOTIFICATIONS = 'NOTIFICATIONS/SET_NOTIFICATIONS',
	UPDATE_SEEN_NOTIFICATIONS = 'NOTIFICATIONS/UPDATE_SEEN_NOTIFICATIONS',
	UPDATE_DISMISSED_NOTIFICATION = 'NOTIFICATIONS/UPDATE_DISMISSED_NOTIFICATIONS',
}

export interface Notification {
	id: string;
	type: NotificationType;
	text: string;
	isSeen: boolean;
	isDismissed: boolean;
}

export enum NotificationType {
	Info = 'INFO',
	Warning = 'WARNING',
	Error = 'ERROR',
	Success = 'SUCCESS',
	None = 'NONE',
}

export interface DismissNotificationDTO {
	id: string;
}

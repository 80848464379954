import { createReducer } from '@reduxjs/toolkit';
import { JiraOrganization } from 'common/types';

import * as actions from './actions';

interface JiraState  {
    jiraOrganizations: JiraOrganization[];
}

const initialState: JiraState = {
	jiraOrganizations: [],
};

export const reducer = createReducer(initialState, (builder) => 
	builder
		.addCase(actions.setJiraOrganizations, (state, {payload}) => {
			state.jiraOrganizations = [...payload];
		})
);
import { Buffer } from 'buffer';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from 'services/http-client/http-client';
import { toaster } from 'services/toaster';

import { ActionType } from './types';

/* AZURE */

export const synchronizeAzure = createAsyncThunk<void>(
	ActionType.SYNC_AZURE,
	async () => {
		try {
			toaster({
				message: 'Azure Synchronization Started',
				variant: 'success',
			});
			await httpClient.get('/azure/synchronize');
			toaster({
				message: 'Azure Synchronization Finished',
				variant: 'success',
			});
		} catch (e) {
			console.log(e);
		}
	},
);

export const getDemoReport = createAsyncThunk<void, string | null>(
	ActionType.FETCH_DEMO_REPORT,
	async (tenantId, { dispatch }) => {
		if (!tenantId) {
			return;
		}
		const response = await httpClient.post('/demo/request-report', {
			tenantId: tenantId,
		});

		let pdfDocument = '';

		if (response?.data?._chunks?.length > 0) {
			const data: any[] = [];

			response?.data?._chunks.map((chunk: any) => {
				data.push(...chunk.data);
			});

			pdfDocument = Buffer.from(data).toString('base64');
		} else if (response?.data?.length > 0) {
			pdfDocument = Buffer.from(response.data).toString('base64');
		}

		dispatch(setDemoReport(pdfDocument));
	},
);

export const setDemoReport = createAction<string>(ActionType.SET_DEMO_REPORT);

export const startLoadingAzure = createAction(ActionType.START_LOADING_AZURE);

export const finishLoadingAzure = createAction(ActionType.FINISH_LOADING_AZURE);

import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { Box, List } from '@mui/material';
import {
	ExpandMore,
	NotificationIcon,
	PlusIcon,
	SearchIcon,
} from 'assets/icons';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { buildRoute } from 'common/utils';
import { ClientsListItem } from 'components/ClientsListItem';
import { NotificationsListItem } from 'components/NotificationsListItem';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'router';
import { selectors } from 'store';
import { actions as authActions } from 'store/stores/auth';
import { Notification } from 'store/stores/notifications/types';
import styled from 'styled-components';

import { useAppDispatch } from '../../../hooks/redux';
import { Avatar } from '../Avatar';
import { Dropdown } from '../Dropdown';
import { SearchBar } from '../SearchBar';

interface HeaderProps {
	pageTitle: string;
	titleButton?: React.ReactNode;
	clients: any[];
}

export const Header = ({
	pageTitle,
	titleButton = <></>,
	clients,
}: HeaderProps) => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const handleAccountSettingsClick = () => {};
	const handleHelpCenterClick = () => {};
	const handleLogOutClick = () => {
		dispatch(authActions.logout());
	};
	useEffect(() => {
		// dispatch(actions.notifications.fetchNotifications());
	}, []);

	const handleEditClient = (id: string) => () =>
		history.push(buildRoute(routes.authorized.editClient, { id: id }));

	const handleAddNewClient = () => history.push(routes.authorized.newClient);
	const ninjaOrgs = useSelector(selectors.ninja.selectNinjaOrganizations);

	const selectedNinjaOrganization = useSelector(
		selectors.ninja.selectNinjaSelectedOrganization,
	);

	const itosOrg = useSelector(selectors.organizations.selectOrganizations);
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = () => {
		setIsOpen(false);
	};

	const notifications: Notification[] = useSelector(
		selectors.notifications.getNotifications,
	);

	return (
		<Root>
			<SearchBar isOpen={isOpen} onClose={handleClose} />
			<LeftSideWrap>
				<Title>{pageTitle}</Title>
				{titleButton}
			</LeftSideWrap>
			<Toolbar>
				<StyledButtonWrap>
					<SearchIcon onClick={() => setIsOpen(true)} />
				</StyledButtonWrap>
				<NotificationsList
					clickableContent={<NotificationIcon />}
					dropdownTitle="Notifications"
					dropdownContent={
						<List>
							{notifications.map(n => (
								<NotificationsListItem
									key={n.id}
									notification={n}
								/>
							))}
						</List>
					}
				/>
				<ClientsDropdown
					clickableContent={<StyledAvatar firstName="" />}
					dropdownTitle="Organizations"
					dropdownContent={
						<ClientsList>
							{clients.map(item => (
								<ClientsListItem
									key={item.id}
									client={item}
									onSelectClient={() => {}}
									onEditClient={handleEditClient(item.id)}
								/>
							))}
							<AddClientButton onClick={handleAddNewClient}>
								<AddClientIconWrap>
									<PlusIcon />
								</AddClientIconWrap>
								Add New Client
							</AddClientButton>
							<DropdownTitle>
								NinjaOne Organizations
							</DropdownTitle>
							{ninjaOrgs
								.filter(ninj => {
									if (
										itosOrg?.length > 0 &&
										itosOrg[0]?.ninjaOrgId
									) {
										return ninj.id != itosOrg[0].ninjaOrgId;
									}
									return false;
								})
								.map(n => {
									return (
										<NinjaOrgButton key={n.id}>
											<Box
												display={'flex'}
												flexDirection={'row'}
												width={'100%'}>
												<Box flexGrow={1}>{n.name}</Box>
												{selectedNinjaOrganization ==
												n.id ? (
													<CloseIcon />
												) : (
													<PersonIcon />
												)}
											</Box>
										</NinjaOrgButton>
									);
								})}
						</ClientsList>
					}
				/>
				<OptionsDropdown
					clickableContent={<StyledExpandMore />}
					dropdownContent={
						<OptionsList>
							<OptionsItem onClick={handleAccountSettingsClick}>
								Account Settings
							</OptionsItem>
							<OptionsItem onClick={handleHelpCenterClick}>
								Help Center
							</OptionsItem>
							<OptionsItem onClick={handleLogOutClick}>
								Log Out
							</OptionsItem>
						</OptionsList>
					}
				/>
			</Toolbar>
		</Root>
	);
};

const Root = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: space-between;

	padding: 60px 106px 40px;
`;

const LeftSideWrap = styled.div`
	display: flex;
	align-items: center;
`;

const Title = styled.div`
	${TYPOGRAPHY.h3}
	color: ${COLORS.textPrimary};
	margin-right: 38px;
`;

const Toolbar = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

const StyledAvatar = styled(Avatar)`
	&& {
		width: 32px;
		height: 32px;
		margin-top: 4px;

		&:hover {
			background-color: rgba(66, 130, 179, 0.7);
		}
	}
`;

const StyledButtonWrap = styled.button`
	width: 40px;
	height: 40px;

	cursor: pointer;
	background-color: transparent;
	border-width: 0;
	border-radius: 10px;

	padding: 0;
	margin: 0;

	&:first-child {
		margin-right: 20px;
	}

	margin-right: 24px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const StyledExpandMore = styled(ExpandMore)`
	border-radius: 10px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const ClientsDropdown = styled(Dropdown)`
	margin-right: 24px;
	.dropdown__container {
		width: 250px;
		transform: translate(-37%, 25px);
	}
`;
const OptionsDropdown = styled(Dropdown)`
	.dropdown__container {
		width: 168px;
		transform: translate(-27%, 19px);
	}
`;

const NotificationsList = styled(Dropdown)`
	margin-right: 24px;
	.dropdown__container {
		max-height: 80vh;
		overflow: auto;
		width: 400px;
		transform: translate(-37%, 25px);
	}
`;

const ClientsList = styled.ul`
	width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
`;

const AddClientButton = styled.button`
	${TYPOGRAPHY.body1}
	color: ${COLORS.textPrimary};

	display: flex;
	align-items: center;

	width: 100%;
	padding: 8px 16px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const NinjaOrgButton = styled.button`
	${TYPOGRAPHY.body1}
	color: ${COLORS.textPrimary};

	display: flex;
	align-items: center;

	width: 100%;
	padding: 8px 16px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const AddClientIconWrap = styled.div`
	width: 40px;
	height: 40px;

	border-radius: 40px;
	margin-right: 16px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${COLORS.gray400};
`;

const OptionsList = styled.div`
	width: 100%;
`;

const OptionsItem = styled.button`
	${TYPOGRAPHY.body1}
	width: 100%;
	cursor: pointer;

	background-color: transparent;
	border-color: transparent;
	border-width: 0;
	text-align: left;

	margin: 0;
	padding: 0;
	padding: 6px 16px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const DropdownTitle = styled.div`
	${TYPOGRAPHY.h6}
	color: ${COLORS.textPrimary};
	padding: 16px 24px;
	text-align: left;
`;

import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

interface AzureState {
	laoding: boolean;
	demoPDFDocument?: string;
	isPdfDocumentLoading: boolean;
}

const initialState: AzureState = {
	laoding: false,
	isPdfDocumentLoading: false,
};

export const reducer1 = createReducer(initialState, builder => {
	builder
		.addCase(actions.startLoadingAzure, state => {
			state.laoding = true;
		})
		.addCase(actions.finishLoadingAzure, state => {
			state.laoding = false;
		})
		.addCase(actions.setDemoReport, (state, { payload }) => {
			state.demoPDFDocument = payload;
		});
});

export const reducer = createReducer(initialState, builder => {
	builder.addDefaultCase((state, action) => {
		// This can be used to log all unknown actions
		// console.log('Unknown action: ', action);
	});
});

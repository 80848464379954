import { RootState } from 'store';

export enum ResultTypeName {
	TeamMember,
	Team,
	HwAsset,
	SwAsset,
}

export interface ResultType {
	type: string;
	items: Result[];
}

export interface Result {
	name: string;
	description: string;
	id: string;
	type: ResultTypeName;
}

export const getResults = (state: RootState) => {
	if (state.search.text.length == 0) {
		return null;
	} else {
		return {
			teams: getTeams(state),
			teamMembers: getMembers(state),
			hwAssets: getHwAssets(state),
			swAssets: getSwAssets(state),
		};
	}
};

const getHwAssets = (state: RootState) => {
	return state.assets.hardwareAssets
		.filter(
			asset =>
				asset?.name
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()) ||
				asset?.category
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()),
		)
		.map(asset => {
			return {
				name: `${asset.name}`,
				description: `${
					asset.category ? asset.category : 'Hardware Asset'
				}`,
				id: asset.id,
				type: ResultTypeName.HwAsset,
			};
		});
};

const getSwAssets = (state: RootState) => {
	return state.assets.softwareAssets
		.filter(
			asset =>
				asset?.name
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()) ||
				asset?.category
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()) ||
				asset?.procurementVendor
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()),
		)
		.map(asset => {
			return {
				name: `${asset.name}`,
				description: `${
					asset.category ? asset.category : 'Software Asset'
				}`,
				id: asset.id,
				type: ResultTypeName.SwAsset,
			};
		});
};

const getMembers = (state: RootState) => {
	return state.teamMembers.members
		.filter(
			teamMember =>
				teamMember?.firstName
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()) ||
				teamMember?.lastName
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()),
		)
		.map(tm => {
			return {
				name: `${tm.firstName} ${tm.lastName}`,
				firstName: tm.firstName,
				lastName: tm.lastName,
				description: `${tm.jobTitle ? tm.jobTitle : 'User'}`,
				id: tm.id,
				type: ResultTypeName.TeamMember,
				avatar: tm.avatar,
			};
		});
};

const getTeams = (state: RootState) => {
	return state.teams.teams
		.filter(
			team =>
				team?.name
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()) ||
				team?.teamLocation
					?.toLowerCase()
					?.includes(state?.search?.text?.toLowerCase()),
		)
		.map(team => {
			return {
				name: `${team.name}`,
				description: `${
					team.teamLocation ? team.teamLocation : 'Team'
				}`,
				id: team.id,
				type: ResultTypeName.Team,
			};
		});
};

export const getSearchText = (state: RootState) => {
	return state.search.text;
};

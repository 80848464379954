import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from 'react';

interface TopComplianceIssuesProps {}

const rows = [
	{
		id: 1,
		status: 'Device Health',
	},
	{
		id: 2,
		status: 'Patch Missing',
	},
	{
		id: 3,
		status: 'MFA Error',
	},
];

const TopComplianceIssues: React.FunctionComponent<
	TopComplianceIssuesProps
> = props => {
	return (
		<DataGrid
			rows={rows}
			columns={columns}
			pageSize={5}
			autoHeight={true}
			disableSelectionOnClick
			hideFooterPagination={true}
			hideFooter={true}
			hideFooterSelectedRowCount={true}
		/>
	);
};
const columns: GridColDef[] = [
	{
		field: 'status',
		headerName: 'Status',
		headerAlign: 'center',
		width: 160,
	},
];

export default TopComplianceIssues;

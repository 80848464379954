import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (
	props: SVGProps<SVGSVGElement>,
) => (
	<svg
		width={40}
		height={40}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M20 33.167c1.467 0 2.667-1.17 2.667-2.599h-5.333c0 1.43 1.187 2.599 2.667 2.599Zm8-7.795v-6.496c0-3.988-2.186-7.327-6-8.21v-.884c0-1.078-.893-1.949-2-1.949-1.106 0-2 .87-2 1.95v.883c-3.826.883-6 4.209-6 8.21v6.496L9.335 27.97v1.3h21.333v-1.3l-2.666-2.598Z"
			fill="#4282B3"
		/>
		<ellipse
			cx={31.203}
			cy={9.185}
			rx={5.203}
			ry={5.185}
			fill="#DD3B8C"
		/>
	</svg>
);

export default SvgComponent;

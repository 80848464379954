export const COMPONENTS = {
	chip: `
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.16px;
  `,
	buttonSmall: `
    font-family: 'Lato';
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
  `,
	buttonLarge: `
    font-family: 'Lato';
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.46px;
    text-transform: none;
  `,
	inputText: `
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  `,
	inputLabel: `
    font-family: 'Lato';
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.15px;
  `,
	helperText: `
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
  `,
	tableHeader: `
    font-family: 'Lato';
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
  `,
	avatarInitials: `
    font-family: 'Lato';
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.14px;
  `,
	tooltip: `
    font-family: 'Lato';
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
  `,
};

import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';

interface ProgressBarProps {
	title: string;
	maxValue: number;
	currentValue: number;
	className?: string;
}

export const ProgressBar = ({
	title,
	maxValue,
	currentValue,
	className,
}: ProgressBarProps) => {
	const getFillerWidth = (
		maxCompletedValue: number,
		completedValue: number,
	) => {
		if (maxCompletedValue) {
			const ratio = Number(completedValue) / maxCompletedValue;
			return ratio > 1 ? '100%' : `${ratio * 100}%`;
		}
		return 0;
	};

	return (
		<Root className={className}>
			<Title>{title}</Title>
			<ProgressWrap>
				<ProgressLine
					$fillerWidth={getFillerWidth(maxValue, currentValue)}
				/>
			</ProgressWrap>
		</Root>
	);
};

const Root = styled.div`
	max-width: 100%;
	width: 100%;
`;

const Title = styled.div`
	${TYPOGRAPHY.body1}
	color: ${COLORS.textPrimary};
	margin-bottom: 6.5px;
`;

const ProgressWrap = styled.div`
	width: 100%;
	height: 5px;
	background-color: #adcee3;
`;

const ProgressLine = styled.div<{ $fillerWidth: string | number }>`
	width: ${({ $fillerWidth }) => $fillerWidth};
	height: 5px;
	background-color: ${COLORS.primaryMain};
`;

import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { Notification } from './types';

export interface NotificationsState {
	notifications: Notification[];
}

const initialState: NotificationsState = {
	notifications: [],
};

export const reducer = createReducer(initialState, builder =>
	builder.addCase(actions.setNotifications, (state, { payload }) => {
		state.notifications = payload;
	}),
);

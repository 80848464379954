import { RootState } from 'store';

import { OnboardingProgress } from './types';

export const selectOnboardingProgress = (
	state: RootState,
): OnboardingProgress => state?.onboarding?.onboardingProgress;

// export const selectIsOnboardingBeforeConsent = (state: RootState): boolean =>
// 	[
// 		OnboardingProgress.Introduction,
// 		OnboardingProgress.BasicInformation,
// 		OnboardingProgress.FirstAssessmentConsent,
// 	].indexOf(state?.onboarding?.onboardingProgress) > -1;

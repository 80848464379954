import { RootState } from 'store';

import { Customer, Invite } from './types';

export const getCustomers = (state: RootState) => {
	const result: Customer[] = state?.customers?.customers;
	return result;
};

export const getUnactivatedInvites = (state: RootState) => {
	const result: Invite[] = state?.customers?.unactivatedInvites;
	return result;
};

export const getinviteData = (state: RootState) => {
	return state?.customers?.inviteData;
};

export const selectCustomerById = (id: string) => (state: RootState) => {
	const result = state?.customers?.customers?.find(c => c?.id == id);
	return result ? result : null;
};

export const selectInviteById = (id: string) => (state: RootState) => {
	const result = state?.customers?.unactivatedInvites?.find(c => c?.id == id);
	return result ? result : null;
};

import { HardwareAsset } from 'common/types';
import { RootState } from 'store';
import { OptionType } from 'types';

export const selectSaasAssets = (state: RootState) => state.assets.saasAssets;

export const selectSaasAssetById = (id: string) => (state: RootState) => {
	return state.assets.saasAssets.find(asset => asset.id === id);
};

export const selectHardwareAssetById = (id: string) => (state: RootState) =>
	state.assets.hardwareAssets.find(asset => asset.id === id);

export const selectHardwareAssets = (state: RootState): HardwareAsset[] => {
	const selectedNinjaOrganozation = state.ninja.slectedOrganization;
	if (selectedNinjaOrganozation) {
		const filteredAssets = state.assets.hardwareAssets.filter(hw => {
			return hw?.ninjaOrganizationId == selectedNinjaOrganozation;
		});
		return filteredAssets;
	}
	return state.assets.hardwareAssets;
};
export const selectHardwareAssetsAsOptionType = (
	state: RootState,
): OptionType[] => {
	const selectedNinjaOrganozation = state.ninja.slectedOrganization;
	if (selectedNinjaOrganozation) {
		const filteredAssets = state.assets.hardwareAssets.filter(hw => {
			return hw?.ninjaOrganizationId == selectedNinjaOrganozation;
		});
		return filteredAssets.map(hw => {
			return {
				label: hw.name,
				value: hw.id,
			};
		});
	}
	return state.assets.hardwareAssets.map(hw => {
		return {
			label: hw.name,
			value: hw.id,
		};
	});
};

export const selectAvailableHardwareAssets = (
	state: RootState,
): HardwareAsset[] => {
	const selectedNinjaOrganozation = state.ninja.slectedOrganization;
	if (selectedNinjaOrganozation) {
		const filteredAssets = state.assets.hardwareAssets.filter(hw => {
			return (
				hw?.ninjaOrganizationId == selectedNinjaOrganozation &&
				!hw?.assignedTeamMember &&
				!hw?.assignedTeamMemberId
			);
		});
		return filteredAssets;
	}
	return state.assets.hardwareAssets.filter(
		hw => !hw?.assignedTeamMember && !hw?.assignedTeamMemberId,
	);
};

export const selectAvailableHardwareAssetsAsOptionType = (
	state: RootState,
): OptionType[] => {
	const selectedNinjaOrganozation = state.ninja.slectedOrganization;
	if (selectedNinjaOrganozation) {
		const filteredAssets = state.assets.hardwareAssets.filter(hw => {
			return (
				hw?.ninjaOrganizationId == selectedNinjaOrganozation &&
				!hw?.assignedTeamMember &&
				!hw?.assignedTeamMemberId
			);
		});
		return filteredAssets.map(hw => {
			return {
				label: hw.name,
				value: hw.id,
			};
		});
	}
	return state.assets.hardwareAssets
		.filter(hw => !hw?.assignedTeamMember && !hw?.assignedTeamMemberId)
		.map(hw => {
			return {
				label: hw.name,
				value: hw.id,
			};
		});
};

export const selectSoftwareAssetById = (id: string) => (state: RootState) =>
	state.assets.softwareAssets.find(asset => asset.id === id);

export const selectSoftwareAssets = (state: RootState) => {
	const selectedNinjaOrganozation = state.ninja.slectedOrganization;
	return selectedNinjaOrganozation ? [] : state.assets.softwareAssets;
};

export const selectSoftwareAssetsAsOptionType = (
	state: RootState,
): OptionType[] => {
	const selectedNinjaOrganozation = state.ninja.slectedOrganization;
	return selectedNinjaOrganozation
		? []
		: state.assets.softwareAssets.map(sw => {
				return {
					label: sw.name,
					value: sw.id,
				};
		  });
};

export const selectAssetsCount = (state: RootState): number => {
	const hardwareAssetsCount = state?.assets?.hardwareAssets?.length || 0;
	const softwareAssetsCount = state?.assets?.softwareAssets?.length || 0;
	return hardwareAssetsCount + softwareAssetsCount;
};

import * as React from 'react';
import { SVGProps } from 'react';

const SvgHighSecurityIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={20}
		height={22}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M19.966 5.39a1.094 1.094 0 0 0-.607-.874l-8.864-4.4a1.114 1.114 0 0 0-.991 0L.64 4.516a1.108 1.108 0 0 0-.606.874C.022 5.508-1.03 17.233 9.55 21.904a1.101 1.101 0 0 0 .9 0c10.58-4.671 9.528-16.395 9.516-16.514ZM10 19.685V11H2.81a16.901 16.901 0 0 1-.603-4.801L10 2.33V11h7.157c-.84 3.013-2.767 6.576-7.157 8.685Z"
			fill="#4CAF50"
		/>
	</svg>
);

export default SvgHighSecurityIcon;

import DomainIcon from '@mui/icons-material/Domain';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FunctionComponent } from 'react';

export interface SecurityScoreTileProps {
	score: number | null | undefined;
	companyName?: string | null | undefined;
	timestamp?: string;
}

export const SecurityScoreTile: FunctionComponent<
	SecurityScoreTileProps
> = props => {
	const { score, companyName, timestamp } = props;

	const getColor = (score: number | undefined | null) => {
		if (score == 100) {
			return 'success';
		} else if (score && score >= 75) {
			return 'warning';
		} else {
			return 'error';
		}
	};
	return (
		<Paper elevation={4} sx={sxRoot}>
			<Box sx={sxInfo}>
				<Box sx={sxLogo}>
					<DomainIcon sx={{ height: '75px', width: '75px' }} />
				</Box>
				<Box sx={sxCustomer}>
					<Typography variant="h5">
						{companyName ? companyName : 'Report'}
					</Typography>
					<Typography variant="body1">
						Report generated: {timestamp}
					</Typography>
				</Box>
			</Box>
			<Box sx={sxProgress}>
				{score && (
					<Box sx={{ width: '100%', height: '100%' }}>
						<CircularProgress
							color={getColor(score)}
							variant="determinate"
							value={score}
							size="6rem"
							sx={{ zIndex: 0 }}
							thickness={6}
						/>
						<Typography
							variant="body1"
							sx={sxCaption}>{`${Math.round(
							score,
						)}%`}</Typography>
					</Box>
				)}
			</Box>
			<Box />
		</Paper>
	);
};
const sxRoot = {
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '20px',
	width: '100%',
	backgroundColor: '#F9F9F9',
	minHeight: '100px',
};

const sxLogo = {};

const sxInfo = {
	display: 'flex',
	flexDirection: 'row',
	ml: 2,
	alignItems: 'center',
};
const sxCustomer = {
	ml: 4,
};
const sxProgress = {
	mr: 2,
	position: 'relative',
	display: 'inline-flex',
};

const sxCaption = {
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

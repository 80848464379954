export const COLORS = {
	primaryMain: '#6501E5',
	secondaryMain: '#9573D0',
	textPrimary: '#000000DE',
	textSecondary: '#00000099',
	successMain: '#4caf50',
	errorMain: '#F44336',
	warningMain: 'rgba(255, 152, 0, 0.5)',
	gray400: '#BDBDBD',
};

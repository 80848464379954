export enum ActionType {
	AUTH_AZURE = 'AUTH/AUTH_AZURE',
	ACTIVATE_INVITE_AZURE = 'AUTH/ACTIVATE_INVITE_AZURE',
	LOGIN_WITH_REDIRECT = 'AUTH/LOGIN_WITH_REDIRECT',
	AUTH_MSP_AZURE = 'AUTH/AUTH_MSP_AZURE',
	AUTH_MSP_AZURE_SIGNIN = 'AUTH/AUTH_MSP_AZURE_SIGNIN',
	AUTH_MSP_AZURE_SIGNUP = 'AUTH/AUTH_MSP_AZURE_SIGNUP',
	AUTH_END_CUSTOMER = 'AUTH/AUTH_END_CUSTOMER',
	AUTH_DEMO_AZURE = 'AUTH/AUTH_DEMO_AZURE',
	LOGIN = 'AUTH/LOGIN',
	LOGOUT = 'AUTH/LOGOUT',
}

export enum Role {
	MSP = 'MSP',
	EndCustomer = 'EndCustomer',
}

export enum MspAzureResponse {
	NOT_FOUND = 'NOT_FOUND',
	INVALID_TOKEN = 'INVALID_TOKEN',
	SUCCESS = 'SUCCESS',
	UNKNOWN = 'UNKNOWN',
}

export interface itOSAuthToken {
	userId?: string;
	email?: string;
	tenantId?: string;
	name?: string;
	source?: string;
	iat?: number;
	exp?: number;
	iss?: string;
	firstName: string;
	lastName: string;
	role: Role;
	organizationName: string;
	mspId?: string;
	endCustomerId?: string;
}

export interface InviteActivationPayload {
	inviteId: string;
	inviteMapId: string;
}

export enum RedirectTargets {
	CustomerReportView = 'CustomerReportView',
	InviteLogin = 'InviteLogin',
}

type CustomerRedirectPayload = {
	redirectTarget: RedirectTargets.CustomerReportView;
	redirectPayload: null;
};
type InviteLoginPayload = {
	redirectTarget: RedirectTargets.InviteLogin;
	redirectPayload: { inviteId: string };
};

export type LoginWithRedirectPayload =
	| CustomerRedirectPayload
	| InviteLoginPayload;

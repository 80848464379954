import { Box, Portal } from '@mui/material';
import { ReactChild, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import { OptionType } from 'types';

import { Chip } from '..';

interface TooltipProps {
	className?: string;
	content?: ReactNode | string | OptionType[];
	children?: ReactChild;
	condition?: boolean;
}

const renderChip = (arrayData: OptionType[]) =>
	arrayData.map(item => (
		<StyledChip key={item.value} variant="secondary" text={item.label} />
	));

export const Tooltip = ({
	className,
	content,
	children,
	condition = true,
}: TooltipProps) => {
	const parentRef = useRef(null);
	const [isHover, setIsHover] = useState(false);

	const handleHover = (value: boolean) => () => {
		setIsHover(value);
	};

	return (
		<Box width={'25%'}>
			<Root
				ref={parentRef}
				className={className}
				onMouseEnter={handleHover(true)}
				onMouseLeave={handleHover(false)}>
				{children}
			</Root>
			{condition && isHover && (
				<Portal container={parentRef.current}>
					<TooltipContent>
						{Array.isArray(content)
							? renderChip(content as OptionType[])
							: content}
					</TooltipContent>
				</Portal>
			)}
		</Box>
	);
};

const Root = styled.div`
	position: relative;
`;

const TooltipContent = styled.div`
	position: absolute;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;

	background-color: #fff;
	box-shadow: 2px 2px 5px #00000022;

	border-radius: 4px;
	padding: 7px;

	z-index: 999;
`;

const StyledChip = styled(Chip)`
	&:not(:last-child) {
		margin-right: 8px;
		margin-bottom: 4px;
	}
`;

import { TeamMember } from 'common/types';

export enum OnboardingStepStatus {
	IN_PROGRESS = 'IN PROGRESS',
	TO_DO = 'TO DO',
	COMPLETED = 'COMPLETED',
}

export interface AzureTeam {
	department_name: string;
	count: string;
}

export type OptionType = {
	label: string;
	value: string;
};

export type StepItemType = {
	name: string;
	onClick: () => void;
};

export interface MapInformationItem {
	id: string;
	isMatched: boolean;
	columnHeader: string;
	preview: string;
	itOSProperty: OptionType;
	isOverwriteExisting: boolean;
}

export enum OnboardingSteps {
	ImportFiles = 0,
	Map,
	Done,
}

export interface TabPanelProps {
	valueOfTab: number;
	currentTabValue: number;
}

export enum ProblemStatus {
	PATCH_MISSING = 'Patch Missing',
	MFA_ERROR = 'MFA Error',
	DEVICE_HEALTH = 'Device Health',
}

export interface UserProblemInfo {
	id: string;
	userName: string;
	device: string;
	problemStatus: SecurityStatus;
}
export enum SecurityStatus {
	LOW = 'low',
	MEDIUM = 'medium',
	HIGH = 'high',
}

export type TeamType = {
	id: string;
	name: string;
	teamSecurity: SecurityStatus;
	countOfProblems: number;
};

export type TeamMemberType = {
	id: string;
	name: string;
	title: string;
	hardware: OptionType[];
	softwares: OptionType[];
	security: SecurityStatus;
	isMfaEnabled: boolean;
};

export interface TeamSecurityInfo {
	securityStatus: SecurityStatus;
	devicesWithEncryption: number;
	itOSUnifiedCompliance: number;
	multifactorAuth: number;
	vulnerableAssets: number;
	patchLevel: number;
	teamMembers: TeamMember[];
}

export enum AssetsStatus {
	InService = 'In-Service',
	Available = 'Available',
	Assigned = 'Assigned',
}

export type AssetType = {
	id: string;
	name: string;
	platform: string;
	quantity: number;
	assignedTo: string;
	status: AssetsStatus;
	security: SecurityStatus;
	price: number;
	// Hardware Asset only props
	isAzureDevice?: boolean;
	isAzureIntuneDevice?: boolean;
	isNinjaDevice?: boolean;
};

export enum AssetTypes {
	None = 'None',
	SaaS = 'SaaS',
	Software = 'Software',
	Hardware = 'Hardware',
	CloudInfrastructure = 'Cloud Infrastructure',
}

export type ServiceType = {
	id: string;
	name: string;
	logo: string;
};

type BasicCompanyFormValues = {
	avatarKey?: string;
	companyName: string;
	hqAddress: string;
	city: string;
	state: string;
	zipCode: string;
	primaryPhone: string;
	domain: string;
	ninjaOrgId?: string;
	jiraId?: string;
	pax8Id?: string;
};

type PrimaryContactFormValues = {
	primaryFirstName: string;
	primaryLastName: string;
	primaryJobTitle: string;
	primaryEmail: string;
	primaryMobilePhone: string;
	primaryNotes: string;
};

type SecondaryContactFormValues = {
	secondaryFirstName: string;
	secondaryLastName: string;
	secondaryJobTitle: string;
	secondaryEmail: string;
	secondaryMobilePhone: string;
	secondaryNotes: string;
};

export type AddClientFormValues = BasicCompanyFormValues &
	Partial<PrimaryContactFormValues> &
	Partial<SecondaryContactFormValues>;

export type SoftwareAssetsFormValues = {
	name?: string;
	category?: string;
	quantity?: number;
	procurementVendor?: string;
	serialNumber?: string;
	costOfAsset?: number;
	datePurchased: Date;
	warrantyExpiration: Date;
	purchasingManagerId?: string;
	subjectExpertId?: string;
	isVendorSupport: boolean;
	accountNumber?: string;
	comments?: string;
	isAddAnother: boolean;
	assignedTeamMemberIds?: string[];
};

export enum MspTypeOptions {
	MSP = 'MSP - Managed Service Provider',
	MSSP = 'MSSP - Managed Security Service Provider',
	COMIT = 'Co-MIT - Co-Managed IT Services Provier',
	MDR = 'MDR - Managed Detection & Response',
	OTHER = 'Other',
}

export const MspTypeOptionsArray = Object.keys(MspTypeOptions).map(option => {
	return {
		option,
		value: MspTypeOptions[option as keyof typeof MspTypeOptions],
	};
});

export enum MspCustomerOptions {
	SMALL = '0 to 10 customers',
	MEDIUM = '10 to 50 customers',
	LARGE = '50 to 100 customers',
	XLARGE = 'More than 100 customers',
}

export const MspCustomerOptionsArray = Object.keys(MspCustomerOptions).map(
	option => {
		return {
			option,
			value: MspCustomerOptions[
				option as keyof typeof MspCustomerOptions
			],
		};
	},
);

export enum MspUsersOptions {
	SMALL = '20 or fewer',
	MEDIUM = '20 to 50',
	LARGE = '50 to 100',
	XLARGE = '100 to 500',
	XXLARGE = 'more than 500',
}
export const MspUsersOptionsArray = Object.keys(MspUsersOptions).map(option => {
	return {
		option,
		value: MspUsersOptions[option as keyof typeof MspUsersOptions],
	};
});
export enum MspRevenueOptions {
	XSMALL = 'up to $100k',
	SMALL = '$100k to $250k',
	MEDIUM = '$500k to $1M',
	LARGE = '$1M to $5M',
	XLARGE = '$5M to $10M',
	XXLARGE = 'more than $10M',
}

export const MspRevenueOptionsArray = Object.keys(MspRevenueOptions).map(
	option => {
		return {
			option,
			value: MspRevenueOptions[option as keyof typeof MspRevenueOptions],
		};
	},
);

export const formatDate = (dateString: string | Date) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('en-us', {
		weekday: 'long',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
};

export const formatTime = (dateString: string | Date) => {
	return convertUTCDateToLocalDate(new Date(dateString)).toLocaleTimeString(
		[],
		{ hour: '2-digit', minute: '2-digit' },
	);
};

function convertUTCDateToLocalDate(date: Date) {
	const newDate = new Date(
		date.getTime() + date.getTimezoneOffset() * 60 * 1000,
	);

	const offset = date.getTimezoneOffset() / 60;
	const hours = date.getHours();

	newDate.setHours(hours - offset);

	return newDate;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from 'services/http-client/http-client';
import { toaster } from 'services/toaster';

import { ActionType } from './types';

/* QUCIKBOOKS-ACTIONS */

export const synchronizeQuickbooks = createAsyncThunk<void, string>(
	ActionType.QUCIKBOOKS_CALLBACK,
	async code => {
		try {
			toaster({
				message: 'Quickbooks Synchronization Started',
				variant: 'warning',
			});
			await httpClient.post('/quickbooks/callback', { code });
			toaster({
				message: 'Quickbooks Synchronization Finished',
				variant: 'success',
			});
		} catch (e) {
			console.log(e);
		}
	},
);

import { RootState } from 'store';

import { Report } from './types';

export const latestReportGenerationTime = (state: RootState) => {
	return state.cybersecurityReport?.report?.createdTime;
};

export const latestReportId = (state: RootState) => {
	return state.cybersecurityReport?.report?.id;
};

export const isReportExists = (state: RootState): boolean => {
	return state.cybersecurityReport?.isReportExists || false;
};

export const getReportHistory = (state: RootState) => {
	return state?.cybersecurityReport?.reportHistory;
};

export const fetchSelectedReport = (state: RootState) => {
	if (!state.cybersecurityReport?.report) {
		return null;
	} else {
		return state.cybersecurityReport?.report;
	}
};

export const getSortedReports = (state: RootState) => {
	const reports: Report[] = [];
	state.cybersecurityReport?.reportMap?.forEach((v, k) => {
		v.forEach(report => {
			const transformedReport = {
				...report,
				endCustomerId: k,
			};
			reports.push(transformedReport);
		});
	});
	const sortedReports = reports.sort((a, b) => {
		if (a.createdTime < b.createdTime) {
			return -1;
		} else return 1;
	});
	return sortedReports;
};

export const getReportsForCustomer = {};
export const getReportForId = {};

export const fetchReportValues = (state: RootState) => {
	if (!state.cybersecurityReport?.report) {
		return null;
	}

	return [
		{
			id: '1',
			headline: 'Active Global Admins',
			subhead: `${state.cybersecurityReport?.report?.numberOfGlobalAdmins} of ${state.cybersecurityReport?.report?.totalUsers}`,
			body: 'Three or fewer Global Admins recommended.',
			heroStat: `${state.cybersecurityReport?.report?.numberOfGlobalAdmins}`,
		},
		{
			id: '2',
			headline: 'Fully Licensed Users',
			subhead: `${state.cybersecurityReport?.report?.licensedUsers} of ${state.cybersecurityReport?.report?.totalUsers}`,
			body: 'Only licensed users are allowed.',
			heroStat:
				state.cybersecurityReport?.report?.licensedUsers &&
				state.cybersecurityReport?.report?.totalUsers
					? `${
							state.cybersecurityReport?.report?.totalUsers > 0 &&
							Math.round(
								(state.cybersecurityReport?.report
									?.licensedUsers /
									state.cybersecurityReport?.report
										?.totalUsers) *
									100,
							)
					  }%`
					: 'N/A',
		},
		{
			id: '3',
			headline: 'MFA Enabled',
			subhead: state.cybersecurityReport?.report?.mfaStatus
				? `${state.cybersecurityReport?.report?.mfaStatus} of ${state.cybersecurityReport?.report?.totalUsers}`
				: '',
			body: state.cybersecurityReport?.report?.mfaStatus
				? 'Multi-factor authentication is recommended for all users.'
				: 'Multi-factor authentication has not been enabled for this organization.',
			heroStat:
				state.cybersecurityReport?.report?.mfaStatus &&
				state.cybersecurityReport?.report?.totalUsers
					? `${
							state.cybersecurityReport?.report?.totalUsers > 0 &&
							Math.round(
								(state.cybersecurityReport?.report?.mfaStatus /
									state.cybersecurityReport?.report
										?.totalUsers) *
									100,
							)
					  }%`
					: '!',
		},
		{
			id: '4',
			headline: 'Inactive Users',
			subhead: `${state.cybersecurityReport?.report?.inactiveUsers} of ${state.cybersecurityReport?.report?.totalUsers}`,
			body: 'Inactive users should be disabled.',
			heroStat: `${state.cybersecurityReport?.report?.inactiveUsers}`,
		},
		{
			id: '5',
			headline: 'Email Forwarding',
			subhead: `${state.cybersecurityReport?.report?.usersWithEmailForwarding} of ${state.cybersecurityReport?.report?.totalUsers}`,
			body: 'Allowing email forwarding is not reccomended.',
			heroStat: `${state.cybersecurityReport?.report?.usersWithEmailForwarding}`,
		},
		{
			id: '6',
			headline: 'Permissions Delegated',
			body: 'Delegated permissions should be reviewed carefully and often.',
			heroStat: `${state.cybersecurityReport?.report?.dangerousDelegatedAccess}`,
		},
		{
			id: '7',
			headline: 'Device Encryption',
			body: 'Encryption is recommended for all devices.',
			heroStat: `${state.cybersecurityReport?.report?.managedDeviceEncryptionPercentage}%`,
		},
		{
			id: '8',
			headline: 'Shared Files',
			subhead: 'Past 90 days.',
			heroStat: `${state.cybersecurityReport?.report?.numberOfPubliclySharedFilesInLast90Days}`,
			body: 'We recommend regular auditing of public file links.',
		},
		{
			id: '9',
			headline: 'Risky Sign-ins',
			subhead: 'Past 30 days',
			body: 'All sign-ins must be from secured and compliant devices.',
			heroStat: `${state.cybersecurityReport?.report?.riskySignInsPercentage}%`,
		},
		{
			id: '10',
			headline: 'Password Expiration',
			subhead: state.cybersecurityReport?.report
				?.usersWithoutPasswordExpiration
				? `${state.cybersecurityReport?.report?.usersWithoutPasswordExpiration} of ${state.cybersecurityReport?.report?.totalUsers}`
				: '',
			body: state.cybersecurityReport?.report
				?.usersWithoutPasswordExpiration
				? 'These users might never change their password.'
				: 'No password expiration policy has been set for this organization',
			heroStat:
				state.cybersecurityReport?.report
					?.usersWithoutPasswordExpiration &&
				state.cybersecurityReport?.report?.totalUsers
					? `${
							state.cybersecurityReport?.report?.totalUsers > 0 &&
							Math.round(
								(state.cybersecurityReport?.report
									?.usersWithoutPasswordExpiration /
									state.cybersecurityReport?.report
										?.totalUsers) *
									100,
							)
					  }%`
					: '!',
		},
	];
};

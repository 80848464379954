import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from 'services/http-client/http-client';

import { ActionType, Notification } from './types';

/* NOTIFICATIONS */

export const fetchNotifications = createAsyncThunk<void>(
	ActionType.FETCH_NOTIFICATIONS,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<Notification[]>(
				'/notifications',
			);
			dispatch(setNotifications(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const setNotifications = createAction<Notification[]>(
	ActionType.SET_NOTIFICATIONS,
);

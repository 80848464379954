import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Dialog, IconButton, TextField } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { Button } from 'components/UIComponents';
import { useAppDispatch } from 'hooks/redux';
import { useState } from 'react';
import { toaster } from 'services/toaster';
import { actions } from 'store';
import styled from 'styled-components';

interface TicketInputProps {
	isOpen: boolean;
	onClose: any;
}

export const TicketInput = ({ isOpen, onClose }: TicketInputProps) => {
	const [issueDescription, setDescription] = useState('');

	const handleCloseIconClick = () => {
		setDescription('');
		onClose();
	};

	const dispatch = useAppDispatch();

	const handleSubmit = async () => {
		const res = await dispatch(
			actions.feedback.postFeedback({
				text: issueDescription,
			}),
		);

		try {
			const isFeedbackCreated = unwrapResult(res);

			if (isFeedbackCreated) {
				toaster({
					message: 'Your help request has been submitted',
					variant: 'success',
				});
			} else {
				toaster({
					message: 'Something went wrong. Please try again.',
					variant: 'error',
				});
			}
		} catch (e) {
			console.log(e);
		}
		onClose();
		setDescription('');
	};

	return (
		<Dialog
			PaperProps={{
				style: {
					position: 'absolute',
					top: '12%',
					maxHeight: '80%',
				},
			}}
			fullWidth={true}
			maxWidth="lg"
			open={isOpen}
			onClose={onClose}>
			<Header>
				<Avatar
					sx={{
						width: 60,
						height: 60,
						bgcolor: 'white',
						color: 'black',
						mr: '50px',
					}}>
					itOS
				</Avatar>
				Support System
				<IconButton
					onClick={() => handleCloseIconClick()}
					sx={{ ml: 'auto' }}
					color="inherit"
					size="large">
					<CloseIcon fontSize="inherit" />
				</IconButton>
			</Header>
			<InputArea>
				<TextField
					id="outlined-multiline-static"
					// Add back when changing input to redux property
					// autoFocus={true}
					fullWidth={true}
					label="Describe your issue"
					multiline
					rows={4}
					defaultValue={issueDescription}
					onChange={e => setDescription(e.target.value)}
				/>
			</InputArea>
			<StyledButton
				onClick={() => handleSubmit()}
				text="Submit"
				variant="secondary"
			/>
		</Dialog>
	);
};

const Header = styled.div`
	${TYPOGRAPHY.h4}
	background-color: ${COLORS.secondaryMain};
	color: white;
	display: flex;
	align-items: center;
	padding: 2%;
`;

const InputArea = styled.div`
	${TYPOGRAPHY.body1}
	padding: 2%;
`;

const StyledButton = styled(Button)`
	&& {
		min-width: 106px;
		height: 31px;
		text-transform: none;
		font-weight: 400;
		padding: 1%;
		margin: 2%;
		margin-left: auto;
	}
`;

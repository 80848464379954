import { combineReducers } from 'redux';

import * as assets from './stores/assets';
import * as auth from './stores/auth';
import * as azureAction from './stores/azure/actions';
import { reducer as azureReducer } from './stores/azure/reducer';
import * as azureSelectors from './stores/azure/selectors';
import * as compliance from './stores/compliance';
import * as customers from './stores/customers';
import * as cybersecurityReport from './stores/cybersecurity-report';
import * as feedback from './stores/feedbacks';
import * as greetings from './stores/greetings';
import * as gusto from './stores/gusto';
import * as hubSpot from './stores/hubSpot';
import * as jira from './stores/jira';
import * as msp from './stores/msp';
import * as ninja from './stores/ninja';
import * as notifications from './stores/notifications';
import * as onboarding from './stores/onboarding';
import * as organizations from './stores/organizations';
import * as pax8 from './stores/pax8';
import * as quickbooks from './stores/quickbooks';
import * as search from './stores/search';
import * as teamMembers from './stores/team-members';
import * as teams from './stores/teams';
import * as users from './stores/users';

export const rootReducer = combineReducers({
	auth: auth.reducer,
	teams: teams.reducer,
	teamMembers: teamMembers.reducer,
	assets: assets.reducer,
	jira: jira.reducer,
	ninja: ninja.reducer,
	organizations: organizations.reducer,
	pax8: pax8.reducer,
	greetings: greetings.reducer,
	azure: azureReducer,
	search: search.reducer,
	notifications: notifications.reducer,
	cybersecurityReport: cybersecurityReport.reducer,
	customers: customers.reducer,
	onboarding: onboarding.reducer,
});

export const actions = {
	auth: auth.actions,
	compliance: compliance.actions,
	onboarding: onboarding.actions,
	teams: teams.actions,
	teamMembers: teamMembers.actions,
	users: users.actions,
	assets: assets.actions,
	jira: jira.actions,
	quickbooks: quickbooks.actions,
	hubSpot: hubSpot.actions,
	ninja: ninja.actions,
	organizations: organizations.actions,
	pax8: pax8.actions,
	greetings: greetings.actions,
	azure: azureAction,
	gusto: gusto.actions,
	search: search.actions,
	feedback: feedback.actions,
	notifications: notifications.actions,
	cybersecurityReport: cybersecurityReport.actions,
	customers: customers.actions,
	msp: msp.actions,
};

export const selectors = {
	auth: auth.selectors,
	teams: teams.selectors,
	teamMembers: teamMembers.selectors,
	assets: assets.selectors,
	jira: jira.selectors,
	ninja: ninja.selectors,
	organizations: organizations.selectors,
	pax8: pax8.selectors,
	greetings: greetings.selectors,
	azure: azureSelectors,
	search: search.selectors,
	notifications: notifications.selectors,
	cybersecurityReport: cybersecurityReport.selectors,
	customers: customers.selectors,
	onboarding: onboarding.selectors,
	msp: msp.selectors,
};

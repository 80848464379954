import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

interface TopVulnerableUsersProps {}

const rows = [
	{
		id: 1,
		device: 'SUM-LAP01.ACME.LOCAL',
		user: 'John Smith',
		status: 'Device Health',
	},
	{
		id: 2,
		device: 'SUM-LAP12.ACME.LOCAL',
		user: 'Olivia Ford',
		status: 'Patch Missing',
	},
	{
		id: 3,
		device: 'SUM-LAP08.ACME.LOCAL',
		user: 'Michael Larson',
		status: 'MFA Error',
	},
	{
		id: 4,
		device: 'SUM-LAP14.ACME.LOCAL',
		user: 'Stacy Fitzegard',
		status: 'Device Health',
	},
	{
		id: 5,
		device: 'SUM-LAP11.ACME.LOCAL',
		user: 'James Oxford',
		status: 'Patch Missing',
	},
];
interface AzureUsers {
	id: string;
	displayName: string;
	userType: string;
	userPrincipalName: string;
	tenantId: string;
	department?: string;
}

const TopVulnerableUsers: React.FunctionComponent<
	TopVulnerableUsersProps
> = props => {
	const authToken = useSelector(selectors.auth.selectToken);

	const [users, setUsers] = React.useState<AzureUsers[]>([]);
	const thisWindow: any = window;
	React.useEffect(() => {
		axios
			.get<AzureUsers[]>(`${thisWindow._env_.API_URL}/azure/users`, {
				headers: {
					authorization: authToken,
				},
			})
			.then(response => {
				setUsers(response.data);
			});
	}, []);
	return (
		<DataGrid
			rows={users}
			columns={columns}
			pageSize={5}
			autoHeight={true}
			disableSelectionOnClick
			hideFooterPagination={true}
			hideFooter={true}
			hideFooterSelectedRowCount={true}
		/>
	);
};
const columns: GridColDef[] = [
	{
		field: 'displayName',
		headerName: 'Name',
		headerAlign: 'center',
		width: 150,
	},
	{
		field: 'department',
		headerName: 'Department',
		headerAlign: 'center',
		width: 160,
	},
];

export default TopVulnerableUsers;

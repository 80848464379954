import { createReducer } from '@reduxjs/toolkit';
import { Pax8Company } from 'common/types';

import * as actions from './actions';

interface Pax8State  {
    pax8Companies: Pax8Company[];
}

const initialState: Pax8State = {
	pax8Companies: [],
};

export const reducer = createReducer(initialState, (builder) => 
	builder
		.addCase(actions.setPax8Companies, (state, {payload}) => {
			state.pax8Companies = [...payload];
		})
);
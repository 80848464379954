export enum ActionType {
	/* AZURE */
	START_LOADING_AZURE = 'AZURE/START_LOADING',
	FINISH_LOADING_AZURE = 'AZURE/FINISH_LOADING',
	SYNC_AZURE = 'AZURE/SYNCHRONIZE',
	FETCH_DEMO_REPORT = 'AZURE/FETCH_DEMO_REPORT',
	SET_DEMO_REPORT = 'AZURE/SET_DEMO_REPORT',
	START_DEMO_REPORT_LOADING = 'AZURE/START_DEMO_REPORT_LOADING',
	FINISH_DEMO_REPORT_LOADING = 'AZURE/FINISH_DEMO_REPORT_LOADING',
}

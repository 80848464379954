import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { TeamMember } from 'common/types';
import { UpdateTeamMemberDto } from 'common/types/team-members-dto';

import httpClient from '../../../services/http-client/http-client';
import { ActionType } from './types';

interface CreateTeamMemberDto {
	startDate: string;
	managerId: string;
	email: string;
	jobTitle: string;
	firstName: string;
	lastName: string;
	teamId: string;
	mobileNumber: string;
	workNumber: string;
	addressFor911: string;
	hardwareAssetIds: string[];
	softwareAssetIds: string[];
	allowLocalAdmin: boolean;
}

export const createTeamMember = createAsyncThunk<
	TeamMember,
	CreateTeamMemberDto
>(ActionType.CREATE_TEAM_MEMBER, async (dto, { dispatch }) => {
	const { data } = await httpClient.post<TeamMember>('/team-members', dto);

	dispatch(addTeamMember(data));
	return data;
});

export const fetchUpdateTeamMember = createAsyncThunk<
	void,
	UpdateTeamMemberDto
>(ActionType.FETCH_UPDATE_TEAM_MEMBER, async (dto, { dispatch }) => {
	try {
		const { data } = await httpClient.patch<TeamMember>(
			`/team-members/${dto.id}`,
			dto,
		);
		dispatch(updateTeamMember(data));
	} catch (e) {
		console.log(e);
	}
});

export const fetchRemoveTeamMember = createAsyncThunk<void, string>(
	ActionType.FETCH_REMOVE_TEAM_MEMBER,
	async (id, { dispatch }) => {
		try {
			const { data } = await httpClient.delete<TeamMember>(
				`/team-members/${id}`,
			);

			dispatch(removeTeamMember(data.id));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchTeamMembers = createAsyncThunk<void, void>(
	ActionType.FETCH_TEAM_MEMBERS,
	async (_, { dispatch }) => {
		let { data } = await httpClient.get<TeamMember[]>('/team-members');
		data = data.filter(
			teamMember => !!teamMember?.firstName || !!teamMember?.lastName,
		);
		dispatch(setTeamMembers(data));
	},
);

export const addTeamMember = createAction<TeamMember>(
	ActionType.ADD_TEAM_MEMBER,
);

export const updateTeamMember = createAction<TeamMember>(
	ActionType.UPDATE_TEAM_MEMBER,
);

export const removeTeamMember = createAction<string>(
	ActionType.REMOVE_TEAM_MEMBER,
);

export const setTeamMembers = createAction<TeamMember[]>(
	ActionType.SET_TEAM_MEMBERS,
);

import { CLASS_NAMES } from 'assets/styles/classNames';
import { COLORS } from 'assets/styles/colors';
import { COMPONENTS } from 'assets/styles/components';

export const themes = {
	// eslint-disable-next-line quotes
	primary: (isMultiple?: boolean) => `
    && {
      ${COMPONENTS.inputText}
      color: #000000DE;

      ${CLASS_NAMES.InputNotchedOutline} {
        border-color: #0000003B;
      }

      ${CLASS_NAMES.SelectIcon} {
        fill: #0000008A;
      }

      &${CLASS_NAMES.OutlinedInputRoot} {
        border-radius: 4px;

        &${CLASS_NAMES.Focused} {
          ${CLASS_NAMES.InputNotchedOutline} {
            border-color: #00000067;
          }
        }

        &:hover {
          ${CLASS_NAMES.InputNotchedOutline} {
            border-color: #00000067;
          }
        }
      }

      ${CLASS_NAMES.SelectMenu} {
        display: flex;
        align-items: center; 
        min-height: 40px;
        box-sizing: border-box;
        padding: 0;
        padding-left: 14px;
        padding-right: 32px;
        ${isMultiple ? 'padding-top: 10px' : 'padding-top: 0'};
        vertical-align: middle;
      }

      ${CLASS_NAMES.Select}:focus {
        background-color: transparent;
      }

      &${CLASS_NAMES.SelectRoot} {
        text-align: left;
      }
    }
  `,
	// eslint-disable-next-line quotes
	secondary: (isMultiple?: boolean) => `
    && {
      ${COMPONENTS.inputText}
      color: ${COLORS.secondaryMain}B2;

      ${CLASS_NAMES.InputNotchedOutline} {
        border-color: ${COLORS.secondaryMain};
      }

      ${CLASS_NAMES.SelectIcon} {
        fill: ${COLORS.secondaryMain};
      }

      &${CLASS_NAMES.OutlinedInputRoot} {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;

        &${CLASS_NAMES.Focused} {
          ${CLASS_NAMES.InputNotchedOutline} {
            border-color: ${COLORS.secondaryMain};
          }
        }

        &:hover {
          ${CLASS_NAMES.InputNotchedOutline} {
            border-color: ${COLORS.secondaryMain};
          }
        }
      }

      ${CLASS_NAMES.SelectMenu} {
        display: flex;
        align-items: center;
        min-height: 40px;
        box-sizing: border-box;
        padding: 0;
        padding-left: 14px;
        padding-right: 32px;
        ${isMultiple ? 'padding-top: 10px' : 'padding-top: 0'};
        vertical-align: middle;
      }

      ${CLASS_NAMES.Select}:focus {
        background-color: transparent;
      }

      &${CLASS_NAMES.SelectRoot} {
        text-align: left;
      }
    }
  `,
};

import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

export interface Greeting {
	salutation: string;
	userName: string;
	message: string;
	buttonText: string;
	colorPrimary: boolean;
	buttonShowing: boolean;
}

export interface GreetingsState {
	greetings: Greeting[];
}

const initialState: GreetingsState = {
	greetings: [],
};

export const reducer = createReducer(initialState, builder =>
	builder
		.addCase(actions.setGreeting, (state, { payload }) => {
			state.greetings = payload;
		})
		.addCase(actions.nextGreeting, state => {
			state.greetings.pop();
		}),
);

import { LogoType } from '../msp/types';

export enum ActionType {
	/* CUSTOMERS */
	FETCH_CUSTOMERS = 'CUSTOMERS/FETCH_CUSTOMERS',
	FETCH_UNACTIVATED_INVITES = 'CUSTOMERS/FETCH_UNACTIVATED_INVITES',
	FETCH_INVITE = 'CUSTOMERS/FETCH_INVITE',
	FETCH_RESEND_INVITE = 'CUSTOMERS/FETCH_RESEND_INVITE',
	FETCH_CANCEL_INVITE = 'CUSTOMERS/FETCH_RESEND_INVITE',
	UPDATE_CUSTOMER_DATA = 'CUSTOMERS/UPDATE_CUSTOMER_DATA',
	SET_INVITE_DATA = 'CUSTOMERS/SET_INVITE_DATA',
	SET_CUSTOMERS = 'CUSTOMERS/SET_CUSTOMERS',
	SET_UNACTIVATED_INVITES = 'CUSTOMERS/SET_UNACTIVATED_INVITES',
	FETCH_STATS = 'CUSTOMERS/FETCH_STATS',
	SET_STATS = 'CUSTOMERS/SET_STATS',
	SEND_ASSESSMENT = 'CUSTOMERS/SEND_ASSESSMENT',
	SEND_REMINDER = 'CUSTOMERS/SEND_REMINDER',
	SET_ACTION_MESSAGE = 'CUSTOMERS/SET_ACTION_MESSAGE',
	SET_END_CUSTOMER_LOGO = 'CUSTOMERS/SET_END_CUSTOMER_LOGO',
}

export interface UpdateCustomerDto {
	endCustomerId?: string;
	companyName?: string;
	companyContactName?: string;
	companyContactEmail?: string;
}

export interface UpdateLogoDto {
	logo: LogoType | null;
	customerId: string;
}

export enum EventType {
	LOGIN = 'Customer Logged In',
	EMAIL_OPENED = 'Invitation Email opened',
	REPORT_GENERATED = 'Report Generated',
}

export interface CustomerStats {
	leads: number;
	reports: number;
	converted: number;
}

export interface EventRecord {
	id: string;
	type: EventType;
	timestamp: Date;
	trackerId: string;
}

export interface Customer {
	id: string;
	azureTenantId: string;
	companyName: string;
	mspId: string;
	domain: string;
	createdTime: Date;
	updatedTime?: Date;
	deletedTime?: Date;
	logo: LogoType | null;
	companyContactEmail: string;
	companyContactPhoneNumber: string;
	companyContactName: string;
	status: EndCustomerStatus;
}

export enum EndCustomerStatus {
	LOGGED_IN = 'End customer logged in',
	ASSESSMENT_COMPLETE = 'End customer assessment complete',
	COMPLETE = 'End customer complete',
}

export interface Invite {
	id: string;
	companyName: string;
	firstName: string;
	lastName: string;
	companyContactEmail: string;
	companyContactName: string;
	companyContactPhone: string;
	address: string;
	phone: string;
	customerType: CustomerType | null;
	logo: LogoType | null;
	createdAt: Date;
	status: InviteStatus;
	mspId: string;
	mspName?: string;
	mspContact?: string;
	mspContactEmail?: string;
	mspPictureId?: string;
}

export enum InviteStatus {
	SENT = 'Invite sent',
	VIEWED = 'Invite viewed',
	ACTIVATED = 'Invite activated',
}
export enum CustomerType {
	FULL = 'Full Service',
	HELPDESK = 'Helpdesk Only',
	INFRASTRUCTURE = 'Infrastructure Management',
}

export const seedData = [
	{
		salutation: 'Good morning',
		userName: 'Mark',
		message: 'Your support request has a comment.',
		buttonText: 'Go',
		colorPrimary: false,
		buttonShowing: true,
	},
	{
		salutation: 'Happy Friday',
		userName: 'Mark',
		message:
			'You still have 3 Users to onboard. Continue the process below.',
		buttonText: 'Continue',
		colorPrimary: true,
		buttonShowing: true,
	},
	{
		salutation: 'Good morning',
		userName: 'Alexandra',
		message:
			'Your support request (IT-1822) has a new comment from Jay Taylor.',
		buttonText: 'Go',
		colorPrimary: false,
		buttonShowing: true,
	},
	{
		salutation: 'Welcome back',
		userName: 'Alexandra',
		message: 'One or more team members are missing a patch.',
		buttonText: 'View',
		colorPrimary: true,
		buttonShowing: true,
	},
	{
		salutation: 'Happy Friday',
		userName: 'Mark',
		message:
			'You still have 3 Users to onboard. Continue the process below.',
		buttonText: 'Continue',
		colorPrimary: true,
		buttonShowing: false,
	},
	{
		salutation: 'Its good to see you',
		userName: 'Mark',
		message:
			'You still have 3 Users to onboard. Continue the process below.',
		buttonText: 'Continue',
		colorPrimary: false,
		buttonShowing: true,
	},
	{
		salutation: 'Good morning',
		userName: 'Alexandra',
		message:
			'Your support request (IT-1822) has a new comment from Jay Taylor.',
		buttonText: 'View',
		colorPrimary: true,
		buttonShowing: true,
	},
	{
		salutation: 'Its good to see you',
		userName: 'Alexandra',
		message: 'We wouldn\t be here without you. How can we improve itOS?',
		buttonText: 'Feedback',
		colorPrimary: false,
		buttonShowing: true,
	},
	{
		salutation: 'Good evening',
		userName: 'Mark',
		message:
			'You still have 3 Users to onboard. Continue the process below.',
		buttonText: 'Continue',
		colorPrimary: false,
		buttonShowing: true,
	},
	{
		salutation: 'Good morning',
		userName: 'Mark',
		message: 'Your support request has a comment.',
		buttonText: 'View',
		colorPrimary: true,
		buttonShowing: true,
	},
	{
		salutation: 'Good evening',
		userName: 'Alexandra',
		message: 'Your Operations Team needs some security updates',
		buttonText: 'View',
		colorPrimary: false,
		buttonShowing: true,
	},
	{
		salutation: 'Welcome back',
		userName: 'Mark',
		message: 'Your support request has a comment.',
		buttonText: 'View',
		colorPrimary: true,
		buttonShowing: false,
	},
];

import { RootState } from 'store';

import { NotificationType } from './types';

export const getNotifications = (state: RootState) => {
	if (state.notifications.notifications.length > 0) {
		return state.notifications.notifications;
	} else {
		return [
			{
				id: '0',
				type: NotificationType.None,
				text: 'You have no notifications',
				isDismissed: false,
				isSeen: false,
			},
		];
	}
};

import { PenIcon } from 'assets/icons/';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { Avatar } from 'components/UIComponents';
import styled from 'styled-components';

interface ClientsListItemProps {
  client: any;
	onSelectClient: (id: string) => void;
  onEditClient: (id: string) => void; 
}

export const ClientsListItem = ({
	client, onSelectClient, onEditClient
}: ClientsListItemProps) => {

	const handleSelectClientClick = (id: string) => () => {
		onSelectClient(id);
	};

	const handleEditClientClick = (id: string) => () => {
		onEditClient(id);
	};

	return (
		<Root>
			<InnerButton onClick={handleSelectClientClick(client.id)}>
				<StyledAvatar
					avatarKey={''}
					firstName={client.name}
					lastName={''}
				/>
				<ClientName>{client.name}</ClientName>
				<EditButton onClick={handleEditClientClick(client.id)}>
					<PenIcon />
				</EditButton>
			</InnerButton>
		</Root>
	);
};

const Root = styled.li`
	position: relative;
  width: 100%;
  padding: 8px 16px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const InnerButton = styled.div`
	width: 100%;
	display: flex;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 16px;
`;

const ClientName = styled.div`
  ${TYPOGRAPHY.body1}
  ${COLORS.textPrimary};
`;

const EditButton = styled.div`
  position: absolute;
  right: 24px;
  top: 50%;
	transform: translateY(-50%);

	cursor: pointer;
`;
import { COLORS } from 'assets/styles/colors';
import { COMPONENTS } from 'assets/styles/components';

export const themes: Record<string, string> = {
	// eslint-disable-next-line quotes
	default: `
    border: 1px solid #0000003B;
    color: ${COLORS.textPrimary};
  `,
	// eslint-disable-next-line quotes
	primary: `
    min-width: 47px;
    min-height: 22px;

    font-weight: 400;
    font-size: 15px;
  	line-height: 22px;

    border: 1px solid ${COLORS.primaryMain};
    color: ${COLORS.primaryMain};
  `,
	// eslint-disable-next-line quotes
	secondary: `
    min-width: 47px;
    min-height: 22px;

    font-weight: 400;
    font-size: 15px;
    line-height: 22px;

    border: 1px solid ${COLORS.secondaryMain};
    color: ${COLORS.secondaryMain};`,
};

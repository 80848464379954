import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import httpClient from 'services/http-client/http-client';
import { toaster } from 'services/toaster';

import { Customer } from '../customers/types';
import { Report } from './types';
import { ActionType } from './types';

/* CYBERSECURITY REPORT */

export const generateNewReport = createAsyncThunk<void>(
	ActionType.GENERATE_NEW_REPORT,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<Report>(
				'/cybersecurity-report/generate',
			);
			dispatch(setReport(data));

			toaster({
				message: 'Report was successfully generated.',
				variant: 'success',
			});
		} catch (e) {
			toaster({
				message: 'There was a problem during report generation.',
				variant: 'error',
			});
			console.log(e);
		}
	},
);

export const fetchMspCustomerReportMap = createAsyncThunk<void>(
	ActionType.FETCH_MSP_CUSTOMER_REPORT_MAP,
	async (_, { dispatch }) => {
		try {
			const reportMap = new Map();
			const waitGroup: any[] = [];
			const { data } = await httpClient.get<Customer[]>('/customers');
			data.forEach(c => {
				waitGroup.push(
					httpClient
						.get<Report[]>(
							`/cybersecurity-report/forCustomer/${c.id}`,
						)
						.then(response => {
							reportMap.set(c.id, response.data);
						}),
				);
			});
			await Promise.all(waitGroup);
			dispatch(setReportMap(reportMap));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchSelectedReport = (id: string | undefined) =>
	createAsyncThunk<void>(
		ActionType.FETCH_SELECTED_REPORT,
		async (_, { dispatch }) => {
			try {
				const { data, status } = await httpClient.get<Report>(
					`/cybersecurity-report?id=${id}`,
				);
				if (status === 200) {
					dispatch(setReport(data));
				}
			} catch (e) {
				console.log(e);
			}
		},
	);

export const fetchIsReportExists = createAsyncThunk<boolean, void>(
	ActionType.FETCH_IS_REPORT_EXISTS,
	async (_, { dispatch }) => {
		try {
			const { data, status } = await httpClient.get<boolean>(
				'/cybersecurity-report/exists',
			);
			if (status >= 400) {
				dispatch(setIsReportExists(false));

				return false;
			} else {
				dispatch(setIsReportExists(data));

				return data;
			}
		} catch (e) {
			console.log(e);
			return false;
		}
	},
);

export const fetchLatestReport = createAsyncThunk<void>(
	ActionType.FETCH_LATEST_REPORT,
	async (_, { getState, dispatch }) => {
		try {
			const result = await dispatch(fetchIsReportExists());
			const isReportExists = unwrapResult(result);
			if (isReportExists) {
				const { data, status } = await httpClient.get<Report>(
					'/cybersecurity-report/latest',
				);
				if (status >= 400) {
					dispatch(setReport(undefined));
				} else {
					dispatch(setReport(data));
				}
			}
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchReportHistory = createAsyncThunk<void>(
	ActionType.FETCH_REPORT_HISTORY,
	async (_, { dispatch }) => {
		try {
			const { data, status } = await httpClient.get<Report[]>(
				'/cybersecurity-report/history',
			);
			if (status >= 400) {
				return console.error('ERROR: Could not fetch report history');
			} else {
				dispatch(setReportHistory(data));
			}
		} catch (e) {
			console.log(e);
		}
	},
);

export const setReport = createAction<Report | undefined>(
	ActionType.SET_REPORT_VALUES,
);
export const setIsReportExists = createAction<boolean>(
	ActionType.SET_IS_REPORT_EXISTS,
);

export const setReportHistory = createAction<Report[] | undefined>(
	ActionType.SET_REPORT_HISTORY,
);

export const setReportMap = createAction<Map<string, Report[]> | undefined>(
	ActionType.SET_REPORT_MAP,
);

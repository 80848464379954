export interface Report {
	id: string;
	endCustomerId: string | null;
	numberOfGlobalAdmins: number | null;
	totalUsers: number | null;
	licensedUsers: number | null;
	mfaStatus: number | null;
	inactiveUsers: number | null;
	usersWithEmailForwarding: number | null;
	dangerousDelegatedAccess: number | null;
	numberOfPubliclySharedFilesInLast90Days: number | null;
	riskySignInsPercentage: number | null;
	usersWithoutPasswordExpiration: number | null;
	managedDeviceEncryptionPercentage: number | null;
	createdTime: string;
	weightedScore?: number | null;
}

export interface ReportForEndCustomer {
	id: string;
	endCustomerId: string | null;
	numberOfGlobalAdmins: string | null;
	totalUsers: string | null;
	licensedUsers: string | null;
	mfaStatus: string | null;
	inactiveUsers: string | null;
	usersWithEmailForwarding: string | null;
	dangerousDelegatedAccess: string | null;
	numberOfPubliclySharedFilesInLast90Days: string | null;
	riskySignInsPercentage: string | null;
	usersWithoutPasswordExpiration: string | null;
	managedDeviceEncryptionPercentage: string | null;
	createdTime: string;
	weightedScore?: string | null;
}

export enum ActionType {
	GENERATE_NEW_REPORT = 'CYBERSECURITY-REPORT/GENERATE_NEW_REPORT',
	FETCH_IS_REPORT_EXISTS = 'CYBERSECURITY-REPORT/FETCH_IS_REPORT_EXISTS',
	FETCH_LATEST_REPORT = 'CYBERSECURITY-REPORT/FETCH_LATEST_REPORT',
	FETCH_REPORT_HISTORY = 'CYBERSECURITY-REPORT/FETCH_REPORT_HISTORY',
	FETCH_SELECTED_REPORT = 'CYBERSECURITY-REPORT/FETCH_SELECTED_REPORT',
	SET_REPORT_VALUES = 'CYBERSECURITY-REPORT/SET_REPORT_VALUES',
	SET_IS_REPORT_EXISTS = 'CYBERSECURITY-REPORT/SET_IS_REPORT_EXISTS',
	SET_REPORT_HISTORY = 'CYBERSECURITY-REPORT/SET-REPORT-HISTORY',
	FETCH_MSP_CUSTOMER_REPORT_MAP = 'CYBERSECURITY-REPORT/FETCH_MSP_CUSTOMER_REPORT_MAP',
	SET_REPORT_MAP = 'CYBERSECURITY-REPORT/SET_REPORT_MAP',
}

import {
	AuthenticationResult,
	PublicClientApplication,
} from '@azure/msal-browser';
import { APP_BASE_URL } from 'common/constants';

export class AzureClient {
	private static readonly client = new PublicClientApplication({
		auth: {
			clientId: '140f616c-6d3e-422d-a0a0-21d408f1c2ba',
			authority: 'https://login.microsoftonline.com/common',
			redirectUri: window.location.origin,
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: false,
		},
	});
	static acquireTokenPopup(): Promise<AuthenticationResult> {
		return this.client.acquireTokenPopup({
			scopes: [
				'https://graph.microsoft.com/Device.Read.All',
				'https://graph.microsoft.com/DeviceManagementManagedDevices.Read.All',
				'https://graph.microsoft.com/Directory.Read.All',
				'https://graph.microsoft.com/Domain.Read.All',
				'https://graph.microsoft.com/IdentityRiskyUser.Read.All',
				'https://graph.microsoft.com/Organization.Read.All',
				'https://graph.microsoft.com/Policy.Read.All',
				'https://graph.microsoft.com/Reports.Read.All',
				'https://graph.microsoft.com/SecurityEvents.Read.All',
				'https://graph.microsoft.com/User.Read.All',
				'https://graph.microsoft.com/UserAuthenticationMethod.Read.All',
			],
			prompt: 'select_account',
		});
	}
}

export class AzureDemoClient {
	private static readonly client = new PublicClientApplication({
		auth: {
			clientId: '140f616c-6d3e-422d-a0a0-21d408f1c2ba',
			authority: 'https://login.microsoftonline.com/common',
			redirectUri: `${APP_BASE_URL}/demo`,
		},
		cache: {
			cacheLocation: 'seesionStorage',
			storeAuthStateInCookie: false,
		},
	});
	static acquireTokenPopup(): Promise<AuthenticationResult> {
		return this.client.acquireTokenPopup({
			scopes: [
				'https://graph.microsoft.com/Device.Read.All',
				'https://graph.microsoft.com/DeviceManagementManagedDevices.Read.All',
				'https://graph.microsoft.com/Directory.Read.All',
				'https://graph.microsoft.com/Domain.Read.All',
				'https://graph.microsoft.com/IdentityRiskyUser.Read.All',
				'https://graph.microsoft.com/Organization.Read.All',
				'https://graph.microsoft.com/Policy.Read.All',
				'https://graph.microsoft.com/Reports.Read.All',
				'https://graph.microsoft.com/SecurityEvents.Read.All',
				'https://graph.microsoft.com/User.Read.All',
				'https://graph.microsoft.com/UserAuthenticationMethod.Read.All',
			],
			prompt: 'select_account',
		});
	}
}

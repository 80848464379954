import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { selectIsAuthenticated } from '../store/stores/auth/selectors';

const AuthorizedRoutes = lazy(() => import('./AuthorizedRoutes'));
const UnauthorizedRoutes = lazy(() => import('./UnauthorizedRoutes'));

export { routes } from './routes';

const AppRouter = () => {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	return (
		<Router>
			<Suspense fallback={<div>Loading...</div>}>
				{isAuthenticated ? (
					<AuthorizedRoutes />
				) : (
					<UnauthorizedRoutes />
				)}
			</Suspense>
		</Router>
	);
};

export default AppRouter;

import { SVGProps } from 'react';

const SvgPenIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={17}
		height={17}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M0 12.095v2.657h2.656l7.834-7.834L7.834 4.26 0 12.095Zm12.545-7.232a.705.705 0 0 0 0-.998l-1.658-1.658a.705.705 0 0 0-.999 0L8.592 3.503l2.656 2.657 1.297-1.297Z"
			fill="#4282B3"
			fillOpacity={0.5}
		/>
	</svg>
);

export default SvgPenIcon;

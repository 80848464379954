import { Card, CardContent, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getSecurityIcon } from 'lib/utils/getSecurityIcon';
import React from 'react';
import { SecurityStatus } from 'types';

interface SecurityDashboardMainCardProps {
	devicesWithEncryptionEnabled: number;
	NISTCSFCompliance: number;
	MFAEnabled: number;
	vulnerableAssets: number;
	patchLevel: number;
}

const SecurityDashboardMainCard: React.FunctionComponent<
	SecurityDashboardMainCardProps
> = props => {
	return (
		<Card>
			<CardContent>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }}>
					<Typography variant="h5" sx={{ mr: '16px' }}>
						Security Overview
					</Typography>
					<Box sx={{ w: '20px', h: '22px' }}>
						{getSecurityIcon(SecurityStatus.MEDIUM)}
					</Box>
				</Box>
				<Typography variant="body1" sx={{ mb: '10px' }}>
					Devices with Encryption Enabled
				</Typography>
				<LinearProgress
					variant="determinate"
					sx={progressBarStyle}
					value={props.devicesWithEncryptionEnabled}
				/>
				<Typography variant="body1" sx={{ mb: '10px' }}>
					Multifactor Authentication Enabled
				</Typography>
				<LinearProgress
					variant="determinate"
					sx={progressBarStyle}
					value={props.MFAEnabled}
				/>
				<Typography variant="body1" sx={{ mb: '10px' }}>
					Compliance Level
				</Typography>
				<LinearProgress
					variant="determinate"
					sx={progressBarStyle}
					value={79}
				/>
				<Typography variant="body1" sx={{ mb: '10px' }}>
					Patch Level
				</Typography>
				<LinearProgress
					variant="determinate"
					sx={progressBarStyle}
					value={props.patchLevel}
				/>
			</CardContent>
		</Card>
	);
};

export default SecurityDashboardMainCard;

const progressBarStyle = {
	mb: '10px',
};

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { JiraOrganization, SaasAsset } from 'common/types';
import httpClient from 'services/http-client/http-client';

import { ActionType } from './types';

/* JIRA-ACTIONS */

export const fetchJiraOrganizations = createAsyncThunk<void>(
	ActionType.FETCH_JIRA_ORGS,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<JiraOrganization[]>(
				'/jira/organizations',
			);

			dispatch(setJiraOrganizations(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const synchronizeJira = createAsyncThunk<void>(
	ActionType.SYNC_JIRA,
	async () => {
		try {
			await httpClient.get('/jira/synchronize');
		} catch (e) {
			console.log(e);
		}
	},
);

export const setJiraOrganizations = createAction<JiraOrganization[]>(
	ActionType.SET_JIRA_ORGS,
);

import DevicesIcon from '@mui/icons-material/Devices';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import TerminalIcon from '@mui/icons-material/Terminal';
import {
	Avatar,
	Dialog,
	InputAdornment,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	TextField,
} from '@mui/material';
import { ListItemButton } from '@mui/material';
import { buildRoute } from 'common/utils';
import { useAppDispatch } from 'hooks/redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'router/routes';
import { actions, selectors } from 'store';

interface SearchBarProps {
	onClose: (e: any) => void;
	isOpen: any;
}

export const SearchBar = ({ isOpen, onClose }: SearchBarProps) => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const results = useSelector(selectors.search.getResults);
	const searchText = useSelector(selectors.search.getSearchText);

	const handleChange = async (e: any) => {
		await dispatch(actions.search.setSearchTerm(e.target.value));
	};

	const handleTmClick = (id: any) => {
		history.push(buildRoute(routes.authorized.teamMember, { id: id }));
	};

	const handleTeamClick = (id: any) => {
		history.push(buildRoute(routes.authorized.team, { id: id }));
	};

	const handleHwClick = (id: any) => {
		history.push(buildRoute(routes.authorized.hardwareAsset, { id: id }));
	};

	const handleSwClick = (id: any) => {
		history.push(buildRoute(routes.authorized.softwareAsset, { id: id }));
	};

	return (
		<Dialog
			PaperProps={{
				style: {
					position: 'absolute',
					top: '12%',
					maxHeight: '80%',
				},
			}}
			fullWidth={true}
			maxWidth="md"
			open={isOpen}
			onClose={onClose}>
			<TextField
				autoFocus={true}
				fullWidth={true}
				placeholder="Start searching for something..."
				onChange={handleChange}
				defaultValue={searchText}
				InputProps={{
					disableUnderline: true,
					style: {
						padding: '20px',
					},
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
				variant="standard"
			/>
			{results && (
				<List dense={true}>
					{results.teamMembers.length > 0 && (
						<ListItem>
							<ListItemText secondary="Team Members" />
						</ListItem>
					)}
					{results?.teamMembers?.map(r => {
						return (
							<ListItem key={r.id}>
								<ListItemButton
									onClick={() => handleTmClick(r.id)}>
									<ListItemAvatar>
										{!r?.avatar ? (
											<Avatar>
												{r
													? `${
															(r?.firstName &&
																r
																	?.firstName[0]) ||
															''
													  }${
															(r?.lastName &&
																r
																	?.lastName[0]) ||
															''
													  }`
													: ''}
											</Avatar>
										) : (
											<Avatar
												src={r?.avatar}
												sx={{ width: 40, height: 40 }}
												style={{ marginRight: '24px' }}
											/>
										)}
									</ListItemAvatar>
									<ListItemText
										primary={r.name}
										secondary={r.description}
									/>
								</ListItemButton>
							</ListItem>
						);
					})}
					{results.hwAssets.length > 0 && (
						<ListItem>
							<ListItemText secondary="Hardware Assets" />
						</ListItem>
					)}
					{results.hwAssets.map(r => {
						return (
							<ListItem key={r.id}>
								<ListItemButton
									onClick={() => handleHwClick(r.id)}>
									<ListItemAvatar>
										<Avatar>
											<DevicesIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={r.name}
										secondary={r.description}
									/>
								</ListItemButton>
							</ListItem>
						);
					})}
					{results.swAssets.length > 0 && (
						<ListItem>
							<ListItemText secondary="Software Assets" />
						</ListItem>
					)}
					{results.swAssets.map(r => {
						return (
							<ListItem key={r.id}>
								<ListItemButton
									onClick={() => handleSwClick(r.id)}>
									<ListItemAvatar>
										<Avatar>
											<TerminalIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={r.name}
										secondary={r.description}
									/>
								</ListItemButton>
							</ListItem>
						);
					})}
					{results.teams.length > 0 && (
						<ListItem>
							<ListItemText secondary="Teams" />
						</ListItem>
					)}
					{results.teams.map(r => {
						return (
							<ListItem key={r.id}>
								<ListItemButton
									onClick={() => handleTeamClick(r.id)}>
									<ListItemAvatar>
										<Avatar>
											<PeopleIcon />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={r.name}
										secondary={r.description}
									/>
								</ListItemButton>
							</ListItem>
						);
					})}
					{results.hwAssets.length < 1 &&
						results.swAssets.length < 1 &&
						results.teamMembers.length < 1 &&
						results.teams.length < 1 && (
							<ListItem>
								<ListItemText primary="No results found" />
							</ListItem>
						)}
				</List>
			)}
		</Dialog>
	);
};

import { NinjaOrganization } from 'common/types';
import { RootState } from 'store';

export const selectNinjaOrganizations = (
	state: RootState,
): NinjaOrganization[] => state.ninja.ninjaOrganizations;

export const selectNinjaOrganizationsForAssignment = (
	state: RootState,
): NinjaOrganization[] => state.ninja.ninjaOrganizationsForAssignment;

export const selectNinjaLoadingState = (state: RootState) =>
	state.ninja.loading;
export const selectNinjaSelectedOrganization = (state: RootState) =>
	state.ninja.slectedOrganization;

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncError } from 'common/utils';

import { CreateTeamDto, Team, UpdateTeamDto } from '../../../common/types';
import httpClient from '../../../services/http-client/http-client';
import { ActionType } from './types';


export const createTeam = createAsyncThunk<Team | undefined, CreateTeamDto>(
	ActionType.CREATE_TEAM,
	async (dto, {dispatch}) => {
		try {
			const { data } = await httpClient.post<Team>('/teams', dto);
			dispatch(addTeam(data));

			return data;
		} catch (e :any) {
			handleAsyncError(e);
		}
	},
);


export const fetchUpdateTeam = createAsyncThunk<void, UpdateTeamDto>(ActionType.FETCH_UPDATE_TEAM, async (payload, {dispatch}) => {
	try{
		const {data} = await httpClient.patch<Team>(`/teams/${payload.id}`, payload);

		dispatch(updateTeam(data));
	}catch(e: any){
		handleAsyncError(e);
	}
});

export const fetchRemoveTeam = createAsyncThunk<void, string>(ActionType.FETCH_REMOVE_TEAM, async (id, {dispatch}) => {
	try {
		const {data} = await httpClient.delete(`/teams/${id}`);

		dispatch(removeTeam(id));
	} catch (e: any) {
		handleAsyncError(e);
	}
});

export const fetchTeams = createAsyncThunk<void, void>(ActionType.FETCH_TEAMS, async (_, {dispatch}) => {
	try {
		const {data} = await httpClient.get<Team[]>('/teams');	

		dispatch(setTeams(data));
	} catch (e: any) {
		handleAsyncError(e);
	}
});

export const fetchTeam = createAsyncThunk<void, string>(ActionType.FETCH_TEAM, async (id, {dispatch}) => {
	try {
		const {data} = await httpClient.get<Team>(`/team/${id}`);	

		dispatch(addTeam(data));
	} catch (e: any) {
		handleAsyncError(e);
	}
});

export const setTeams = createAction<Team[]>(ActionType.SET_TEAMS);

export const addTeam = createAction<Team>(ActionType.ADD_TEAM);

export const updateTeam = createAction<Team>(ActionType.UPDATE_TEAM);

export const removeTeam = createAction<string>(ActionType.REMOVE_TEAM);

import { COLORS } from 'assets/styles/colors';
import { COMPONENTS } from 'assets/styles/components';
import { useRef, useState } from 'react';
import styled from 'styled-components';

interface TextAreaProps {
	id?: string;
	name?: string;
	label?: string;
	className?: string;
	placeholder?: string;
	defaultValue?: any;
	value?: any;
	onChange?: (value: any) => void;
	rows?: number;
}

export const TextArea = ({
	id,
	name,
	label,
	className,
	placeholder,
	defaultValue,
	value,
	onChange,
	rows = 5,
}: TextAreaProps) => {
	const [isTextAreaFocused, setIsTextAreaFocused] = useState(false);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	return (
		<Root
			$onFocus={isTextAreaFocused}
			className={className}
			onClick={() => textareaRef.current?.focus()}>
			<Label $onFocus={isTextAreaFocused} $hasValue={value}>
				{label}
			</Label>
			<StyledTextArea
				id={id}
				name={name}
				ref={textareaRef}
				placeholder={placeholder}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange}
				onFocus={() => setIsTextAreaFocused(true)}
				onBlur={() => setIsTextAreaFocused(false)}
				rows={rows}
			/>
		</Root>
	);
};

const Root = styled.div<{ $onFocus: boolean }>`
	max-width: 100%;
	width: 100%;
	height: 156px;

	padding: 16px 12px;

	border: 1px solid #0000003b;
	border-radius: 4px;

	position: relative;

	cursor: text;

	${({ $onFocus }) => $onFocus && `border: 2px solid ${COLORS.primaryMain}`}
`;

const Label = styled.label<{ $onFocus: boolean; $hasValue: boolean }>`
	position: absolute;
	left: 12px;
	top: 16px;

	background-color: #fff;
	${COMPONENTS.inputText}
	color: ${COLORS.textSecondary};
	padding: 0px 3px;

	transition: transform 0.1s ease-in-out;
	transform: translate(0px, 0px) scale(1);
	${({ $onFocus, $hasValue }) =>
		($onFocus || $hasValue) &&
		'transform: translate(-12px, -28px) scale(0.75);'}
	${({ $onFocus }) => $onFocus && `color: ${COLORS.primaryMain};`}
`;

const StyledTextArea = styled.textarea`
	${COMPONENTS.inputText}
	color: ${COLORS.textPrimary};
	width: 100%;

	outline: none;
	border-width: 0;
	resize: none;
`;

import 'assets/styles/global.css';
import 'assets/styles/fonts.css';
import 'react-toastify/dist/ReactToastify.css';

import * as ReactDOM from 'react-dom';

import App from './App';

const rootEl = document.getElementById('root');
ReactDOM.render(<App />, rootEl);

import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { Customer, Invite } from './types';

export interface CustomersState {
	customers: Customer[];
	actionMessage: string | null;
	inviteData?: any;
	unactivatedInvites: Invite[];
}

const initialState: CustomersState = {
	customers: [],
	actionMessage: null,
	unactivatedInvites: [],
};

export const reducer = createReducer(
	initialState,
	builder =>
		builder
			.addCase(actions.setCustomers, (state, { payload }) => {
				state.customers = payload;
			})
			.addCase(actions.setUnactivatedInvites, (state, { payload }) => {
				state.unactivatedInvites = payload;
			})
			.addCase(actions.setInviteData, (state, { payload }) => {
				state.inviteData = payload;
			}),
	// .addCase(actions.setActionMessage, (state, { payload }) => {
	// 	state.actionMessage = payload;
	// }),
);

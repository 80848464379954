import { CheckboxProps, Checkbox as MUICheckbox } from '@mui/material';
import { CLASS_NAMES } from 'assets/styles/classNames';
import { COLORS } from 'assets/styles/colors';
import { FC } from 'react';
import styled from 'styled-components';

export const Checkbox: FC<CheckboxProps> = props => {
	return <Root {...props} />;
};

const Root = styled(MUICheckbox)`
	&& {
		&${CLASS_NAMES.CheckBoxPrimary}${CLASS_NAMES.Checked} {
			color: ${COLORS.primaryMain};
		}
	}
`;

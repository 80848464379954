import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { MSP } from './types';

interface MSPState {
	currentMSP?: MSP;
}

const initialState: MSPState = {};

export const reducer = createReducer(initialState, builder =>
	builder.addCase(actions.setMSP, (state, { payload }) => {
		state.currentMSP = payload;
	}),
);

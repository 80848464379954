export const TYPOGRAPHY = {
	h1: `
    font-family: 'Lato';
    font-weight: 900;
    font-size: 62px;
    line-height: 78px;
  `,
	h2: `
    font-family: 'Lato';
    font-weight: 900;
    font-size: 60px;
    line-height: 68px;
  `,
	h3: `
    font-family: 'Lato';
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
  `,
	h4: `
    font-family: 'Lato';
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.25px;
  `,
	h5: `
    font-family: 'Lato';
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
  `,
	h6: `
    font-family: 'Lato';
    font-weight: 900;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.15px;
  `,
	caption: `
    font-family: 'Lato';
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.4px;
  `,
	subtitle1: `
    font-family: 'Lato';
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.15px;
  `,
	subtitle2: `
    font-family: 'Lato';
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
  `,
	body1: `
    font-family: 'Lato';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.15px;
  `,
	body2: `
    font-family: 'Lato';
    font-weight: 900;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
  `,
	body5: `
    font-family: 'Lato';
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15px;
  `,
	light1: `
    font-family: 'Lato';
    font-weight: 300;
    font-size: 22px;
    line-height: 22px;
  `,
	light2: `
    font-family: 'Lato';
    font-weight: 300;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.15px;
  `,
};
import { TextField, TextFieldProps } from '@mui/material';
import { COMPONENTS } from 'assets/styles/components';
import React from 'react';
import styled from 'styled-components';

type InputProps = Omit<TextFieldProps, 'error'> & {
	error?: string;
};

export const Input: React.FC<InputProps> = ({
	id,
	name,
	label,
	className,
	placeholder,
	onChange,
	defaultValue,
	value,
	error,
	disabled,
	type,
	inputProps,
}) => {
	return (
		<Root className={className}>
			<StyledTextField
				error={Boolean(error)}
				helperText={error}
				variant="outlined"
				id={id}
				name={name}
				label={label}
				placeholder={placeholder}
				value={value}
				defaultValue={defaultValue}
				onChange={onChange}
				disabled={disabled}
				type={type}
				inputProps={inputProps}
			/>
		</Root>
	);
};

const Root = styled.div`
	max-width: 220px;
	width: 100%;
`;

const StyledTextField = styled(TextField)`
	width: 100%;

	&& {
		.MuiInputBase-input {
			${COMPONENTS.inputText}
			box-sizing: border-box;
			width: 100%;
			height: 40px;
		}

		.MuiFormLabel-root {
			${COMPONENTS.inputText}
		}

		.MuiInputLabel-outlined {
			transform: translate(14px, 8px) scale(1);

			&.MuiInputLabel-shrink {
				transform: translate(14px, -8px) scale(0.75);
			}
		}

		.MuiOutlinedInput-input {
			padding: 7px 11px;
		}
	}
`;

import {
	AccordionDetails,
	AccordionSummary,
	Accordion as UIAccordion,
} from '@mui/material';
import ExpandMoreIcon from 'assets/icons/expand-more.svg';
import React, { useState } from 'react';
import styled from 'styled-components';

interface AccordionProps {
	className?: string;
	summary: React.ReactNode;
	details: React.ReactNode;
	isExpanded: boolean;
}

export const Accordion = ({
	className,
	summary,
	details,
	isExpanded = false,
}: AccordionProps) => {
	const [expanded, setExpanded] = useState(isExpanded);
	return (
		<StyledAccordion
			expanded={expanded}
			className={className}
			variant="outlined"
			onClick={() => setExpanded(!expanded)}>
			<AccordionSummary expandIcon={<img src={ExpandMoreIcon} />}>
				{summary}
			</AccordionSummary>
			<AccordionDetails>{details}</AccordionDetails>
		</StyledAccordion>
	);
};

const StyledAccordion = styled(UIAccordion)`
	&& {
		&.MuiAccordion-root {
			&::before {
				display: none;
			}
			&.Mui-expanded {
				margin: 0 0 32px;
			}
		}
		&.MuiPaper-outlined {
			border: 0;
			box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.03);
		}
		&.MuiAccordion-rounded {
			border-radius: 0;

			.MuiAccordionSummary-root {
				padding: 20px 16px;

				.MuiAccordionSummary-content {
					margin: 0;
				}
			}
		}
	}
`;

import { createReducer } from '@reduxjs/toolkit';


interface State {
	num: number;
	startedNIST: boolean;
}

const initialState: State = {
	num: 12,
	startedNIST: false,
};

export const reducer = createReducer(initialState, () => {
	// builder
	// 	.addCase(actions.getStarterCompliance, (state) => {
	// 		state.startedNIST = true;
	// 	});
});

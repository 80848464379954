import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { NinjaOrganization } from 'common/types';
import { buildRoute } from 'common/utils';
import { routes } from 'router';
import httpClient from 'services/http-client/http-client';
import { toaster } from 'services/toaster';

import { ActionType, NinjaAuthenticateDTO } from './types';

/* SAAS-ASSETS */

export const fetchNinjaOrganizations = createAsyncThunk<void>(
	ActionType.FETCH_NINJA_ORGS,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<NinjaOrganization[]>(
				'/ninja/organizations',
			);

			dispatch(setNinjaOrganizations(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const authorizeNinja = createAsyncThunk<
	void,
	{ code: string; history: any }
>(ActionType.AUTHORIZE_NINJA, async ({ code, history }) => {
	try {
		await httpClient.post('/ninja/authorize', {
			code: code,
		});

		toaster({
			message: 'NinjaOne synchronization started.',
			variant: 'success',
		});
		await httpClient.get('/ninja/synchronize').then(
			() => {
				toaster({
					message: 'NinjaOne synchronization finished.',
					variant: 'success',
				});
			},
			rejected => {
				if (rejected.response.status == 401) {
					toaster({
						message: 'NinjaOne is not authorized',
						variant: 'error',

						buttonProps: {
							buttonText: 'FIX-IT',
							onClick: () => {
								history.push(
									buildRoute(
										routes.authorized.onboardingAssets,
									),
								);
							},
						},
					});
				}
			},
		);
	} catch (e) {
		console.log(e);
	}
});

export const fetchOrganizationsForAssignment = createAsyncThunk<void>(
	ActionType.FETCH_ORGS_FOR_ASSIGNMENT,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<NinjaOrganization[]>(
				'/ninja/organizationsForAssignment',
			);

			dispatch(setNinjaOrganizationsForAssignemt(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const synchronizeNinja = createAsyncThunk<void>(
	ActionType.SYNC_NINJA,
	async () => {
		try {
			await httpClient.get('/ninja/synchronize');
		} catch (e) {
			console.log(e);
		}
	},
);

export const authenticateNinja = createAsyncThunk<
	boolean,
	NinjaAuthenticateDTO
>(ActionType.SYNC_NINJA, async args => {
	try {
		const { data } = await httpClient.post('/ninja/authenticate', args);

		return data;
	} catch (e) {
		console.log(e);
	}
});

export const checkNinjaAuth = createAsyncThunk<boolean>(
	ActionType.CHECK_AUTH_NINJA,
	async () => {
		try {
			const { data } = await httpClient.get('/ninja/check-ninja-auth');
			return data;
		} catch (e) {
			console.log(e);
		}
	},
);

export const setNinjaOrganizations = createAction<NinjaOrganization[]>(
	ActionType.SET_NINJA_ORGS,
);
export const setNinjaOrganizationsForAssignemt = createAction<
	NinjaOrganization[]
>(ActionType.SET_NINJA_ORGS_FOR_ASSIGN);
export const startSyncNinjaLoader = createAction(
	ActionType.START_SYNC_NINJA_LOADER,
);

export const finishSyncNinjaLoader = createAction(
	ActionType.FINISH_SYNC_NINJA_LOADER,
);

export const selectNinjaOrganization = createAction<string>(
	ActionType.SELECT_NINJA_ORG,
);

export const unselectNinjaOrganization = createAction(
	ActionType.UNSELECT_NINJA_ORG,
);

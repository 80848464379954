import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useOutsideClick } from 'hooks';
import { useRef, useState } from 'react';
import styled from 'styled-components';

interface DropDownProps {
  className?: string;
  clickableContent: React.ReactNode;
	dropdownTitle?: string;
  dropdownContent: React.ReactNode;
}

export const Dropdown = ({ 
	className,
	clickableContent, 
	dropdownTitle = '',
	dropdownContent }: DropDownProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);
	useOutsideClick(ref, () => setIsDropdownVisible(false));

	return (
		<Root className={className}
			onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
			{clickableContent}
			{isDropdownVisible && (
				<DropdownContainer 
					ref={ref}
					className='dropdown__container'>
					{dropdownTitle && (
						<DropdownTitle>
							{dropdownTitle}
						</DropdownTitle>
					)}
					<DropdownInner>
						{dropdownContent}
					</DropdownInner>
				</DropdownContainer>
			)}
		</Root>
	);
};

const Root = styled.div`
  position: relative;

  max-width: 40px;
  width: 100%;
	height: 40px;

	cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  left: -50%;
  transform: translate(-50%, 24px);
	z-index: 100;

  border-radius: 8px;

  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
	background-color: #fff;
`;

const DropdownTitle = styled.div`
	${TYPOGRAPHY.h6}
	color: ${COLORS.textPrimary};
	padding: 16px 24px;
	text-align: left;
`;

const DropdownInner = styled.div`
	width: 100%;
	padding: 8px 0;
`;


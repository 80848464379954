import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import { CLASS_NAMES } from 'assets/styles/classNames';
import { COLORS } from 'assets/styles/colors';
import { COMPONENTS } from 'assets/styles/components';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useOutsideClick } from 'hooks';
import { Fragment, useRef, useState } from 'react';
import styled from 'styled-components';

interface SplitButtonOptionType {
	title: string;
	onClick: () => void;
}

interface SplitButtonProps {
	title: string;
	options: SplitButtonOptionType[];
	className?: string;
}

interface StypedPopperProps {
	TransitionProps: any;
	placement: any;
}

export const SplitButton = ({
	title,
	options,
	className,
}: SplitButtonProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(1);

	useOutsideClick(anchorRef, () => setIsOpen(false));

	const handleClick = () => {
		setIsOpen(!isOpen);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		index: number,
	) => {
		options[index].onClick();
		setIsOpen(false);
	};

	const handleToggle = () => {
		setIsOpen(prev => !prev);
	};

	return (
		<Fragment>
			<StyledButtonGroup
				variant="contained"
				ref={anchorRef}
				className={className}>
				<LeftButton onClick={handleClick}>{title}</LeftButton>
				<RightButton size="small" onClick={handleToggle}>
					<ArrowDropDownIcon />
				</RightButton>
			</StyledButtonGroup>

			<Popper
				sx={{ zIndex: '999' }}
				open={isOpen}
				anchorEl={anchorRef.current}
				transition
				disablePortal>
				{({ TransitionProps, placement }: StypedPopperProps) => (
					<StyledGrow {...TransitionProps} $placement={placement}>
						<Paper>
							<ClickAwayListener
								onClickAway={() => setIsOpen(false)}>
								<MenuList id="split-button-menu">
									{options.map((option, index) => (
										<StyledMenuItem
											key={option.title}
											disabled={index === 2}
											selected={index === selectedIndex}
											onClick={(event: any) =>
												handleMenuItemClick(
													event,
													index,
												)
											}>
											{option.title}
										</StyledMenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</StyledGrow>
				)}
			</Popper>
		</Fragment>
	);
};

const ArrowDropDownIcon = styled.div`
	border: 4px solid transparent;
	border-top: 4px solid ${COLORS.secondaryMain};
	margin-top: 2px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
	&& {
		&${CLASS_NAMES.ButtonGroupContained} {
			box-shadow: none;
			width: 70px;
			height: 36px;
		}
	}
`;

const LeftButton = styled(Button)`
	&& {
		&${CLASS_NAMES.ButtonRoot} {
			${COMPONENTS.buttonLarge};
			color: ${COLORS.secondaryMain};
		}
		&${CLASS_NAMES.ButtonContained} {
			background-color: #fff;
			border: 1px solid ${COLORS.secondaryMain};
		}
		&${CLASS_NAMES.ButtonGroupGrouped}:not(:last-of-type) {
			border: 1px solid ${COLORS.secondaryMain};
		}
	}
`;

const RightButton = styled(Button)`
	&& {
		&${CLASS_NAMES.ButtonContained} {
			padding: 0;
			min-width: 19px;
			background-color: #fff;
			border: 1px solid ${COLORS.secondaryMain};
			border-left-color: transparent;
		}
	}
`;

const StyledMenuItem = styled(MenuItem)`
	&& {
		${TYPOGRAPHY.body1}
	}
`;

const StyledGrow = styled(Grow)<{ $placement: any }>`
	transform-origin: ${({ $placement }) =>
		$placement === 'bottom' ? 'center top' : 'center bottom'};
`;

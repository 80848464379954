import { SVGProps } from 'react';

const SvgExpandMore = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={40}
		height={40}
		fill="none"
		{...props}
	>
		<path
			d="M26.12 15.453 20 21.56l-6.12-6.107-1.88 1.88 8 8 8-8-1.88-1.88Z"
			fill="#4282B3"
		/>
	</svg>
);

export default SvgExpandMore;

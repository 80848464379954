import { createReducer } from '@reduxjs/toolkit';

import { OnboardingProgress } from './types';
import { actions } from '.';

interface State {
	onboardingProgress: OnboardingProgress;
}

const initialState: State = {
	onboardingProgress: OnboardingProgress.Loading,
};

export const reducer = createReducer(initialState, builder =>
	builder.addCase(actions.setOnboardingStatus, (state, { payload }) => {
		state.onboardingProgress = payload;
	}),
);

export enum ActionType {
	/* SAAS-ASSETS */
	FETCH_CREATE_SAAS_ASSETS = 'ASSETS/FETCH_CREATE_SAAS_ASSETS',
	ADD_SAAS_ASSET = 'ASSETS/ADD_SAAS_ASSET',
	SET_SAAS_ASSETS = 'ASSETS/SET_SAAS_ASSETS',
	FETCH_SAAS_ASSETS = 'ASSETS/FETCH_SAAS_ASSETS',
	FETCH_UPDATE_SAAS_ASSET = 'ASSETS/FETCH_UPDATE_SAAS_ASSET',
	UPDATE_SAAS_ASSETS = 'ASSETS/UPDATE_SAAS_ASSETS',
	FETCH_REMOVE_SAAS_ASSET = 'ASSETS/FETCH_REMOVE_SAAS_ASSET',
	REMOVE_SAAS_ASSETS = 'ASSETS/REMOVE_SAAS_ASSETS',

	/* HARDWARE-ASSETS */
	FETCH_CREATE_HARDWARE_ASSETS = 'ASSETS/FETCH_CREATE_HARDWARE_ASSETS',
	GET_NINJA_DASHBOARD_URL = 'ASSETS/GET_NINJA_DASHBOARD_URL',
	SET_NINJA_DASHBOARD_URL = 'ASSETS/SET_NINJA_DASHBOARD_URL',
	ADD_HARDWARE_ASSET = 'ASSETS/ADD_HARDWARE_ASSET',
	SET_HARDWARE_ASSETS = 'ASSETS/SET_HARDWARE_ASSETS',
	FETCH_HARDWARE_ASSETS = 'ASSETS/FETCH_HARDWARE_ASSETS',
	FETCH_UPDATE_HARDWARE_ASSET = 'ASSETS/FETCH_HARDWARE_SAAS_ASSET',
	UPDATE_HARDWARE_ASSETS = 'ASSETS/UPDATE_HARDWARE_ASSETS',
	FETCH_REMOVE_HARDWARE_ASSET = 'ASSETS/FETCH_REMOVE_HARDWARE_ASSET',
	REMOVE_HARDWARE_ASSETS = 'ASSETS/REMOVE_HARDWARE_ASSETS',

	/* SOFTWARE-ASSETS */
	FETCH_CREATE_SOFTWARE_ASSETS = 'ASSETS/FETCH_CREATE_SOFTWARE_ASSETS',
	ADD_SOFTWARE_ASSET = 'ASSETS/ADD_SOFTWARE_ASSET',
	SET_SOFTWARE_ASSETS = 'ASSETS/SET_SOFTWARE_ASSETS',
	FETCH_SOFTWARE_ASSETS = 'ASSETS/FETCH_SOFTWARE_ASSETS',
	FETCH_UPDATE_SOFTWARE_ASSET = 'ASSETS/FETCH_SOFTWARE_SAAS_ASSET',
	UPDATE_SOFTWARE_ASSETS = 'ASSETS/UPDATE_SOFTWARE_ASSETS',
	FETCH_REMOVE_SOFTWARE_ASSET = 'ASSETS/FETCH_REMOVE_SOFTWARE_ASSET',
	REMOVE_SOFTWARE_ASSETS = 'ASSETS/REMOVE_SOFTWARE_ASSETS',
}

export enum ActionType {
	CREATE_TEAM_MEMBER = 'TEAM_MEMBERS/CREATE_TEAM_MEMBER',
	ADD_TEAM_MEMBER = 'TEAM_MEMBERS/ADD_TEAM_MEMBER',
	FETCH_UPDATE_TEAM_MEMBER = 'TEAM_MEMBERS/FETCH_UPDATE_TEAM_MEMBER',
	UPDATE_TEAM_MEMBER = 'TEAM_MEMBERS/UPDATE_TEAM_MEMBER',
	FETCH_REMOVE_TEAM_MEMBER = 'TEAM_MEMBERS/FETCH_REMOVE_TEAM_MEMBER',
	REMOVE_TEAM_MEMBER = 'TEAM_MEMBERS/REMOVE_TEAM_MEMBER',
	FETCH_TEAM_MEMBERS = 'TEAM_MEMBERS/FETCH_TEAM_MEMBERS',
	SET_TEAM_MEMBERS = 'TEAM_MEMBERS/SET_TEAM_MEMBERS',
}

export enum ActionType {
	CREATE_TEAM = 'TEAMS/CREATE_TEAM',
	ADD_TEAM = 'TEAMS/ADD_TEAM',
	UPDATE_TEAM = 'TEAMS/UPDATE_TEAM',
	FETCH_UPDATE_TEAM = 'TEAMS/FETCH_UPDATE_TEAM',
	FETCH_REMOVE_TEAM = 'TEAMS/FETCH_REMOVE_TEAM',
	REMOVE_TEAM = 'TEAMS/REMOVE_TEAM',
	SET_TEAMS = 'TEAMS/SET_TEAMS',
	FETCH_TEAMS = 'TEAMS/FETCH_TEAMS',
	FETCH_TEAM = 'TEAMS/FETCH_TEAM',
}

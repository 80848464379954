import { FC } from 'react';
import styled from 'styled-components';
import { TabPanelProps } from 'types';

export const TabPanelWrap: FC<TabPanelProps> = ({
	valueOfTab,
	currentTabValue,
	children,
}) => (
	<Root role="tabpanel" hidden={valueOfTab !== currentTabValue}>
		{children}
	</Root>
);

const Root = styled.div`
	width: 100%;
	background-color: #fff;
`;

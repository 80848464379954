import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { jwtRegex } from 'common/regex';
import { AzureClient, AzureDemoClient } from 'services';
import httpClient from 'services/http-client/http-client';

import {
	ActionType,
	InviteActivationPayload,
	LoginWithRedirectPayload,
	MspAzureResponse,
	RedirectTargets,
} from './types';

interface LoginPayload {
	token: string;
}

export const login = createAction<LoginPayload>(ActionType.LOGIN);

export const logout = createAction(ActionType.LOGOUT);

export const authWithAzure = createAsyncThunk<boolean, void>(
	ActionType.AUTH_AZURE,
	async (_, { dispatch }) => {
		try {
			const { idToken, accessToken } =
				await AzureClient.acquireTokenPopup();

			const { data } = await httpClient.post('/auth/end-customer/azure', {
				idToken: idToken,
				accessToken,
			});

			if (jwtRegex.test(data)) {
				dispatch(login({ token: data }));
				return true;
			}
			return false;
		} catch (e) {
			//TODO: error handlers
			return false;
		}
	},
);

export const activateInviteWithAzure = createAsyncThunk<
	boolean,
	InviteActivationPayload
>(ActionType.ACTIVATE_INVITE_AZURE, async (payload, { dispatch }) => {
	try {
		const { idToken, accessToken } = await AzureClient.acquireTokenPopup();

		const { data: token } = await httpClient.post(
			'/auth/end-customer/azure/activate-invitation',
			{
				inviteId: payload.inviteId,
				inviteMapId: payload.inviteMapId,
				idToken: idToken,
				accessToken: accessToken,
			},
		);

		if (jwtRegex.test(token)) {
			dispatch(login({ token: token }));
			return true;
		}
		return false;
	} catch (e) {
		//TODO: error handlers
		return false;
	}
});

export const loginToAzureWithRedirect = createAsyncThunk<
	boolean,
	LoginWithRedirectPayload
>(ActionType.LOGIN_WITH_REDIRECT, async (payload, { dispatch }) => {
	try {
		const { idToken, accessToken } = await AzureClient.acquireTokenPopup();
		switch (payload.redirectTarget) {
			case RedirectTargets.CustomerReportView: {
				const { data } = await httpClient.post(
					'/auth/end-customer/azure',
					{
						idToken: idToken,
						accessToken: accessToken,
					},
				);
				if (jwtRegex.test(data)) {
					dispatch(login({ token: data }));
				}
				return true;
				break;
			}
			default: {
				return false;
			}
		}

		return false;
	} catch (e) {
		//TODO: error handlers
		return false;
	}
});

export const authMspAzureSignIn = createAsyncThunk<MspAzureResponse, void>(
	ActionType.AUTH_MSP_AZURE_SIGNIN,
	async (_, { dispatch }) => {
		try {
			const { idToken, accessToken } =
				await AzureClient.acquireTokenPopup();

			const { data: token, status } = await httpClient.post(
				'/auth/msp/azure/sign-in',
				{
					idToken: idToken,
					accessToken: accessToken,
				},
			);

			if (jwtRegex.test(token)) {
				dispatch(login({ token: token }));
				return MspAzureResponse.SUCCESS;
			}
			return MspAzureResponse.INVALID_TOKEN;
		} catch (e) {
			//TODO: error handlers

			return MspAzureResponse.UNKNOWN;
		}
	},
);

export const authMSPWithAzure = createAsyncThunk<boolean, void>(
	ActionType.AUTH_MSP_AZURE,
	async (_, { dispatch }) => {
		try {
			const { idToken, accessToken } =
				await AzureClient.acquireTokenPopup();

			const { data: token } = await httpClient.post('/auth/msp/azure', {
				idToken: idToken,
				accessToken: accessToken,
			});

			if (jwtRegex.test(token)) {
				dispatch(login({ token: token }));
				return true;
			}
			return false;
		} catch (e) {
			//TODO: error handlers
			return false;
		}
	},
);

export const authEndCustomerWithAzure = createAsyncThunk<boolean, void>(
	ActionType.AUTH_END_CUSTOMER,
	async (_, { dispatch }) => {
		try {
			const { idToken, accessToken } =
				await AzureClient.acquireTokenPopup();

			const { data: token } = await httpClient.post(
				'/auth/end-customer/azure',
				{
					idToken,
					accessToken,
				},
			);

			if (jwtRegex.test(token)) {
				dispatch(login({ token: token }));
				return true;
			}
			return false;
		} catch (e) {
			//TODO: error handlers
			return false;
		}
	},
);

export const authWithDemoAzure = createAsyncThunk<string | null, void>(
	ActionType.AUTH_DEMO_AZURE,
	async () => {
		try {
			const result = await AzureDemoClient.acquireTokenPopup();

			const { data: pdfReport } = await httpClient.post(
				'/demo/request-report',
				{
					idToken: result.idToken,
					accessToken: result.accessToken,
				},
			);

			return pdfReport;
		} catch (e) {
			//TODO: error handlers
			return null;
		}
	},
);

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from 'services/http-client/http-client';

import { ActionType, CreateFeedbackDto } from './types';

/* FEEDBACK-ACTIONS */

export const postFeedback = createAsyncThunk<boolean, CreateFeedbackDto>(
	ActionType.POST_FEEDBACK,
	async (dto, { dispatch }) => {
		try {
			const response = await httpClient.post('/feedbacks', dto);
			if (response.status == 201) {
				return true;
			}
		} catch (e) {
			console.log(e);
		}
		return false;
	},
);

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Avatar, Box, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { ItOsLogo } from 'assets/icons';
import { buildRoute } from 'common/utils';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useRef, useState } from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'router';
import { actions, selectors } from 'store';
import { actions as authActions } from 'store/stores/auth';

export const MspSidebar: FunctionComponent = () => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const [tabValue, setTabValue] = useState<string>('dashboard');

	const authToken = useAppSelector(selectors.auth.selectTokenData);
	const [logoId, setLogoId] = useState<string | null>(null);

	const handleLogoutClick = () => {
		dispatch(authActions.logout());
	};
	const handleClick = (value: string) => {
		history.push(buildRoute(value));
		getTabValue();
	};

	const isSubscribed = useRef(true);

	const getTabValue = () => {
		const path = window.location.pathname.toString();
		if (path.includes('customer')) {
			setTabValue('customer');
		} else if (path.includes('report')) {
			setTabValue('report');
		} else if (path.includes('settings')) {
			setTabValue('settings');
		} else {
			setTabValue('dashboard');
		}
	};

	React.useEffect(() => {
		getTabValue();
		const fetchLogo = async () => {
			if (isSubscribed.current) {
				const res = await dispatch(actions.msp.fetchCurrentMsp());
				const unwrappedResult = await unwrapResult(res);
				setLogoId(JSON.parse(unwrappedResult.logo).id);
			}
		};
		fetchLogo().catch(console.error);
		return () => {
			isSubscribed.current = false;
		};
	}, []);

	return (
		<Box sx={sxSidebar}>
			<Box sx={sxLogo}>
				<ItOsLogo
					onClick={() => {
						history.push(routes.authorized.msp.dashboard.root);
						getTabValue();
					}}
				/>
			</Box>
			<Tabs
				value={tabValue}
				textColor="secondary"
				indicatorColor="secondary"
				sx={sxTabs}
				orientation="vertical">
				<Tab
					sx={sxTab}
					value={'dashboard'}
					onClick={() =>
						handleClick(routes.authorized.msp.dashboard.root)
					}
					label="Dashboard"
					icon={<GridViewIcon />}
					iconPosition="start"
				/>
				<Tab
					value={'customer'}
					onClick={() =>
						handleClick(
							routes.authorized.msp.dashboard.customers.dashboard,
						)
					}
					label="Customers"
					icon={<PeopleOutlineIcon />}
					iconPosition="start"
				/>
				<Tab
					value={'report'}
					onClick={() =>
						handleClick(
							routes.authorized.msp.dashboard.reports.dashboard,
						)
					}
					label="Reports"
					icon={<DescriptionOutlinedIcon />}
					iconPosition="start"
				/>
				<Tab
					sx={{ mt: '2em' }}
					value={'settings'}
					onClick={() =>
						handleClick(
							routes.authorized.msp.dashboard.settings.root,
						)
					}
					label="Settings"
					icon={<SettingsOutlinedIcon />}
					iconPosition="start"
				/>
			</Tabs>
			<Box sx={sxAvatarBox}>
				<Avatar
					src={
						logoId
							? `https://itos-images.s3.us-west-2.amazonaws.com/${logoId}_64`
							: undefined
					}
				/>
				<Box sx={{ ml: '1em', mr: '1em' }}>
					<Typography>{authToken?.name}</Typography>
					<Typography variant="caption">
						{authToken?.organizationName}
					</Typography>
				</Box>
				<IconButton onClick={handleLogoutClick} aria-label="logout">
					<LogoutOutlinedIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

const sxSidebar = {
	paddingTop: '5vh',
	paddingBottom: '5vh',
	width: '18%',
	minWidth: '295px',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	alignContent: 'center',
	borderRight: '1px solid #e3dee1',
	backgroundColor: '#F9F9F9',
	'.MuiTab-root': {
		justifyContent: 'left',
		marginLeft: '3em',
	},
};

const sxLogo = {
	// width: '154px',
	// height: '74px',
	'&:hover': {
		cursor: 'pointer',
	},
};

const sxTabs = {
	marginTop: '60px',
	width: '100%',
};

const sxTab = {
	width: '100%',
};

const sxAvatarBox = {
	mt: 'auto',
	paddingTop: '36px',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-around',
	borderTop: '1px solid #D3D3D3',
	width: '100%',
	height: '12%',
	paddingLeft: '3em',
	paddingRight: '1em',
};

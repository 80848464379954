import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

interface CountCardProps {
	cardName: string;
	successNumber: number;
	failNumber: number;
}

const CountCard: React.FunctionComponent<CountCardProps> = props => {
	return (
		<Card sx={{ height: '100%' }}>
			<CardContent style={{ height: '100%' }}>
				<Typography variant="h5">{props.cardName}</Typography>
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					width="100%"
					height="100%">
					<Typography variant="h2" sx={{ mr: 5 }}>
						{props.successNumber}
					</Typography>
					<Typography variant="h2" color="secondary">
						|
					</Typography>
					<Typography variant="h5" sx={{ ml: 5 }}>
						{props.failNumber}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default CountCard;

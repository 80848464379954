import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import httpClient from '../../../services/http-client/http-client';
import {
	ActionType,
	LogoType,
	MSP,
	MspIncomingDataUpdate,
	MspNewCustomerDataUpdate,
} from './types';

export const setMspUpdate = createAsyncThunk<void, MspIncomingDataUpdate>(
	ActionType.SET_MSP_UPDATE,
	async (dto, { dispatch }) => {
		try {
			const { data } = await httpClient.patch<MSP>('/msp/update', dto);
			dispatch(setMSP(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const setMspLogo = createAsyncThunk<void, LogoType | null>(
	ActionType.SET_MSP_LOGO,
	async (dto, { dispatch }) => {
		try {
			const { data } = await httpClient.patch<MSP>(
				'/msp/update-logo',
				dto,
			);
			dispatch(setMSP(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchCurrentMsp = createAsyncThunk<any, void>(
	ActionType.FETCH_MSP,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get('/msp');
			const newMSP: MSP = {
				...data,
				logo: {
					id: data.logo
						? JSON.parse(data.logo as string).id
						: undefined,
				},
			};
			dispatch(setMSP(newMSP));
			return data || null;
		} catch (e) {
			console.log(e);
		}
	},
);

export const getAWSId = createAsyncThunk<string, File>(
	ActionType.GET_AWS_ID,
	async logo => {
		const body = new FormData();
		body.append('file', logo);
		try {
			const { data } = await httpClient.post('/image/upload', body);
			return data;
		} catch (e) {
			console.log(e);
		}
	},
);

export const addNewCustomer = createAsyncThunk<void, MspNewCustomerDataUpdate>(
	ActionType.ADD_NEW_CUSTOMER,
	async values => {
		const body = values;
		try {
			const { data } = await httpClient.post('/customers', body);
			return data;
		} catch (e) {
			console.log(e);
		}
	},
);
export const addNewCustomerWithoutEmail = createAsyncThunk<
	void,
	MspNewCustomerDataUpdate
>(ActionType.ADD_NEW_CUSTOMER, async values => {
	const body = values;
	try {
		const { data } = await httpClient.post(
			'/customers/without-email',
			body,
		);
		return data;
	} catch (e) {
		console.log(e);
	}
});

export const setMSP = createAction<MSP>(ActionType.SET_MSP);

import { RootState } from 'store';

export const selectTeamMemberById = (id: string) => (state: RootState) =>
	state.teamMembers.members.find(member => member.id === id);

export const selectTeamMemberByTeamId = (id: string) => (state: RootState) =>
	state.teamMembers.members.filter(member => member.teamId === id);

export const selectTeamMember = (state: RootState) => state.teamMembers;

export const selectTeamMembers = (state: RootState) =>
	state.teamMembers.members;

export const selectTeamMembersCount = (state: RootState) =>
	state.teamMembers.members.length;

/* eslint no-useless-escape: 0 */

import {
	Autocomplete,
	Box,
	Grid,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material';
import { CompanyAvatar } from 'components';
import { useAppSelector } from 'hooks/redux';
import { useState } from 'react';
import httpClient from 'services/http-client/http-client';
import { selectors } from 'store';
import { Role } from 'store/stores/auth/types';
import { LogoType } from 'store/stores/msp/types';
import {
	MspCustomerOptions,
	MspCustomerOptionsArray,
	MspRevenueOptions,
	MspRevenueOptionsArray,
	MspTypeOptions,
	MspTypeOptionsArray,
	MspUsersOptions,
	MspUsersOptionsArray,
} from 'types';

import { OnboardingWizardData } from '../MspOnboardingWizardPage';
import { StepWrapper } from './StepWrapper';

type MspInfoProps = OnboardingWizardData & {
	updateFields: (fields: Partial<OnboardingWizardData>) => void;
};

export function MspInfo({
	companyName,
	firstName,
	lastName,
	email,
	address,
	phone,
	mspType,
	mspCustomer,
	mspUsers,
	mspRevenue,
	logoId,
	updateFields,
}: MspInfoProps) {
	const [addresses, setAddressess] = useState<string[]>([]);
	const currentMSP = useAppSelector(selectors.msp.selectCurrentMSP);
	const getAddress = async (value: string) => {
		const { data } = await httpClient.get(
			`/customers/address?value=${value}`,
		);
		setAddressess([]);
		const result: string[] = data;
		result.unshift(value);
		setAddressess(result);
	};

	const formCustomersItems = MspCustomerOptionsArray.map((option, i) => {
		return (
			<MenuItem key={i} value={option.option}>
				{option.value}
			</MenuItem>
		);
	});

	const formUsersItems = MspUsersOptionsArray.map((option, i) => {
		return (
			<MenuItem key={i} value={option.option}>
				{option.value}
			</MenuItem>
		);
	});

	const formRevenueItems = MspRevenueOptionsArray.map((option, i) => {
		return (
			<MenuItem key={i} value={option.option}>
				{option.value}
			</MenuItem>
		);
	});

	const formTypeItems = MspTypeOptionsArray.map((option, i) => {
		return (
			<MenuItem key={i} value={option.option}>
				{option.value}
			</MenuItem>
		);
	});

	//Set new msp status

	return (
		<StepWrapper heading="Tell us more about you">
			<Box sx={sxRoot}>
				<Box sx={sxHeader}>
					<CompanyAvatar
						role={Role.MSP}
						logoProps={currentMSP?.logo ? currentMSP?.logo : null}
						editable
					/>
					<Box sx={sxHeaderText}>
						<Typography marginTop={2} variant="h5">
							In order to serve you better, we need some more
							information about your business.
						</Typography>
					</Box>
				</Box>
				<Grid sx={sxTopFormBox}>
					<TextField
						required
						name="companyName"
						size="small"
						sx={sxFormItem}
						variant="outlined"
						label="Company Name"
						value={companyName}
						onChange={e => {
							updateFields({ companyName: e.target.value });
						}}
					/>
					<TextField
						select
						label="Company Type"
						size="small"
						defaultValue={mspType != null ? mspType : undefined}
						value={mspType}
						onChange={e => {
							updateFields({
								mspType: e.target.value as MspTypeOptions,
							});
						}}
						sx={sxFormItem}>
						{formTypeItems}
					</TextField>
					<TextField
						size="small"
						sx={sxFormItem}
						variant="outlined"
						name="firstName"
						label="First Name"
						value={firstName}
						onChange={e => {
							updateFields({ firstName: e.target.value });
						}}
						required
					/>
					<TextField
						size="small"
						sx={sxFormItem}
						variant="outlined"
						name="lastName"
						label="Last Name"
						value={lastName}
						onChange={e => {
							updateFields({ lastName: e.target.value });
						}}
						required
					/>
					<TextField
						size="small"
						sx={sxFormItem}
						variant="outlined"
						name="email"
						label="Email"
						value={email}
						onChange={e => {
							updateFields({ email: e.target.value });
						}}
						required
					/>
					<TextField
						size="small"
						sx={sxFormItem}
						variant="outlined"
						name="phone"
						label="Phone"
						value={phone}
						onChange={e => {
							updateFields({ phone: e.target.value });
						}}
						required
					/>

					<Autocomplete
						disablePortal={true}
						sx={sxAddressItem}
						options={addresses}
						autoHighlight
						size="small"
						getOptionLabel={option => option}
						filterOptions={options => options}
						value={address}
						renderOption={(props, option) => (
							<Box component="li" {...props}>
								{option}
							</Box>
						)}
						onChange={(e, v) => {
							updateFields({ address: v as string });
						}}
						renderInput={params => (
							<TextField
								{...params}
								label="Company Address"
								onChange={e => {
									if (e?.target?.value?.length > 2) {
										getAddress(e?.target?.value);
									}
								}}
								inputProps={{
									...params.inputProps,
									autoComplete: 'new-address',
								}}
							/>
						)}
					/>

					<Typography sx={{ mt: '1em' }} variant="h5">
						...and we have a few optional questions to help us
						understand how to serve our customers better
					</Typography>
					<TextField
						select
						label="Estimated Customer Base"
						size="small"
						defaultValue={
							mspCustomer != null ? mspCustomer : undefined
						}
						value={mspCustomer}
						onChange={e => {
							updateFields({
								mspCustomer: e.target
									.value as MspCustomerOptions,
							});
						}}
						sx={sxFormItem}>
						{formCustomersItems}
					</TextField>
					<TextField
						select
						label="Estimated Users"
						size="small"
						defaultValue={mspUsers != null ? mspUsers : undefined}
						value={mspUsers}
						onChange={e => {
							updateFields({
								mspUsers: e.target.value as MspUsersOptions,
							});
						}}
						sx={sxFormItem}>
						{formUsersItems}
					</TextField>
					<TextField
						select
						label="Estimated Annual Revenue"
						size="small"
						defaultValue={
							mspRevenue != null ? mspRevenue : undefined
						}
						value={mspRevenue}
						onChange={e => {
							updateFields({
								mspRevenue: e.target.value as MspRevenueOptions,
							});
						}}
						sx={sxFormItem}>
						{formRevenueItems}
					</TextField>
				</Grid>
			</Box>
		</StepWrapper>
	);
}

const sxTopFormBox = {
	mt: '1em',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
};

const sxFormItem = {
	width: '45%',
	mt: '1.5em',
	'& .MuiFormHelperText-root': {
		marginTop: 0,
		height: 0,
	},
};

const sxAddressItem = {
	width: '100%',
	mt: '1.5em',
	'& .MuiFormHelperText-root': {
		marginTop: 0,
		height: 0,
	},
};

export const sxAvatarBox = {
	objectFit: 'cover',
	display: 'top',
	borderRadius: '50%',
	maxHeight: '150px',
	maxWidth: '150px',
	minWidth: '150px',
	minHeight: '150px',
	alignItems: 'center',
	alignContent: 'space-around',
	justifyContent: 'centered',
};

const sxHeader = {
	display: 'flex',
	flexDirection: 'row',
};
const sxRoot = {
	mb: '2em',
};

const sxHeaderText = {
	ml: '4em',
	mt: 'auto',
	mb: 'auto',
};

const sxCircularProgress = {
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
	minWidth: '150px',
	minHeight: '150px',
	maxWidth: '150px',
	maxHeight: '150px',
};

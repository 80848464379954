import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { Greeting } from './reducer';
import { seedData } from './seed';
import { ActionType } from './types';

/* GREETINGS */

export const fetchGreetings = createAsyncThunk<void>(
	ActionType.FETCH_GREETINGS,
	async (_, { dispatch }) => {
		try {
			dispatch(setGreeting(seedData));
		} catch (e) {
			console.log(e);
		}
	},
);

export const setGreeting = createAction<Greeting[]>(ActionType.SET_GREETINGS);
export const nextGreeting = createAction(ActionType.NEXT_GREETING);

import * as React from 'react';

function SvgNotStarted(
	props: React.SVGProps<SVGSVGElement>,
) {
	return (
		<svg
			width={19}
			height={20}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.423 0v2c4.12.54 7.04 4.53 6.533 8.92-.207 1.769-.962 3.414-2.145 4.674-1.183 1.261-2.727 2.065-4.388 2.286v2c5.163-.6 8.87-5.54 8.307-11.03C18.242 4.19 14.797.5 10.423 0zM8.546 0C6.706.18 4.97.95 3.543 2.2l1.342 1.54a7.28 7.28 0 013.66-1.68V0zM2.219 3.67A10.159 10.159 0 00.145 9h1.877a8.3 8.3 0 011.54-3.9L2.219 3.67zM.154 11c.188 1.96.91 3.81 2.075 5.33l1.333-1.43a8.287 8.287 0 01-1.53-3.9H.154zm4.694 5.37l-1.305 1.37A9.096 9.096 0 008.546 20v-2a7.275 7.275 0 01-3.66-1.63h-.038zM10.423 11V5H8.546v6h1.877zm0 4v-2H8.546v2h1.877z"
				fill="#FF9800"
			/>
		</svg>
	);
}

export default SvgNotStarted;

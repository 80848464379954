import { SVGProps } from 'react';

const SvgPlusIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z" fill="#fff" />
	</svg>
);

export default SvgPlusIcon;

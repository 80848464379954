export enum ComplianceActions {
	GET_STARTED = 'GET_STARTED',
}

interface ComplianceActionsType<T, P> {
	type: T;
	payload: P;
}

export type ComplianceAction =
	| ComplianceActionsType<typeof ComplianceActions.GET_STARTED, undefined>

import * as React from 'react';
import { SVGProps } from 'react';

const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41Z"
			fill="#000"
			fillOpacity={0.54}
		/>
	</svg>
);

export default SvgCloseIcon;

import { Box, Button, Menu, MenuItem, Typography, colors } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { forEach } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toaster } from 'services/toaster';
import { actions } from 'store/stores/customers';
import {
	Customer,
	EndCustomerStatus,
	Invite,
	InviteStatus,
} from 'store/stores/customers/types';

export interface CustomerTableProps {
	customers: Customer[];
	invites: Invite[];
}

export interface Record {
	companyName: string;
	createdAt: Date;
	email: string;
	id: string;
	status: EndCustomerStatus | InviteStatus;
}

export const CustomerTable = ({ customers, invites }: CustomerTableProps) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [pageSize, setPageSize] = React.useState(25);

	const [records, setRecords] = useState<Record[]>([
		{
			companyName: '',
			createdAt: new Date(),
			email: '',
			id: '',
			status: EndCustomerStatus.ASSESSMENT_COMPLETE,
		},
	]);

	useEffect(() => {
		setRecords([]);
		forEach(customers, customer => {
			const record: Record = {
				companyName: customer.companyName,
				createdAt: customer.createdTime,
				email: customer.companyContactEmail,
				id: customer.id,
				status: customer.status,
			};
			setRecords(prevRecords => [...prevRecords, record]);
		});
		forEach(invites, invites => {
			const record: Record = {
				companyName: invites.companyName,
				createdAt: invites.createdAt,
				email: invites.companyContactEmail,
				id: invites.id,
				status: invites.status,
			};

			setRecords(prevRecords => [...prevRecords, record]);
		});
	}, [invites, customers]);

	const columns: GridColDef<Record>[] = [
		{
			headerAlign: 'center',
			field: 'companyName',
			headerName: 'Company Name',
			flex: 150,
			align: 'center',
			editable: false,
		},

		{
			headerAlign: 'center',
			field: 'createdTime',
			headerName: 'Created',
			flex: 100,
			align: 'center',
			editable: false,
			renderCell: params => {
				const months = [
					'Jan',
					'Feb',
					'Mar',
					'Apr',
					'May',
					'Jun',
					'Jul',
					'Aug',
					'Sept',
					'Oct',
					'Nov',
					'Dec',
				];
				const month = new Date(params?.row?.createdAt).getMonth();
				const year = new Date(params?.row?.createdAt).getFullYear();
				return (
					<Box sx={defaultCellStyle}>
						<Typography variant="subtitle2">
							{months[month - 1]} {year}
						</Typography>
					</Box>
				);
			},
		},
		{
			headerAlign: 'center',
			field: 'status',
			headerName: 'Status',
			flex: 150,
			align: 'center',
			editable: false,
		},
		{
			headerAlign: 'center',
			field: 'actions',
			headerName: 'Quick Actions',
			flex: 150,
			align: 'center',
			editable: false,
			renderCell: params => {
				const [anchorEl, setAnchorEl] =
					React.useState<null | HTMLElement>(null);
				const open = Boolean(anchorEl);
				const handleClick = (event: any) => {
					event?.stopPropagation();
					setAnchorEl(event?.target);
				};
				const handleClose = () => {
					setAnchorEl(null);
				};

				const handleResend = async (id: string) => {
					const result = await dispatch(
						actions.fetchResendInvite(id),
					);
					if ((result as any)?.payload) {
						toaster({
							message: 'Your invite has been resent',
							variant: 'success',
						});
					} else {
						toaster({
							message:
								'There was a problem resending your invite. Please try again later or contact support if the problem persists.',
							variant: 'error',
						});
					}
				};

				const handleMenuClick = async (item: string, id: string) => {
					handleClose();
					switch (item) {
						case 'view':
							history.push(`/msp/customer/${id}`);
							break;
						case 'assessment':
							actions.sendAssessment(id);
							break;
						case 'resend':
							await handleResend(id);

							break;
						case 'report':
							console.log('report ' + id);
							break;
						case 'hide':
							console.log('hide ' + id);
					}
				};
				return (
					<Box sx={defaultCellStyle}>
						<Button
							color="secondary"
							id="quick-actions-button"
							aria-controls={
								open ? 'quick-action-menu' : undefined
							}
							aria-haspopup="true"
							aria-expanded={open ? true : undefined}
							onClick={handleClick}>
							Actions
						</Button>
						<Menu
							id="quick-action-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'quick-actions-button',
							}}>
							<MenuItem
								onClick={() => {
									handleMenuClick('view', params.row.id);
								}}>
								View Customer
							</MenuItem>
							{/* <MenuItem
								onClick={() =>
									handleMenuClick('assessment', params.row.id)
								}>
								Send Assessment
							</MenuItem> */}
							<MenuItem
								onClick={() =>
									handleMenuClick('resend', params.row.id)
								}>
								Resend Invite
							</MenuItem>
							{/* <MenuItem
								onClick={() =>
									handleMenuClick('report', params.row.id)
								}>
								View Report
							</MenuItem> */}
						</Menu>
					</Box>
				);
			},
		},
	];

	return (
		<DataGrid
			disableExtendRowFullWidth={false}
			autoHeight
			rows={records}
			columns={columns}
			disableSelectionOnClick
			pageSize={pageSize}
			onPageSizeChange={newPageSize => {
				setPageSize(newPageSize);
			}}
			className={'pointer'}
			rowsPerPageOptions={[5, 25, 50]}
			onRowClick={e => {
				history.push(`/msp/customer/${e.row.id}`);
			}}
		/>
	);
};

const defaultCellStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	whiteSpace: 'break-spaces',
};

import { SVGProps } from 'react';

const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17Z"
			fill="#4CAF50"
		/>
	</svg>
);

export default SvgCheckIcon;

import AnnouncementIcon from '@mui/icons-material/Announcement';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useAppDispatch } from 'hooks/redux';
// import { actions } from 'store/stores';
import {
	Notification,
	NotificationType,
} from 'store/stores/notifications/types';
import styled from 'styled-components';

interface NotificationsListItemProps {
	notification: Notification;
}

export const NotificationsListItem = ({
	notification,
}: NotificationsListItemProps) => {
	const dispatch = useAppDispatch();

	const handleDismissClick = (notificationId: string) => {};

	return (
		<Root>
			{getIcon(notification.type)}
			<Message>{notification.text}</Message>
			{notification.type !== NotificationType.None && (
				<CloseIcon
					sx={{ mt: 1, ml: 'auto' }}
					onClick={() => handleDismissClick(notification.id)}
				/>
			)}
		</Root>
	);
};

const Root = styled.li`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	width: 100%;
	padding: 8px 16px;
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
`;

const Message = styled.div`
	${TYPOGRAPHY.body1}
	${COLORS.textPrimary};
`;

const getIcon = (notificationType: NotificationType) => {
	if (notificationType == NotificationType.Info) {
		return (
			<AnnouncementIcon
				color="info"
				sx={{
					mr: 2,
					mb: 2,
					mt: 1,
				}}
			/>
		);
	} else if (notificationType == NotificationType.Warning) {
		return (
			<WarningIcon
				color="warning"
				sx={{
					mr: 2,
					mb: 2,
					mt: 1,
				}}
			/>
		);
	} else if (notificationType == NotificationType.Error) {
		return (
			<ErrorIcon
				color="error"
				sx={{
					mr: 2,
					mb: 2,
					mt: 1,
				}}
			/>
		);
	} else if (notificationType == NotificationType.Success) {
		return (
			<CheckCircleIcon
				color="success"
				sx={{
					mr: 2,
					mb: 2,
					mt: 1,
				}}
			/>
		);
	}
};

import { CLASS_NAMES } from 'assets/styles/classNames';
import { COLORS } from 'assets/styles/colors';
import { COMPONENTS } from 'assets/styles/components';

export const themes: Record<string, string> = {
	// eslint-disable-next-line quotes
	primary: `
		max-width: 85px;

		& svg path {
			fill: ${COLORS.primaryMain};
		}

		&& {
			&${CLASS_NAMES.ButtonRoot} {
				width: 100%;
				color: ${COLORS.primaryMain};
				font-family: 'Lato';

				&:hover {
					background-color: #4282B314;
				}
			}

			&${CLASS_NAMES.ButtonOutlined} {
				border: 1px solid ${COLORS.primaryMain};
				&.Mui-disabled {
					border: 1px solid rgba(0, 0, 0, 0.12);
					color: rgba(0, 0, 0, 0.12);
				}
			}

			${COMPONENTS.buttonLarge}
			font-weight: 400;
			line-height: 22px;
		}
	`,
	// eslint-disable-next-line quotes
	secondary: `
		max-width: 85px;

		& svg path {
			fill: ${COLORS.secondaryMain};
		}

		&& {
			&${CLASS_NAMES.ButtonRoot} {
				width: 100%;
				color: ${COLORS.secondaryMain};
				font-family: 'Lato';

				&:hover {
					background-color: #A8008B14;
				}
			}

			&${CLASS_NAMES.ButtonOutlined} {
				border: 1px solid ${COLORS.secondaryMain};
				&.Mui-disabled {
					border: 1px solid rgba(0, 0, 0, 0.12);
					color: rgba(0, 0, 0, 0.12);
				}
			}

			${COMPONENTS.buttonLarge}
			font-weight: 400;
			line-height: 22px;
		}
	`,
	tertiary: `
	max-width: 85px;

	& svg path {
		fill: ${COLORS.errorMain};
	}

	&& {
		&${CLASS_NAMES.ButtonRoot} {
			width: 100%;
			color: ${COLORS.errorMain};
			font-family: 'Lato';

			&:hover {
				background-color: #A8000014;
			}
		}

		&${CLASS_NAMES.ButtonOutlined} {
			border: 1px solid ${COLORS.errorMain};
			&.Mui-disabled {
				border: 1px solid rgba(0, 0, 0, 0.12);
				color: rgba(0, 0, 0, 0.12);
			}
		}

		${COMPONENTS.buttonLarge}
		font-weight: 400;
		line-height: 22px;
	}
`,
};

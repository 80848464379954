import { AvatarProps, Avatar as UIAvatar } from '@mui/material';
import { COMPONENTS } from 'assets/styles/components';
import styled from 'styled-components';

interface MyAvatarProps {
	firstName: string;
	lastName?: string;
	avatarKey?: string;
}

export const Avatar = ({
	className,
	firstName = '',
	lastName = '',
	avatarKey,
	variant,
}: MyAvatarProps & AvatarProps) => {
	return (
		<StyledAvatar
			className={className}
			variant={variant}
			src={avatarKey}
			alt={`${firstName} ${lastName.length && lastName}`}>{`${
			firstName.length && firstName[0]
		}${lastName.length && lastName[0]}`}</StyledAvatar>
	);
};

const StyledAvatar = styled(UIAvatar)`
	&& {
		&.MuiAvatar-root {
			${COMPONENTS.chip}
			background-color: rgba(66, 130, 179, 0.5);
		}
	}
`;

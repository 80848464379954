import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (
	props: SVGProps<SVGSVGElement>,
) => (
	<svg
		width={40}
		height={40}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M24.667 22.667h-1.054l-.373-.36a8.628 8.628 0 0 0 2.093-5.64A8.666 8.666 0 0 0 16.667 8 8.666 8.666 0 0 0 8 16.667a8.666 8.666 0 0 0 8.667 8.666c2.146 0 4.12-.786 5.64-2.093l.36.373v1.054l6.666 6.653 1.987-1.987-6.653-6.666Zm-8 0c-3.32 0-6-2.68-6-6s2.68-6 6-6 6 2.68 6 6-2.68 6-6 6Z"
			fill="#4282B3"
		/>
	</svg>
);

export default SvgComponent;

import { createReducer } from '@reduxjs/toolkit';
import { TeamMember } from 'common/types';

import * as actions from './actions';

interface TeamMembersState {
	members: TeamMember[];
}

const initialState: TeamMembersState = {
	members: [],
};

export const reducer = createReducer(initialState, builder =>
	builder
		.addCase(actions.addTeamMember, (state, { payload }) => {
			state.members = [...state.members, payload];
		})
		.addCase(actions.removeTeamMember, (state, { payload: id }) => {
			state.members = state.members.filter(member => member.id !== id);
		})
		.addCase(actions.updateTeamMember, (state, { payload }) => {
			state.members = state.members.map(member => {
				if (member.id !== payload.id) {
					return member;
				}

				return {
					...member,
					payload,
				};
			});
		})
		.addCase(actions.setTeamMembers, (state, { payload }) => {
			state.members = payload;
		}),
);

import {createReducer} from '@reduxjs/toolkit';
import { Team } from 'common/types';

import * as actions from './actions';

interface TeamState {
    teams: Team[];
}

const initialState: TeamState = {
	teams: [],
};

export const reducer = createReducer(initialState, (builder) => {
	builder
		.addCase(actions.addTeam, (state, {payload}) => {
			state.teams = [...state.teams.filter((team) => team.id !== payload.id), payload];
		})
		.addCase(actions.updateTeam, (state, {payload}) => {
			state.teams = state.teams.map((team) => {
				if(team.id !== payload.id){
					return team;
				}

				return {
					...team,
					...payload
				};
			});
		})
		.addCase(actions.removeTeam, (state, {payload}) => {
			state.teams = state.teams.filter((team) => team.id !== payload);
		})
		.addCase(actions.setTeams, (state, {payload}) => {
			state.teams = payload;
		});
});

import { createReducer } from '@reduxjs/toolkit';

import { actions } from '.';

interface SearchState {
	text: string;
}

const initialState: SearchState = {
	text: '',
};

export const reducer = createReducer(initialState, builder =>
	builder.addCase(actions.setSearchTerm, (state, { payload }) => {
		state.text = payload;
	}),
);

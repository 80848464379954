import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import StyledCheckbox from '../StyledCheckbox';
import StyledProgressBar from '../StyledProgressBar';

interface OnboardingProgressCardProps {
	assetList: boolean;
	teamMemberList: boolean;
	saasServices: boolean;
	cloudServices: boolean;
	vendorsAndContractors: boolean;
}

enum OnboardingStep {
	UsersOnboard = 0,
	AssetsOnboard = 1,
	SoftwareSaaSOnboard = 2,
	TicketingOnboard = 3,
	MapTeamMembersToAssets = 4,
}

interface OnboardingStatus {
	tenantId: string;
	users: boolean;
	assets: boolean;
	software: boolean;
	ticketing: boolean;
	mapTeamMembersToAssets: boolean;
	currentOnboardingStep: OnboardingStep;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const OnboardingProgressCard: React.FunctionComponent<
	OnboardingProgressCardProps
> = props => {
	const history = useHistory();
	const query = useQuery();

	const authState = useSelector((state: any) => state.authState);
	const thisWindow: any = window;

	const admin_consent = query.get('admin_consent');
	if (!!admin_consent && admin_consent == 'True') {
		axios.get(`${thisWindow._env_.API_URL}/azure/sync`, {
			headers: {
				authorization: authState?.authToken,
			},
		});
	}

	const [onboardingStatus, setOnboardingStatus] =
		React.useState<OnboardingStatus | null>(null);

	React.useEffect(() => {
		axios
			.get<OnboardingStatus>(
				`${thisWindow._env_.API_URL}/onboarding/status`,
				{
					headers: {
						authorization: authState.authToken,
					},
				},
			)
			.then(response => {
				// debugger;
				setOnboardingStatus(response.data);
			});
	}, []);

	return (
		<Card>
			<CardContent>
				<Box
					display="flex"
					flexDirection="row"
					style={{
						paddingBottom: '48px',
					}}>
					<Typography
						variant="h6"
						style={{
							fontWeight: 'bold',
							fontSize: 28,
						}}>
						Onboarding Progress
					</Typography>
				</Box>
				<StyledProgressBar value={67} />
				<Box display="flex" flexDirection="column" width="100%">
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<Box fontWeight="fontWeightLight">
							Information Gathering Next Steps ...
						</Box>
					</Box>
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<StyledCheckbox
							label="1. Onboard your users"
							checked={
								onboardingStatus != null &&
								onboardingStatus.users
							}
						/>
					</Box>
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<StyledCheckbox
							label="2. Onboard your assets"
							checked={
								!!onboardingStatus && onboardingStatus?.assets
							}
						/>
					</Box>
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<StyledCheckbox
							label="3. Add your software / SaaS"
							checked={
								!!onboardingStatus && onboardingStatus?.software
							}
						/>
					</Box>
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<StyledCheckbox
							label="4. Integrate your ticketing system"
							checked={
								!!onboardingStatus &&
								onboardingStatus?.ticketing
							}
						/>
					</Box>
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<StyledCheckbox
							label="5. Map team members to assets"
							checked={
								!!onboardingStatus &&
								onboardingStatus?.mapTeamMembersToAssets
							}
						/>
					</Box>
					<Box display="flex" flexDirection="row" flexGrow={1}>
						<Button
							variant="outlined"
							onClick={() => {
								history.push('/onboarding');
							}}>
							Continue Onboarding
						</Button>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default OnboardingProgressCard;

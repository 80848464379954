import axios from 'axios';
import { API_BASE_URL } from 'common/constants';

import { StoreType } from '../../store';
import { selectors } from '../../store/stores/auth';

const httpClient = axios.create({
	baseURL: API_BASE_URL,
});

export default httpClient;

export const AxiosInterceptors = {
	setup: (store: StoreType) => {
		httpClient.interceptors.request.use(
			config => {
				const state = store?.getState();
				// console.log('state', state);
				const token = selectors.selectToken(state);

				if (!token) {
					return config;
				}

				const headers = {
					...config.headers,
					Authorization: `Bearer ${token}`,
				};

				return { ...config, headers };
			},
			error => {
				return Promise.reject(error);
			},
		);

		httpClient.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				if (error.data) {
					throw error.data;
				}

				throw error;
			},
		);
	},
};

import { Buffer } from 'buffer';

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from 'services/http-client/http-client';
import { toaster } from 'services/toaster';

import { ActionType } from './types';

/* GUSTO */

export const synchronizeGusto = createAsyncThunk<void, string>(
	ActionType.GUSTO_CALLBACK,
	async code => {
		try {
			toaster({
				message: 'Gusto Synchronization Started',
				variant: 'warning',
			});
			await httpClient.post('/gusto/callback', { code });
			toaster({
				message: 'Gusto Synchronization Finished',
				variant: 'success',
			});
		} catch (e) {
			console.log(e);
		}
	},
);

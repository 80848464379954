import {
	HighSecurityIcon,
	LowSecurityIcon,
	MediumSecurityIcon,
} from 'assets/icons';
import { SecurityStatus } from 'types';

export function getSecurityIcon(security: SecurityStatus) {
	switch (security) {
	case SecurityStatus.LOW:
		return <LowSecurityIcon />;
	case SecurityStatus.MEDIUM:
		return <MediumSecurityIcon />;
	case SecurityStatus.HIGH:
		return <HighSecurityIcon />;
	}
}

import { createReducer } from '@reduxjs/toolkit';
import { HardwareAsset, SaasAsset, SoftwareAsset } from 'common/types';

import * as actions from './actions';

interface AssetState {
	saasAssets: SaasAsset[];
	hardwareAssets: HardwareAsset[];
	hardwareAssetNinjaDashboardUrl: string | null;
	softwareAssets: SoftwareAsset[];
}

const initialState: AssetState = {
	hardwareAssetNinjaDashboardUrl: null,
	saasAssets: [],
	hardwareAssets: [],
	softwareAssets: [],
};

export const reducer = createReducer(initialState, builder =>
	builder
		.addCase(actions.addSaasAsset, (state, { payload }) => {
			state.saasAssets = [...state.saasAssets, payload];
		})
		.addCase(actions.setSaasAssets, (state, { payload }) => {
			state.saasAssets = payload;
		})
		.addCase(actions.updateSaasAsset, (state, { payload }) => {
			state.saasAssets = state.saasAssets.map(asset => {
				if (asset.id !== payload.id) {
					return asset;
				}

				return {
					...asset,
					...payload,
				};
			});
		})
		.addCase(actions.removeSaasAsset, (state, { payload: id }) => {
			state.saasAssets = state.saasAssets.filter(
				asset => asset.id !== id,
			);
		})
		.addCase(actions.setHardwareAssets, (state, { payload }) => {
			state.hardwareAssets = payload;
		})
		.addCase(actions.addHardwareAsset, (state, { payload }) => {
			state.hardwareAssets = [...state.hardwareAssets, payload];
		})
		.addCase(actions.updateHardwareAsset, (state, { payload }) => {
			state.hardwareAssets = state.hardwareAssets.map(asset => {
				if (asset.id !== payload.id) {
					return asset;
				}

				return {
					...asset,
					...payload,
				};
			});
		})
		.addCase(actions.removeHardwareAsset, (state, { payload: id }) => {
			state.hardwareAssets = state.hardwareAssets.filter(
				asset => asset.id !== id,
			);
		})
		.addCase(actions.setSoftwareAssets, (state, { payload }) => {
			state.softwareAssets = payload;
		})
		.addCase(actions.addSoftwareAsset, (state, { payload }) => {
			state.softwareAssets = [...state.softwareAssets, payload];
		})
		.addCase(actions.updateSoftwareAsset, (state, { payload }) => {
			state.softwareAssets = state.softwareAssets.map(asset => {
				if (asset.id !== payload.id) {
					return asset;
				}

				return {
					...asset,
					...payload,
				};
			});
		})
		.addCase(actions.removeSoftwareAsset, (state, { payload: id }) => {
			state.softwareAssets = state.softwareAssets.filter(
				asset => asset.id !== id,
			);
		})
		.addCase(actions.setNinjaDashboardURL, (state, { payload }) => {
			state.hardwareAssetNinjaDashboardUrl = payload;
		}),
);

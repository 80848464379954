import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';
import { Report } from './types';

interface cybersecurityReportState {
	isReportExists: boolean;
	report?: Report;
	reportHistory?: Report[];
	reportMap?: Map<string, Report[]>;
}

const initialState: cybersecurityReportState = {
	isReportExists: false,
};

export const reducer = createReducer(initialState, builder => {
	builder
		.addCase(actions.setReport, (state, { payload }): void => {
			if (payload) {
				state.isReportExists = true;
			} else {
				state.isReportExists = false;
			}
			state.report = payload;
		})
		.addCase(actions.setIsReportExists, (state, { payload }): void => {
			state.isReportExists = payload;
		})
		.addCase(actions.setReportHistory, (state, { payload }): void => {
			state.reportHistory = payload;
		})
		.addCase(actions.setReportMap, (state, { payload }): void => {
			state.reportMap = payload;
		});
});

export enum AuthActions {
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
}

interface AuthActionsType<T, P> {
	type: T;
	payload: P;
}

export type AuthAction =
	| AuthActionsType<typeof AuthActions.LOGIN, string>
	| AuthActionsType<typeof AuthActions.LOGOUT, undefined>;

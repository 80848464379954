import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const ToasterController = () => (
	<StyledToastContainer
		position="top-center"
		autoClose={5000}
		hideProgressBar={true}
		newestOnTop={false}
		closeButton={false}
		closeOnClick={false}
		rtl={false}
		draggable={true}
	/>
);

const StyledToastContainer = styled(ToastContainer).attrs({
	toastClassName: 'toast',
})`
	border-radius: 12px;
	padding: 0;
	width: 100%;
	max-width: 937px;
	z-index: 99;

	.toast {
		position: relative;
		top: -20px;
		left: 125px;
		background: transparent;
		padding: 0px;
		color: inherit;
		overflow: visible;
		margin: 0;
		box-shadow: none;
	}
`;

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
	CreateSoftwareAssetDto,
	HardwareAsset,
	SaasAsset,
	SoftwareAsset,
} from 'common/types';
import {
	CreateHardwareAssetDto,
	UpdateHardwareAssetDto,
} from 'common/types/hardware-assets-dto';
import {
	CreateSaasAssetDto,
	UpdateSaasAssetDto,
} from 'common/types/saas-assets-dto';
import httpClient from 'services/http-client/http-client';
import { SoftwareAssetsFormValues } from 'types';

import { ActionType } from './types';

/* SAAS-ASSETS */

export const fetchSaasAssets = createAsyncThunk<void>(
	ActionType.FETCH_SAAS_ASSETS,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<SaasAsset[]>('/saas-assets');

			dispatch(setSaasAssets(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchCreateSaasAsset = createAsyncThunk<void, CreateSaasAssetDto>(
	ActionType.FETCH_CREATE_SAAS_ASSETS,
	async (dto, { dispatch }) => {
		try {
			const { data } = await httpClient.post<SaasAsset>(
				'/saas-assets',
				dto,
			);

			dispatch(addSaasAsset(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchUpdateSaasAsset = createAsyncThunk<void, UpdateSaasAssetDto>(
	ActionType.FETCH_UPDATE_SAAS_ASSET,
	async (dto, { dispatch }) => {
		try {
			const { data } = await httpClient.patch<SaasAsset>(
				`/saas-assets/${dto.id}`,
				dto,
			);

			dispatch(updateSaasAsset(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchRemoveSaasAsset = createAsyncThunk<void, string>(
	ActionType.FETCH_REMOVE_SAAS_ASSET,
	async (id, { dispatch }) => {
		try {
			const { data } = await httpClient.delete<SaasAsset>(
				`/saas-assets/${id}`,
			);

			dispatch(removeSaasAsset(data.id));
		} catch (e) {
			console.log(e);
		}
	},
);

export const setSaasAssets = createAction<SaasAsset[]>(
	ActionType.SET_SAAS_ASSETS,
);
export const addSaasAsset = createAction<SaasAsset>(ActionType.ADD_SAAS_ASSET);
export const updateSaasAsset = createAction<SaasAsset>(
	ActionType.UPDATE_SAAS_ASSETS,
);
export const removeSaasAsset = createAction<string>(
	ActionType.REMOVE_SAAS_ASSETS,
);

/* HARDWARE-ASSETS */

export const fetchHardwareAssets = createAsyncThunk<void>(
	ActionType.FETCH_HARDWARE_ASSETS,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<HardwareAsset[]>(
				'/hardware-assets',
			);

			dispatch(setHardwareAssets(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchHardwareAssetNinjaDashboardUrl = createAsyncThunk<
	void,
	string
>(ActionType.GET_NINJA_DASHBOARD_URL, async (id, { dispatch }) => {
	try {
		dispatch(setNinjaDashboardURL(null));
		const { data } = await httpClient.get<string>(
			`/ninja/device-dashboard-url/${id}`,
		);
		dispatch(setNinjaDashboardURL(data));
	} catch (e) {
		console.log(e);
	}
});

export const fetchCreateHardwareAsset = createAsyncThunk<
	void,
	CreateHardwareAssetDto
>(ActionType.FETCH_CREATE_HARDWARE_ASSETS, async (dto, { dispatch }) => {
	try {
		const { data } = await httpClient.post<HardwareAsset>(
			'/hardware-assets',
			dto,
		);

		dispatch(addHardwareAsset(data));
	} catch (e) {
		console.log(e);
	}
});

export const fetchUpdateHardwareAsset = createAsyncThunk<
	void,
	UpdateHardwareAssetDto
>(ActionType.FETCH_UPDATE_HARDWARE_ASSET, async (dto, { dispatch }) => {
	try {
		const { data } = await httpClient.patch<HardwareAsset>(
			`/hardware-assets/${dto.id}`,
			dto,
		);
		dispatch(updateHardwareAsset(data));
	} catch (e) {
		console.log(e);
	}
});

export const fetchRemoveHardwareAsset = createAsyncThunk<void, string>(
	ActionType.FETCH_REMOVE_HARDWARE_ASSET,
	async (id, { dispatch }) => {
		try {
			const { data } = await httpClient.delete<HardwareAsset>(
				`/hardware-assets/${id}`,
			);

			dispatch(removeHardwareAsset(data.id));
		} catch (e) {
			console.log(e);
		}
	},
);

export const setHardwareAssets = createAction<HardwareAsset[]>(
	ActionType.SET_HARDWARE_ASSETS,
);
export const addHardwareAsset = createAction<HardwareAsset>(
	ActionType.ADD_HARDWARE_ASSET,
);
export const updateHardwareAsset = createAction<HardwareAsset>(
	ActionType.UPDATE_HARDWARE_ASSETS,
);
export const removeHardwareAsset = createAction<string>(
	ActionType.REMOVE_HARDWARE_ASSETS,
);
/* SOFTWARE-ASSETS */

export const fetchSoftwareAssets = createAsyncThunk<void>(
	ActionType.FETCH_SOFTWARE_ASSETS,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<SoftwareAsset[]>(
				'/software-assets',
			);
			dispatch(setSoftwareAssets(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchCreateSoftwareAsset = createAsyncThunk<
	void,
	CreateSoftwareAssetDto
>(ActionType.FETCH_CREATE_SOFTWARE_ASSETS, async (dto, { dispatch }) => {
	try {
		const { data } = await httpClient.post<SoftwareAsset>(
			'/software-assets',
			dto,
		);

		dispatch(addSoftwareAsset(data));
	} catch (e) {
		console.log(e);
	}
});

export const fetchUpdateSoftwareAsset = createAsyncThunk<
	void,
	SoftwareAssetsFormValues & { id: string }
>(ActionType.FETCH_UPDATE_SOFTWARE_ASSET, async (dto, { dispatch }) => {
	try {
		const { data } = await httpClient.patch<SoftwareAsset>(
			`/software-assets/${dto.id}`,
			dto,
		);

		dispatch(updateSoftwareAsset(data));
	} catch (e) {
		console.log(e);
	}
});

export const fetchRemoveSoftwareAsset = createAsyncThunk<void, string>(
	ActionType.FETCH_REMOVE_SOFTWARE_ASSET,
	async (id, { dispatch }) => {
		try {
			const { data } = await httpClient.delete<SoftwareAsset>(
				`/software-assets/${id}`,
			);

			dispatch(removeSoftwareAsset(data.id as string));
		} catch (e) {
			console.log(e);
		}
	},
);

export const setSoftwareAssets = createAction<SoftwareAsset[]>(
	ActionType.SET_SOFTWARE_ASSETS,
);
export const addSoftwareAsset = createAction<SoftwareAsset>(
	ActionType.ADD_SOFTWARE_ASSET,
);
export const updateSoftwareAsset = createAction<SoftwareAsset>(
	ActionType.UPDATE_SOFTWARE_ASSETS,
);
export const removeSoftwareAsset = createAction<string>(
	ActionType.REMOVE_SOFTWARE_ASSETS,
);
export const setNinjaDashboardURL = createAction<string | null>(
	ActionType.SET_NINJA_DASHBOARD_URL,
);

import { Box } from '@mui/material';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import React from 'react';
import styled from 'styled-components';

export interface ReportTileProps {
	id: string;
	headline: string;
	subhead?: string;
	body?: string;
	heroStat: string;
}

const ReportTile: React.FunctionComponent<ReportTileProps> = props => {
	const { headline, subhead, body, heroStat } = props;

	return (
		<Box
			sx={{
				p: 2,
				mt: 2,
				border: 'solid 1px #D1D5DB',
				display: 'flex',
				flexDirection: 'row',
				width: '40%',
				minWidth: '460px',
				minHeight: '200px',
			}}>
			<HeroStat>{heroStat}</HeroStat>
			<Box
				sx={{
					mr: '2%',
					ml: '4%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}>
				<Headline>{headline}</Headline>
				<Subhead>{subhead}</Subhead>
				<Body>{body}</Body>
			</Box>
		</Box>
	);
};

const HeroStat = styled.div`
	${TYPOGRAPHY.h1};
	margin-top: auto;
	margin-bottom: auto;
	font-size: 48px;
	color: ${COLORS.secondaryMain};
	margin-right: 4%;
	min-width: 25%;
	text-align: center;
`;

const Headline = styled.div`
	${TYPOGRAPHY.h5};
`;

const Subhead = styled.div`
	${TYPOGRAPHY.h6};
	margin-top: 2%;
`;
const Body = styled.div`
	${TYPOGRAPHY.body1};
	margin-top: 2%;
`;

export default ReportTile;

import * as React from 'react';

export class IgnoreError extends React.Component {
	componentDidCatch(
	) {
	}

	render() {
		return this.props
			// eslint-disable-next-line react/prop-types
			.children;
	}
}

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerDetailsTileProps } from 'pages/Msp/MspCustomerPortalPage/components/CustomerDetailsTile';
import httpClient from 'services/http-client/http-client';

import { LogoType } from '../msp/types';
import {
	ActionType,
	Customer,
	Invite,
	UpdateCustomerDto,
	UpdateLogoDto,
} from './types';

/* CUSTOMERS */

export const fetchInvite = createAsyncThunk<void, any>(
	ActionType.FETCH_INVITE,
	async ({ inviteMapId, id }, { dispatch }) => {
		try {
			const { data } = await httpClient.get<any>(
				`/invites?inviteMapId=${inviteMapId}&inviteId=${id}`,
			);
			dispatch(setInviteData(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchCustomers = createAsyncThunk<void>(
	ActionType.FETCH_CUSTOMERS,
	async (_, { dispatch }) => {
		try {
			const newCustomers: Customer[] = [];
			const { data } = await httpClient.get<any[]>('/customers');
			data.forEach(c => {
				const newCustomer: Customer = {
					...c,
					logo: c.logo
						? { id: JSON.parse(c.logo as string).id }
						: null,
				};
				newCustomers.push(newCustomer);
			});
			dispatch(setCustomers(newCustomers));
		} catch (e) {
			console.log(e);
		}
	},
);

export const fetchInvites = createAsyncThunk<void>(
	ActionType.FETCH_UNACTIVATED_INVITES,
	async (_, { dispatch }) => {
		try {
			const newInvites: Invite[] = [];
			const { data } = await httpClient.get<Invite[]>(
				'/customers/unactivated-invites',
			);
			newInvites.push(...data);
			dispatch(setUnactivatedInvites(newInvites));
		} catch (e) {
			console.log(e);
		}
	},
);

export const sendAssessment = (id: string) => {
	createAsyncThunk<void>(ActionType.SEND_ASSESSMENT, async () => {
		try {
			const { data } = await httpClient.get<string>(
				`/customers/${id}/assessment`,
			);
			console.log(data);
			// dispatch(setActionMessage(data));
		} catch (e) {
			console.log(e);
		}
	});
};

export const fetchUpdateCustomer = createAsyncThunk<
	void,
	Partial<CustomerDetailsTileProps>
>(ActionType.UPDATE_CUSTOMER_DATA, async data => {
	const dto: UpdateCustomerDto = {
		endCustomerId: data.primaryUserId,
		companyName: data.companyName,
		companyContactName: data.companyContactName,
		companyContactEmail: data.primaryUserEmail,
	};
	try {
		await httpClient.patch('/customers/update', dto);
	} catch (e) {
		console.log(e);
	}
});

export const fetchResendInvite = createAsyncThunk<boolean, string>(
	ActionType.FETCH_RESEND_INVITE,
	async (id: string) => {
		try {
			const result = await httpClient.get<string>(
				`customers/resend-invite?inviteId=${id}`,
			);

			return result.status === 200 || result.status === 201;
		} catch (e) {
			console.log(e);
			return false;
		}
	},
);
export const fetchCancelInvite = createAsyncThunk<boolean, string>(
	ActionType.FETCH_CANCEL_INVITE,
	async (id: string) => {
		try {
			const result = await httpClient.get<boolean>(
				`customers/cancel-invite?inviteId=${id}`,
			);

			return result.status === 200 || result.status === 201;
		} catch (e) {
			console.log(e);
			return false;
		}
	},
);

export const setEndCustomerLogo = createAsyncThunk<void, UpdateLogoDto>(
	ActionType.SET_END_CUSTOMER_LOGO,
	async dto => {
		console.log(dto);
		try {
			const data = await httpClient.patch<Customer>(
				'/customers/update-logo',
				dto,
			);
		} catch (e) {
			console.log(e);
		}
	},
);

export const setCustomers = createAction<Customer[]>(ActionType.SET_CUSTOMERS);
export const setUnactivatedInvites = createAction<Invite[]>(
	ActionType.SET_UNACTIVATED_INVITES,
);
export const setInviteData = createAction<any>(ActionType.SET_INVITE_DATA);

import { createReducer } from '@reduxjs/toolkit';
import { Organization } from 'common/types';

import * as actions from './actions';

interface OrganizationState {
	organizations: Organization[];
}

const initialState: OrganizationState = {
	organizations: [],
};

export const reducer = createReducer(initialState, builder =>
	builder
		.addCase(actions.setOrganizations, (state, { payload }) => {
			state.organizations = payload;
		})
		.addCase(actions.updateOrganization, (state, { payload }) => {
			state.organizations = state.organizations.map(org => {
				if (org?.id && payload?.id && org?.id == payload?.id) {
					return {
						...org,
						payload,
					};
				}
				return org;
			});
		})
		.addCase(actions.addOrganization, (state, { payload }) => {
			if (payload) {
				state.organizations.push(payload);
			}
		}),
);

import { MspTypeOptions } from 'types';

import { OnboardingProgress } from '../onboarding/types';

export enum ActionType {
	SET_MSP = 'MSP/SET_MSP',
	SET_MSP_UPDATE = 'MSP/SET_MSP_UPDATE',
	FETCH_MSP = 'MSP/FETCH_MSP',
	GET_AWS_ID = 'MSP/GET_AWS_ID',
	ADD_NEW_CUSTOMER = 'MSP/ADD_NEW_CUSTOMER',
	SET_MSP_LOGO = 'MSP/SET_MSP_LOGO',
}

export interface MspIncomingDataUpdate {
	companyName?: string;
	mspType?: string;
	primaryContactFirstName?: string;
	primaryContactLastName?: string;
	primaryContactEmailAddress?: string;
	phoneNumber?: string;
	address?: string;
	clientBase?: string;
	userBase?: string;
	annualRevenue?: string;
	pictureId?: LogoType | null;
}
export type LogoType = {
	id: string;
};
export interface MSP {
	id: string;
	companyName: string;
	azureTenantId: string;
	mspType: MspTypeOptions;
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
	address: string;
	clientBase: string;
	userBase: string;
	annualRevenue: string;
	onboardingProgress: OnboardingProgress;
	logo?: LogoType | null;
}

export interface MspNewCustomerDataUpdate {
	companyName: string;
	companyContactName: string;
	companyContactEmail: string;
	companyContactPhone: string;
	pictureId: string | null;
	customerType: CustomerType | null;
}

export enum CustomerType {
	FULL = 'Full Service',
	HELPDESK = 'Helpdesk Only',
	INFRASTRUCTURE = 'Infrastructure Management',
}

export enum ActionType {
	/* NINJA */
	FETCH_NINJA_ORGS = 'NINJA/FETCH_ORGS',
	AUTHORIZE_NINJA = 'NINJA/AUTHORIZE',
	FETCH_ORGS_FOR_ASSIGNMENT = 'NINJA/FETCH_ORGS_FOR_ASSIGNMENT',
	SET_NINJA_ORGS = 'NINJA/SET_ORGS',
	SET_NINJA_ORGS_FOR_ASSIGN = 'NINJA/SET_NINJA_ORGS_FOR_ASSIGN',
	SYNC_NINJA = 'NINJA/SYNC',
	AUTH_NINJA = 'NINJA/AUTH',
	CHECK_AUTH_NINJA = 'NINJA/CHECK_AUTH',
	START_SYNC_NINJA_LOADER = 'NINJA/SYNC_START_LOADER',
	FINISH_SYNC_NINJA_LOADER = 'NINJA/SYNC_FINISH_LOADER',
	SELECT_NINJA_ORG = 'NINJA/SELECT_ORG',
	UNSELECT_NINJA_ORG = 'NINJA/UNSELECT_ORG',
}

export interface NinjaAuthenticateDTO {
	accessKeyId: string;
	secretAccessKey: string;
}

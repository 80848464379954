export enum ActionType {
	FETCH_ONBOARDING_STATUS = 'ONBOARDING/FETCH_ONBOARDING_STATUS',
	CHECK_CONSENT = 'ONBOARDING/CHECK_CONSENT',
	SET_ONBOARDING_STATUS = 'ONBOARDING/SET_ONBOARDING_STATUS',
}

export enum OnboardingProgress {
	Introduction = 'Introduction',
	BasicInformation = 'BasicInformation',
	FirstAssessmentConsent = 'FirstAssessmentConsent',
	FirstAssessmentConsentGranted = 'FirstAssessmentConsentGranted',
	FirstReportGenerated = 'FirstReportGenerated',
	Loading = 'Loading'
}

export interface OnboardingStatusResponse {
	progress: OnboardingProgress;
}

import * as React from 'react';

function SvgProgress(
	props: React.SVGProps<SVGSVGElement>,
) {
	return (
		<svg
			width={20}
			height={20}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.68.03v2.02c4.206.54 7.187 4.53 6.67 8.92-.442 3.64-3.182 6.53-6.67 6.96v2c5.27-.55 9.104-5.43 8.576-10.93-.43-4.75-4.044-8.5-8.577-8.97zM8.762.06a9.213 9.213 0 00-5.108 2.2l1.37 1.48a7.505 7.505 0 013.738-1.68v-2zm-6.46 3.61A10.117 10.117 0 00.186 9h1.916a8.203 8.203 0 011.572-3.9l-1.37-1.43zM.195 11c.192 1.96.93 3.81 2.118 5.33l1.36-1.43A8.19 8.19 0 012.113 11H.195zm4.83 5.37l-1.37 1.37A9.377 9.377 0 008.763 20v-2a7.502 7.502 0 01-3.738-1.63zM10.2 5v5.25l4.313 2.67-.72 1.23L8.764 11V5H10.2z"
				fill="#DD3B8C"
			/>
		</svg>
	);
}

export default SvgProgress;

import SyncIcon from '@mui/icons-material/Sync';
import { CircularProgress, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CheckIcon } from 'assets/icons';
import React, { useCallback, useEffect } from 'react';

interface BuildStep {
	step: number;
	text: string;
}

const buildSteps: BuildStep[] = [
	{ step: 0, text: 'Synchronizing with your Active Directory account' },
	{ step: 1, text: 'Auditing permissions' },
	{ step: 2, text: 'Reviewing user list' },
	{ step: 3, text: 'Generating security metrics' },
	{ step: 4, text: 'Drawing report graphs' },
];

export const ReportLoadingAnimation = () => {
	const [activeStep, setActiveStep] = React.useState(0);

	const fetchMyAPI = useCallback(async () => {
		if (activeStep < 4) {
			await new Promise(f => setTimeout(f, 4000));
			setActiveStep(activeStep + 1);
		}
	}, [activeStep]);

	useEffect(() => {
		fetchMyAPI();
	}, [fetchMyAPI]);

	const getColor = (step: number) => {
		if (step > activeStep) {
			return '#bdbdbd';
		} else if (step == activeStep) {
			return 'secondary';
		} else {
			return 'primary';
		}
	};

	const getIcon = (step: number) => {
		if (step > activeStep) {
			return <SyncIcon color="disabled" />;
		} else if (step == activeStep) {
			return <CircularProgress size={24} color="secondary" />;
		} else {
			return <CheckIcon color="success" />;
		}
	};

	return (
		<Box sx={sxRoot}>
			<Typography
				align="center"
				sx={{ mb: '1em', mt: '1em' }}
				color="secondary"
				variant="h4">
				Building Report...
			</Typography>
			<LinearProgress
				sx={{ width: '80%', mr: 'auto', ml: 'auto' }}
				variant="determinate"
				value={activeStep * 20}
				color="secondary"
			/>
			{buildSteps.map(s => {
				return (
					<Box key={s.step} sx={sxList}>
						{getIcon(s.step)}
						<Typography
							color={getColor(s.step)}
							sx={{ ml: '2em', fontSize: '24px' }}
							variant="subtitle1">
							{s.text}
						</Typography>
					</Box>
				);
			})}
		</Box>
	);
};

const sxRoot = {
	width: '80%',
	height: '100%',
	pb: '2em',
};

const sxList = {
	display: 'flex',
	flexDirection: 'row',
	ml: '12em',
	mt: '2em',
};

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import httpClient from '../../../services/http-client/http-client';
import {
	ActionType,
	OnboardingProgress,
	OnboardingStatusResponse,
} from './types';

export const fetchOnboardingStatus = createAsyncThunk(
	ActionType.FETCH_ONBOARDING_STATUS,
	async (_, { dispatch }) => {
		const { data } = await httpClient.get<OnboardingStatusResponse>(
			'/onboarding/status',
		);
		dispatch(setOnboardingStatus(data.progress));
	},
);

export const setOnboardingStatus = createAction<OnboardingProgress>(
	ActionType.SET_ONBOARDING_STATUS,
);

export const checkConsent = createAsyncThunk(
	ActionType.CHECK_CONSENT,
	async (_, { dispatch }) => {
		const { data } = await httpClient.get<boolean>(
			'/onboarding/check-consent',
		);
		if (data === true) {
			dispatch(fetchOnboardingStatus());
		}
	},
);

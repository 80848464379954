import { Typography } from '@mui/material';
import { ReactNode } from 'react';

export type StepWrapperProps = {
	heading: string;
	children: ReactNode;
};

export function StepWrapper({ heading, children }: StepWrapperProps) {
	return (
		<>
			<Typography sx={{ mb: '1em' }} variant="h1">
				{heading}
			</Typography>
			{children}
		</>
	);
}

import { SVGProps } from 'react';

const SvgArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={18}
		height={19}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			d="M15.244 4.55 14.227 3.32a1.061 1.061 0 0 0-.84-.402H4.612c-.343 0-.643.153-.848.402L2.755 4.55a1.432 1.432 0 0 0-.336.93v9.139c0 .804.658 1.462 1.462 1.462h10.237c.804 0 1.462-.658 1.462-1.462v-9.14c0-.35-.124-.68-.336-.929ZM9 13.52 4.978 9.5h2.56V8.037h2.924V9.5h2.56L9 13.52Zm-5.03-9.14.591-.73h8.774l.688.73H3.969Z"
			fill="#000"
			fillOpacity={0.26}
		/>
	</svg>
);

export default SvgArchiveIcon;

import {
	CircularProgress,
	ButtonProps as MuiButtonProps,
	Tooltip,
	Button as UIButton,
} from '@mui/material';
import { COLORS } from 'assets/styles/colors';
import React from 'react';
import styled from 'styled-components';

import { themes } from './themes';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

interface ButtonProps extends Omit<MuiButtonProps, 'variant'> {
	text?: string;
	icon?: React.ReactNode;
	variant: ButtonVariant;
	loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
	id,
	className,
	type,
	onClick,
	size,
	disabled,
	text,
	icon,
	variant,
	loading = false,
}) => {
	const theme = themes[variant];

	return (
		<StyledButton
			$style={theme}
			id={id}
			variant="outlined"
			className={className}
			type={type}
			onClick={onClick}
			size={size}
			disabled={loading ? true : disabled}>
			{loading ? (
				<Tooltip title={`${text} synchromization in progress...`}>
					<CircularProgress
						style={{ color: COLORS.gray400 }}
						size={20}
					/>
				</Tooltip>
			) : icon ? (
				icon
			) : (
				text
			)}
		</StyledButton>
	);
};

const StyledButton = styled(UIButton)<{ $style: string }>`
	${({ $style }) => $style}
`;

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { actions, rootReducer as rr } from './stores';
import * as assets from './stores/assets';
import * as auth from './stores/auth';
import { reducer as azureReducer } from './stores/azure/reducer';
import * as compliance from './stores/compliance';
import * as customers from './stores/customers';
import * as cybersecurityReport from './stores/cybersecurity-report';
import * as feedback from './stores/feedbacks';
import * as greetings from './stores/greetings';
import * as hubSpot from './stores/hubSpot';
import * as jira from './stores/jira';
import * as msp from './stores/msp';
import * as ninja from './stores/ninja';
import * as notifications from './stores/notifications';
import * as onboarding from './stores/onboarding';
import * as organizations from './stores/organizations';
import * as pax8 from './stores/pax8';
import * as search from './stores/search';
import * as teamMembers from './stores/team-members';
import * as teams from './stores/teams';
import * as users from './stores/users';

const rootReducer = combineReducers({
	auth: auth.reducer,
	teams: teams.reducer,
	teamMembers: teamMembers.reducer,
	assets: assets.reducer,
	jira: jira.reducer,
	ninja: ninja.reducer,
	organizations: organizations.reducer,
	pax8: pax8.reducer,
	greetings: greetings.reducer,
	azure: azureReducer,
	search: search.reducer,
	notifications: notifications.reducer,
	cybersecurityReport: cybersecurityReport.reducer,
	customers: customers.reducer,
	onboarding: onboarding.reducer,
	msp: msp.reducer,
});

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth'],
	version: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
					'CYBERSECURITY-REPORT/SET_REPORT_MAP',
				],
				ignoredPaths: ['cybersecurityReport.reportMap'],
			},
		}),
});

export const persistor = persistStore(store);
export type StoreType = typeof store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

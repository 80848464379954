import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BusinessIcon from '@mui/icons-material/Business';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { sleep } from 'lib/utils/sleep';
import { sxAvatarBox } from 'pages/Msp/MspOnboardingWizardPage/components/MspInfo';
import { OnboardingWizardData } from 'pages/Msp/MspOnboardingWizardPage/MspOnboardingWizardPage';
import { sxCircularProgress } from 'pages/Msp/MspSettingsPage/MspSettingsPage';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { actions, selectors } from 'store';
import { Role } from 'store/stores/auth/types';
import { LogoType } from 'store/stores/msp/types';

interface CompanyAvatarProps {
	logoProps?: LogoType | null | undefined;
	editable?: boolean;
	role: Role;
	endCustomerId?: string;
}

export const CompanyAvatar = ({
	logoProps,
	editable = false,
	role,
	endCustomerId,
}: CompanyAvatarProps) => {
	const dispatch = useAppDispatch();
	const [sxState, setSxState] = useState<typeof sxError | typeof sxAvatarBox>(
		sxError,
	);
	const [loaded, setLoaded] = useState<boolean>(false);

	const [logoId, setLogoId] = useState<string | null>(null);

	const [visibleSkeleton, setVisibleSkeleton] = useState<boolean>(false);

	const updateLogoField = (update: LogoType | null) => {
		if (role === Role.MSP) {
			dispatch(actions.msp.setMspLogo(update));
		} else {
			dispatch(
				actions.customers.setEndCustomerLogo({
					logo: update,
					customerId: endCustomerId as string,
				}),
			);
		}
	};

	const logoSubmit = async (logo: File) => {
		setVisibleSkeleton(true);
		const result = await dispatch(actions.msp.getAWSId(logo));
		const logoIdResult: string | null = unwrapResult(result)
			? unwrapResult(result)
			: null;
		updateLogoField(logoIdResult ? { id: logoIdResult } : null);

		setLogoId(logoIdResult ? logoIdResult : null);
	};

	useEffect(() => {
		setLogoId(logoProps ? logoProps.id : null);
	}, [logoProps]);

	return (
		<>
			{logoId && (
				<Tooltip title={editable ? 'Click to remove' : ''}>
					<Box
						component="img"
						sx={sxState}
						alt="Logo image"
						src={`https://itos-images.s3.us-west-2.amazonaws.com/${logoId}_256`}
						onClick={() => {
							if (editable) {
								setLogoId(null);
								updateLogoField(null);
								setLoaded(false);
								setVisibleSkeleton(false);
								setSxState(sxError);
							}
						}}
						onError={async () => {
							await sleep(3000);
							const logo = logoId;
							setLogoId(null);
							setLogoId(logo);
						}}
						onLoad={() => {
							setSxState(sxAvatarBox);
							setLoaded(true);
							setVisibleSkeleton(false);
						}}
					/>
				</Tooltip>
			)}

			{visibleSkeleton && !loaded && (
				<Box sx={sxCircularProgress}>
					<CircularProgress style={{ width: 50, height: 50 }} />
				</Box>
			)}
			{!visibleSkeleton && !loaded && (
				<Tooltip
					tabIndex={-1}
					title={editable ? 'Click to upload your logo' : ''}>
					<IconButton
						sx={{ width: '150px', height: '150px' }}
						aria-label="upload-logo"
						component="label">
						{editable && (
							<input
								accept="image/png,image/jpeg"
								type="file"
								onChange={e => {
									if (e.target.files) {
										setVisibleSkeleton(true);
										logoSubmit(e.target.files[0]);
									}
								}}
							/>
						)}
						{editable ? (
							<AddCircleOutlineIcon
								sx={{ width: '150px', height: '150px' }}
							/>
						) : (
							<BusinessIcon
								sx={{ width: '150px', height: '150px' }}
							/>
						)}
					</IconButton>
				</Tooltip>
			)}
		</>
	);
};

const sxError = {
	display: 'none',
};

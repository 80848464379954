import { Portal } from '@mui/material';
import { Box } from '@mui/system';
import { CloseIcon } from 'assets/icons';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { Button } from 'components/UIComponents';
import { useOutsideClick } from 'hooks';
import { FC, useRef } from 'react';
import styled from 'styled-components';

interface ModalProps {
	isOpen: boolean;
	onCancelClick: () => void;
	onAcceptClick: () => void;
	className?: string;
	cancelButtonText?: string;
	confirmButtonText?: string;
}

export const Modal: FC<ModalProps> = ({
	isOpen,
	onCancelClick,
	onAcceptClick,
	children,
	className,
	cancelButtonText = 'No',
	confirmButtonText = 'Yes',
}) => {
	const ref = useRef<HTMLDivElement>(null);
	useOutsideClick(ref, () => onCancelClick());

	return (
		<Portal>
			{isOpen && (
				<Root className={className}>
					<ModalContainer ref={ref}>
						<CloseIconWrap onClick={onCancelClick}>
							<CloseIcon />
						</CloseIconWrap>
						<ChildrenContainer>{children}</ChildrenContainer>
						<ButtonsContainer>
							<Button
								variant="primary"
								text={cancelButtonText}
								onClick={onCancelClick}
							/>
							<Box sx={{ width: '10%' }} />
							<Button
								variant="tertiary"
								text={confirmButtonText}
								onClick={onAcceptClick}
							/>
						</ButtonsContainer>
					</ModalContainer>
				</Root>
			)}
		</Portal>
	);
};

const Root = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #00000045;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ModalContainer = styled.div`
	position: absolute;

	max-width: 381px;
	width: 100%;

	padding: 35.5px 27px 36px 27px;

	background-color: #fff;
`;

const ChildrenContainer = styled.div`
	${TYPOGRAPHY.body1}
	color: ${COLORS.textPrimary};
	margin-bottom: 24px;
	text-align: center;
`;
const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 208px;
	margin: 0 auto;
`;

const CloseIconWrap = styled.button`
	position: absolute;
	right: 12px;
	top: 12px;
`;

import { RootState } from 'store';

export const selectGreetings = (state: RootState) => {
	const lengthOfGreetings = state.greetings.greetings.length;
	if (lengthOfGreetings > 0) {
		return state.greetings.greetings[lengthOfGreetings - 1];
	} else {
		return null;
	}
};

import { Box } from '@mui/material';
import { ReportTile, SecurityScoreTile } from 'components';
import { ReportTileProps } from 'components/ReportTile/ReportTile';
import { FunctionComponent } from 'react';

export interface CyberSecurityReportProps {
	reportValues: ReportTileProps[] | null;
	weightedScore: number | null | undefined;
	logoUrl?: string;
	timestamp?: string;
	companyName?: string | null | undefined;
}

export const CybersecurityReport: FunctionComponent<
	CyberSecurityReportProps
> = props => {
	const { reportValues, weightedScore, companyName, timestamp } = props;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
			}}>
			{weightedScore && (
				<Box sx={sxSecurityScore}>
					<SecurityScoreTile
						timestamp={timestamp}
						score={weightedScore}
						companyName={companyName}
					/>
				</Box>
			)}
			<Box
				sx={{
					marginTop: '1em',
					marginLeft: 8,
					marginRight: 8,
				}}
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				width="100%"></Box>

			{reportValues?.map((e, i) => {
				return (
					<ReportTile
						key={i}
						id={e.id}
						headline={e.headline}
						subhead={e.subhead}
						body={e.body}
						heroStat={e.heroStat}
					/>
				);
			})}
		</Box>
	);
};

const sxSecurityScore = {
	width: '100%',
	mt: '2em',
	mb: '2em',
};

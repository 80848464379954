import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
	NinjaOrganization,
	Organization,
	UpdateOrganization,
} from 'common/types';
import { EditClientFormValues } from 'pages/Clients/EditClientPage/EditClientPage';
import httpClient from 'services/http-client/http-client';
import { AddClientFormValues } from 'types';

import { ActionType } from './types';

/* ORGANIZATIONS */

export const fetchOrganizations = createAsyncThunk<void>(
	ActionType.FETCH_ORGS,
	async (_, { dispatch }) => {
		try {
			const { data } = await httpClient.get<Organization[]>(
				'/organizations',
			);
			dispatch(setOrganizations(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const createOrganization = createAsyncThunk<void, AddClientFormValues>(
	ActionType.FETCH_ORGS,
	async (value, { dispatch }) => {
		try {
			const { data } = await httpClient.post<Organization>(
				'/organizations/invite',
				value,
			);
			dispatch(addOrganization(data));
		} catch (e) {
			console.log(e);
		}
	},
);

export const updateFetchOrganizations = createAsyncThunk<
	void,
	EditClientFormValues & { id: string }
>(ActionType.UPDATE_FETCH_ORG, async (dto, { dispatch }) => {
	try {
		const { data } = await httpClient.patch<Organization>(
			`/organizations/${dto.id}`,
			dto,
		);
		dispatch(updateOrganization(data));
	} catch (e) {
		console.log(e);
	}
});

export const setOrganizations = createAction<Organization[]>(
	ActionType.SET_ORGS,
);

export const addOrganization = createAction<Organization>(ActionType.ADD_ORG);

export const updateOrganization = createAction<Organization>(
	ActionType.UPDATE_ORG,
);

import { SVGProps } from 'react';

const SvgPencilIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={18}
		height={19}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<g clipPath="url(#a)">
			<path
				d="M.322 13.211v2.742h2.742l8.087-8.087L8.41 5.124.322 13.211Zm12.95-7.465a.728.728 0 0 0 0-1.031L11.56 3.004a.728.728 0 0 0-1.03 0L9.192 4.342l2.741 2.742 1.338-1.338Z"
				fill="#DD3B8C"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path
					fill="#fff"
					transform="translate(.322 .726)"
					d="M0 0h17.548v17.548H0z"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SvgPencilIcon;

import { COMPONENTS } from 'assets/styles/components';
import styled from 'styled-components';

import { themes } from './themes';

type ChipVariant = 'default' | 'primary' | 'secondary';

interface ChipProps {
	text: string;
	variant?: ChipVariant;
	className?: string;
}

export const Chip = ({ text, variant = 'default', className }: ChipProps) => {
	return (
		<Root $theme={themes[variant]} className={className}>
			{text}
		</Root>
	);
};

const Root = styled.span<{ $theme: string }>`
	${COMPONENTS.chip}
	display: inline-flex;
	align-items: center;
	justify-content: center;

	border-radius: 16px;
	padding: 0 9.5px;

	${({ $theme }) => $theme}
`;

import WarningIcon from '@mui/icons-material/Warning';
import { Box, Container } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

interface TopVulnerableDevicesProps {}

interface AzureDevice {
	deviceId: string;
	deviceOwnership: string;
	displayName: string;
	enrollmentType: string;
	manufacturer: string;
	model: string;
	id: string;
	price?: string;
}

const TopVulnerableDevices: React.FunctionComponent<
	TopVulnerableDevicesProps
> = props => {
	const authToken = useSelector(selectors.auth.selectToken);

	const [devices, setDevices] = React.useState<AzureDevice[]>([]);
	const thisWindow: any = window;

	React.useEffect(() => {
		axios
			.get<AzureDevice[]>(`${thisWindow._env_.API_URL}/azure/devices`, {
				headers: {
					authorization: authToken,
				},
			})
			.then(response => {
				setDevices(response.data);
			});
	}, []);
	return (
		<DataGrid
			rows={devices}
			columns={columns}
			pageSize={5}
			autoHeight={true}
			disableSelectionOnClick
			hideFooterPagination={true}
			hideFooter={true}
			hideFooterSelectedRowCount={true}
		/>
	);
};
const columns: GridColDef[] = [
	{
		field: 'deviceName',
		headerName: 'Device Name',
		width: 250,
		headerAlign: 'center',
	},

	{
		field: 'status',
		headerName: 'Status',
		headerAlign: 'center',
		width: 160,
		renderCell: p => {
			const status: string = p.row['status'];
			const id = p.row['id'];
			return (
				<Container>
					<Box display="flex" alignItems="center">
						<Box>
							<WarningIcon />
						</Box>
						<Box style={{ marginLeft: '4px' }}>{status}</Box>
					</Box>
				</Container>
			);
		},
	},
];

export default TopVulnerableDevices;
